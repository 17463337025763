import { AdsRepository } from "src/domain/ads/ads.domain";
import { AdRankingElement, AdRankingInput } from "src/graphql/client";
import { client } from "../clients/graphql.client";

export class AdsBackendRepository implements AdsRepository {
  async getAdRanking(args: AdRankingInput) {
    return client.chain.query.getAdRanking({ filter: args }).get({
      ad: {
        id: 1,
        name: 1,
        creativeId: 1,
        thumbnailUrl: 1,
        url: 1,
        campaignObjective: 1,
        targetingCountries: 1,
      },
      channels: 1,
      campaign: { name: 1 },
      adGroup: { id: 1, name: 1 },
      metrics: {
        conversionRate: {
          value: 1,
          variance: 1,
        },
        clickThroughRate: {
          value: 1,
          variance: 1,
        },
        costPerMille: {
          value: 1,
          variance: 1,
        },
        frequency: {
          value: 1,
          variance: 1,
        },
        engagementRate: {
          value: 1,
          variance: 1,
        },
        impressionsTotal: {
          value: 1,
          total: 1,
        },
        costPerClick: {
          metric: 1,
          variance: 1,
          value: 1,
          average: 1,
        },
        spendTotal: {
          value: 1,
        },
        brandLift: {
          value: 1,
          variance: 1,
        },
        costPerEngagement: {
          value: 1,
          variance: 1,
        },
        costPerCompletedView: {
          value: 1,
          variance: 1,
        },
        videoThroughRate: {
          value: 1,
          variance: 1,
        },
        costPerAcquisition: {
          value: 1,
          variance: 1,
        },
        costPerLead: {
          value: 1,
          variance: 1,
        },
      },
    }) as Promise<AdRankingElement[]>;
  }
}
