import { useRepositoryFeature } from "src/app/features/repositories/repositories.feature";
import {
  Brand,
  BrandExtraAttribute,
  BrandListElement,
  BrandPlainPermissions,
  CreateBrandInput,
  UpdateBrandInput,
  UserWithPermissions,
} from "src/graphql/client";

export interface BrandRepository {
  getBrands(): Promise<Brand[]>;
  getBrand(brandId: string): Promise<Brand>;
  getBrandCountries(
    brandId: string,
  ): Promise<Pick<Brand, "id" | "countries" | "name">>;
  createBrand(input: CreateBrandInput): Promise<Brand>;
  getBusinessAccountBrands(): Promise<BrandListElement[]>;
  updateBrand(input: UpdateBrandInput, brandId: string): Promise<Brand>;
}

export const useBrandsDomain = (repoId = "BrandRepository") => {
  const { repository } = useRepositoryFeature<BrandRepository>(repoId);

  const getBrands = () => {
    return repository.getBrands();
  };

  const getBrand = (brandId: string) => {
    return repository.getBrand(brandId);
  };

  const updateBrandExtraAttributes = (
    extraAttributes: BrandExtraAttribute,
    brandId: string,
  ) => {
    return updateBrand({ extraAttributes }, brandId);
  };

  const createBrand = (input: CreateBrandInput) => {
    return repository.createBrand(input);
  };

  const getBrandCountries = (brandId: string) => {
    return repository.getBrandCountries(brandId);
  };

  const updateBrand = (input: UpdateBrandInput, brandId: string) => {
    return repository.updateBrand(input, brandId);
  };

  const getUsersBrand = async (user: UserWithPermissions): Promise<Brand[]> => {
    const permissions: BrandPlainPermissions[] = user.brandPermissions ?? [];
    return repository.getBusinessAccountBrands().then((brands) => {
      return brands
        .filter((b) => {
          if (user.isAdmin) return true;
          return permissions.some((p) => {
            return p.brandId === b.id;
          });
        })
        .map((b) => {
          return {
            id: b.id,
            name: b.name,
            status: b.status,
            logoUrl: b.logoUrl,
          } as Brand;
        });
    });
  };

  return {
    createBrand,
    getBrand,
    getBrandCountries,
    getBrands,
    getUsersBrand,
    updateBrand,
    updateBrandExtraAttributes,
  };
};
