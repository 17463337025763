import { PermissionsRepository } from "src/domain/permissions/permissions.domain";
import { client } from "../clients/graphql.client";
import {
  Invitation,
  InvitationInput,
  PermissionGroup,
  UserPermissionsInput,
} from "src/graphql/client";

export class PermissionsBackendRepository implements PermissionsRepository {
  getBusinessAccountPermissionGroup(): Promise<PermissionGroup[]> {
    return client.chain.query.getBusinessAccountPermissionGroup.get({
      id: 1,
      name: 1,
    }) as Promise<PermissionGroup[]>;
  }

  updateUserPermissions(input: UserPermissionsInput): Promise<boolean> {
    return client.chain.mutation
      .updateUserPermissions({ input })
      .get() as Promise<boolean>;
  }

  resendInvitation(invitationCode: string): Promise<boolean> {
    return client.chain.mutation
      .resendInvitation({ invitationCode })
      .get() as Promise<boolean>;
  }

  createInternalInvitations(input: InvitationInput): Promise<Invitation[]> {
    return client.chain.mutation
      .createInternalInvitations({ config: input })
      .get({
        code: 1,
      }) as Promise<Invitation[]>;
  }

  removeInternalInvitations(invitationCode: string): Promise<boolean> {
    return client.chain.mutation
      .deleteInvitation({ invitationCode })
      .get() as Promise<boolean>;
  }

  getActiveInternalInvitations(): Promise<Invitation[]> {
    return client.chain.query.getActiveInternalInvitations.get({
      code: 1,
      brands: {
        brandId: 1,
        permissions: {
          id: 1,
          name: 1,
        },
      },
      email: 1,
      isAdmin: 1,
      id: 1,
    }) as Promise<Invitation[]>;
  }
}
