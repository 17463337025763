import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const useTranslationFeature = () => {
  const { t } = useTranslation();

  const translateOrEmpty = useCallback(
    (key: string, options?: Record<string, any>) => {
      const { defaultValue = "", defaultVariables = {} } = options ?? {};
      return t(key, { defaultValue, ...defaultVariables }) as string;
    },
    [t],
  );
  return useMemo(
    () => ({
      t,
      translateOrEmpty,
    }),
    [t, translateOrEmpty],
  );
};

export { useTranslationFeature };
