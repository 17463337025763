import * as Sentry from "@sentry/react";
import { useRepositoryFeature } from "src/app/features/repositories/repositories.feature";
import { useCopilotChatState } from "src/app/pages/creative-intelligence-suite/pages/copilot-chat/features/copilot-chat.state";
import { env } from "src/app/tools/utils/env.tool";
import { token } from "src/app/tools/utils/token.tool";
import { useAdsLibraryState } from "src/domain/ads/ads.state";
import { useCreativeAnalyticsState } from "src/domain/creative-analytics/creative-analytics.state";
import { usePermissionsDomain } from "src/domain/permissions/permissions.domain";
import { UsersRepository } from "src/domain/users/users.domain";
import {
  AuthenticationInput,
  Brand,
  UserWithPermissions,
} from "src/graphql/client";
import { mutate } from "swr";
import { useRequestFeature } from "../requests/request.feature";
import { useConfigurationFeature } from "./configuration.feature";
import { useSessionState } from "./session.state";
export const useSessionFeature = (repoId = "UsersRepository") => {
  const { repository } = useRepositoryFeature<UsersRepository>(repoId);
  const { reset: copilotReset } = useCopilotChatState();
  const { canUserImpersonate } = usePermissionsDomain();
  const state = useSessionState();
  const adsLibState = useAdsLibraryState();
  const creativeAnalyticsState = useCreativeAnalyticsState();

  const { canIAccess } = useConfigurationFeature(state.user?.config);

  const { sendRequest } = useRequestFeature();

  const selectBrand = (brand: Brand) => {
    if (!brand) return;
    state.selectBrand(brand);
  };

  const logout = async () => {
    return sendRequest({
      request: repository.logout(),
      id: "logout",
      onSuccess(response) {
        onLogout();
        return response;
      },
    });
  };

  const onLogout = () => {
    if (env("VITE_APP_SENTRY_DSN")) {
      Sentry.setUser(null);
    }
    mutate(null);
    copilotReset();
    localStorage.clear();
    sessionStorage.clear();

    // clean zustand states
    adsLibState.reset();
    creativeAnalyticsState.reset();
    state.reset();
    token.clear();
    state.removeUser();
  };

  const login = async (loginProps: AuthenticationInput) => {
    const { sessionToken } = await repository.login(loginProps);

    let user = await loadUserFromSessionToken(sessionToken);

    // If has the permissions to impersonate other users will be prompt to enter credentials
    // to impersonate otherwise can cancel and FE will be using existing credentials
    if (canUserImpersonate(user)) {
      const loginProps = promptImpersonateCredentials();
      if (loginProps) {
        const { sessionToken: impSessionToken } =
          await impersonateLogin(loginProps);
        user = await loadUserFromSessionToken(impSessionToken);
        state.setUserImpersonated();
      }
    }
    setUser(user);
    return user;
  };

  const loadUserFromSessionToken = async (sessionToken: string) => {
    token.persist = true;
    token.set(sessionToken);
    return getUser();
  };

  const impersonateLogin = (loginProps: AuthenticationInput) => {
    return repository.impersonateLogin(loginProps);
  };

  const promptImpersonateCredentials = (): AuthenticationInput | undefined => {
    const emailToImpersonate = prompt("Email to impersonate");
    if (!emailToImpersonate || emailToImpersonate === undefined) return;
    const passToImpersonate = prompt("Password");
    if (!passToImpersonate) return;
    return { email: emailToImpersonate, password: passToImpersonate };
  };

  const getUser = () => {
    return repository.getLoggedInUser();
  };

  const setUser = (user: UserWithPermissions | undefined) => {
    state.setUser(user);
  };

  const isAdmin = () => {
    return state.user?.isAdmin;
  };

  return {
    user: state.user as UserWithPermissions,
    currentBrand: state.currentBrand,
    selectBrand,
    canIAccess,
    login,
    logout,
    getUser,
    setUser,
    isAdmin,
    isUserImpersonated: state.isUserImpersonated,
  };
};
