import { Route } from "src/app/features/navigation/models/route.model";
import { OnBoardingRoutes } from "./pages/onboarding/onboarding.routes";

import OnBoardingPage from "./pages/onboarding/onboarding.page";
import SkipIntegrationPage from "./pages/onboarding/pages/skip-integrations/skip-integration.page";
import SkipIntegrationFinishPage from "./pages/onboarding/pages/skip-integrations/skip-integration-finish.page";
import AccountIsDisabledPage from "./pages/onboarding/pages/skip-integrations/your-account-disabled.page";

export const WelcomeRoutes: Route[] = [
  {
    path: "",
    element: OnBoardingPage,
    children: OnBoardingRoutes,
    title: "Welcome",
  },
  {
    path: "skip-integration",
    title: "Skip integration",
    element: SkipIntegrationPage,
  },
  {
    path: "skip-integration/finish",
    title: "Skip integration finish",
    element: SkipIntegrationFinishPage,
  },
  {
    path: "no-access",
    title: "Looks like you don't have access",
    element: AccountIsDisabledPage,
  },
];
