import { Route } from "src/app/features/navigation/models/route.model";

import AssignAssetsPage from "src/app/pages/welcome/pages/onboarding/pages/assign-assets/assign-assets.page";
import BusinessInfoPage from "src/app/pages/welcome/pages/onboarding/pages/business-info/business-info.page";
import ConnectYourPlatformsPage from "src/app/pages/welcome/pages/onboarding/pages/connect-your-platforms.page";
import CreateYourBrandPage from "src/app/pages/welcome/pages/onboarding/pages/create-your-brand.page";
import ReadyPage from "src/app/pages/welcome/pages/onboarding/pages/ready.page";

export const OnBoardingRoutesMap = {
  "business-info": {
    path: "business-info",
    title: "Business Info",
    element: BusinessInfoPage,
  },
  "create-your-brand": {
    path: "create-your-brand",
    title: "Create your brand",
    element: CreateYourBrandPage,
  },
  "connect-your-platforms": {
    path: "connect-your-platforms",
    title: "Connect your platforms",
    element: ConnectYourPlatformsPage,
  },
  "assign-assets": {
    path: "assign-assets",
    title: "Assign assets",
    element: AssignAssetsPage,
  },
  ready: {
    path: "ready",
    title: "Ready",
    element: ReadyPage,
  },
};

export const OnBoardingRoutes: Route[] = Object.values(OnBoardingRoutesMap);
