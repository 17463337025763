import { FCC } from "@types";
import { useEffect, useState } from "react";
import { useNavigationFeature } from "src/app/features/navigation/navigation.feature";
import { ButtonUI } from "../../ui/buttons/button.ui";

export const ScrollTopWidget: FCC = ({ children }) => {
  const { scrollTop, watchScroll } = useNavigationFeature();
  const [scrollViewport, setScrollViewport] = useState(0);

  useEffect(() => {
    const unwatchScroll = watchScroll((scroll) => {
      if (scroll === undefined) return;
      setScrollViewport(scroll);
    });

    return () => {
      unwatchScroll();
    };
  }, []);

  if (scrollViewport < 100) return <></>;

  return (
    <div>
      <ButtonUI type="text" onClick={scrollTop}>
        <div
          className="flex items-center gap-2
        "
        >
          {children}
        </div>
      </ButtonUI>
    </div>
  );
};
