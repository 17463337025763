import { FC, useEffect } from "react";
import { useNavigationFeature } from "src/app/features/navigation/navigation.feature";
import { useRequestFeature } from "src/app/features/requests/request.feature";
import { useSessionFeature } from "src/app/features/session/session.feature";
import { SelectInputUI } from "src/app/ui/inputs/select/select.ui";
import { useBrandsDomain } from "src/domain/brands/brands.domain";
import useSWR from "swr";

export const LOCAL_STORAGE_BRAND_ID = "selectedBrandId";

const saveSelectedBrandIdToLocalStorage = (brandId: string) => {
  localStorage.setItem(LOCAL_STORAGE_BRAND_ID, brandId);
};

export const BrandSelectorWidget: FC = () => {
  const { selectBrand, currentBrand } = useSessionFeature();
  const { user } = useSessionFeature();
  const { sendRequest } = useRequestFeature();
  const { getUsersBrand } = useBrandsDomain();
  const { data: brands, isLoading: isGetBrandLoading } = useSWR(
    user?.brandPermissions && user?.businessAccount
      ? `BrandSelectorWidget-getUsersBrand-${user.id}`
      : null,
    () =>
      sendRequest({
        id: `BrandSelectorWidget-getUsersBrand-${user.id}`,
        request: getUsersBrand(user),
      }),
  );

  const { getPathName, goto } = useNavigationFeature();

  useEffect(() => {
    if (currentBrand) {
      saveSelectedBrandIdToLocalStorage(currentBrand.id);
    }
  }, [currentBrand]);

  const redirectToBaseRoute = () => {
    const basePath = getPathName().split("/")[1];
    goto(`/${basePath}`);
  };

  const onChangeHandler = (value: string) => {
    const selectedBrand = brands?.find((b) => b.id === value);
    if (!selectedBrand) return;
    selectBrand(selectedBrand);
    redirectToBaseRoute();
  };

  if (!brands?.length) return null;

  return (
    <SelectInputUI
      style={{ minWidth: "150px" }}
      isLoading={isGetBrandLoading}
      value={currentBrand?.id}
      onChange={onChangeHandler}
      isSearchable={brands.length > 6 ? true : false}
      options={
        brands?.map((brand) => ({
          label: brand.name,
          key: brand.id,
        })) ?? []
      }
    />
  );
};
