import { FeatureFlagsType } from "src/app/features/feature-flags/flags.model";
import { useRepositoryFeature } from "src/app/features/repositories/repositories.feature";
import { titleCaseFormatterTool } from "src/app/tools/formatters/title-case.formatter.tool";
import {
  Channel,
  IntegrationsAssets,
  Platform,
  PlatformState,
  enumChannel,
  enumConnectionState,
  enumPlatform,
} from "src/graphql/client";
import { useSWRConfig } from "swr";

export interface IntegrationRepository {
  getPlatformOauthUrl(
    platform: Platform,
    flags: FeatureFlagsType,
  ): Promise<string>;
  createPlatformIntegration(
    authCode: string,
    platform: Platform,
  ): Promise<IntegrationsAssets>;
  getPlatformIntegrated(): Promise<PlatformState[]>;
  disconnect(platform: Platform): Promise<boolean>;
  getBusinessAccountAssets(): Promise<IntegrationsAssets>;
}

export type PlatformsIntegrations = Pick<
  PlatformState,
  "userEmail" | "platform"
> & {
  name?: string;
  key?: number;
  status: PlatformState["status"] | undefined;
};

let promiseIntegrations: Promise<PlatformsIntegrations[]> | undefined;
export const useIntegrationDomain = (repoId = "IntegrationsRepository") => {
  const { repository } = useRepositoryFeature<IntegrationRepository>(repoId);
  const { cache } = useSWRConfig();

  const getPlatformOauthUrl = (
    platform: Platform,
    flags: FeatureFlagsType,
  ): Promise<string> => {
    return repository.getPlatformOauthUrl(platform, flags);
  };

  const disconnect = (platform: Platform) => {
    return repository.disconnect(platform);
  };

  const sendOauthToken = async (
    token: string,
    platform: Platform,
  ): Promise<IntegrationsAssets> => {
    return repository.createPlatformIntegration(token, platform);
  };

  const isAnyIntegrationsConnected = (
    integrationsData: PlatformsIntegrations[],
  ) => {
    return integrationsData.some((i) => i.status === enumConnectionState.OK);
  };

  // Using this wrapper function to prevent multiple calls to the same function and creating same promises
  // when promiseIntegrations is already in progress we reuse the same promise that is ongoing
  // this way we can avoid multiple calls to the API at the same time
  // useSWR handles multiple calls to the same endpoint in a short period but not when calling them from the repository
  const getIntegrations = async ({
    useCacheFirst,
    path,
  }: {
    useCacheFirst?: boolean;
    path: string;
  }): Promise<PlatformsIntegrations[]> => {
    if (promiseIntegrations) {
      return promiseIntegrations;
    }
    promiseIntegrations = _getIntegrations({ useCacheFirst, path }).finally(
      () => {
        promiseIntegrations = undefined;
      },
    );
    return promiseIntegrations;
  };

  const _getIntegrations = async ({
    useCacheFirst,
  }: {
    useCacheFirst?: boolean;
    path: string;
  }): Promise<PlatformsIntegrations[]> => {
    let integrations: PlatformState[] | undefined;
    if (useCacheFirst) {
      const { data } = cache.get("get-integrations") as {
        data: PlatformState[] | undefined;
      };
      integrations = data;
    }

    if (!integrations) {
      integrations = await repository.getPlatformIntegrated();
    }

    return Object.keys(enumPlatform)
      .filter((p) => {
        return (
          (
            [
              enumPlatform.GOOGLE,
              enumPlatform.META,
              enumPlatform.TIKTOK,
              // add this if CTV integrates
              // enumPlatform.CTV,
            ] as Platform[]
          ).includes(p as Platform) ||
          integrations?.find((i) => i.platform === p)?.status ===
            enumConnectionState.OK
        );
      })
      .map((platform, key) => {
        return {
          key,
          userEmail: integrations?.find((i) => i.platform === platform)
            ?.userEmail,
          platform: platform as Platform,
          name: titleCaseFormatterTool(platform),
          status: integrations?.find((i) => i.platform === platform)?.status,
        };
      });
  };

  const isAnyIntegrated = (integrations: PlatformsIntegrations[]) => {
    return integrations.some((i) => i.status === enumConnectionState.OK);
  };

  const getPlatformByChannel = (channel: Channel | string): Platform | null => {
    return (
      {
        [enumChannel.facebook]: enumPlatform.META,
        [enumChannel.instagram]: enumPlatform.META,
        [enumChannel.tiktok]: enumPlatform.TIKTOK,
        [enumChannel.automatedAppAds]: enumPlatform.GOOGLE,
        [enumChannel.content]: enumPlatform.GOOGLE,
        [enumChannel.youtube]: enumPlatform.GOOGLE,
        [enumChannel.ooh]: enumPlatform.CUSTOM,
        [enumChannel.crossChannel]: enumPlatform.CUSTOM,
        [enumChannel.ctv]: enumPlatform.CTV,
      }[channel] ?? null
    );
  };

  const isChannelIntegrated = async (channel: Channel): Promise<boolean> => {
    const integrations = await getIntegrations({
      useCacheFirst: true,
      path: "isChannelIntegrated",
    });

    const platform = getPlatformByChannel(channel);

    const platformStatus = integrations.find((i) => i.platform === platform);

    return platformStatus?.status === enumConnectionState.OK;
  };

  return {
    getPlatformOauthUrl,
    getIntegrations,
    disconnect,
    sendOauthToken,
    isChannelIntegrated,
    isAnyIntegrated,
    isAnyIntegrationsConnected,
  };
};
