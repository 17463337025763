import classnames from "classnames";
import { FC } from "react";
import { enumFileType, Highlight } from "src/graphql/client";
import "./asset.ui.css";
import { PhotoAssetUI } from "./photo/photo.asset.ui";
import { VideoAssetUI } from "./video/video.asset.ui";

export type CreativeProps = {
  creative: Highlight;
  isHeatMode?: boolean;
  isDetailMode?: boolean;
  style?: React.CSSProperties;
  className?: string;
  assetRef?: React.MutableRefObject<HTMLVideoElement | HTMLImageElement | null>;
};

export const AssetUI: FC<CreativeProps> = ({
  creative,
  isDetailMode = false,
  isHeatMode,
  className = "",
  assetRef,
  ...props
}) => {
  const clNames = classnames("asset-wrapper ", { [className]: !!className });
  let component;

  switch (creative.fileType) {
    case enumFileType.VIDEO: {
      component = (
        <VideoAssetUI
          isHeatMode={isHeatMode}
          creative={creative}
          isDetailMode={isDetailMode}
          assetRef={assetRef}
        />
      );
      break;
    }
    case enumFileType.IMAGE: {
      component = (
        <PhotoAssetUI
          isHeatMode={isHeatMode}
          creative={creative}
          isDetailMode={isDetailMode}
        />
      );
      break;
    }
    default: {
      component = null;
    }
  }

  if (!component) return <></>;

  return (
    <div className={clNames} {...props}>
      {component}
    </div>
  );
};
