import { ThemeConfig } from "antd";

export const themeConfig: ThemeConfig = {
  components: {
    Radio: {
      fontSize: 12,
      borderRadius: 4,
      colorBorder: "var(--slate-400)",
    },
    Menu: {
      fontSize: 14,
    },
    Select: {
      fontSize: 12,
    },
    Input: {
      fontSize: 12,
    },
    DatePicker: {
      fontSize: 12,
    },
    Button: {
      borderRadius: 4,
    },
  },
  token: {
    colorPrimary: "#1677ff",
    colorError: "#ef4444",
    colorSuccess: "#10b981",
    colorWarning: "#f59e0b",
    colorLink: "#1677ff",
    fontFamily: "Inter",
    fontSizeHeading5: 18,
    fontSizeHeading4: 20,
    fontSizeHeading3: 24,
    fontSizeHeading2: 30,
    fontSizeHeading1: 36,
  },
};
