import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { titleCaseFormatterTool } from "src/app/tools/formatters/title-case.formatter.tool";
import { isEnv } from "src/app/tools/utils/is-env.tool";
import translation from "./locales/en-US.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: isEnv("DEV"),
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation,
      },
    },
    // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
    // set returnNull to false (and also in the i18next.d.ts options)
    // returnNull: false,
    parseMissingKeyHandler: function (key, defaultValue) {
      if (defaultValue !== undefined) return defaultValue;
      const keyParts = key.split(".");
      let a = keyParts.at(-1);
      if (!a) a = key;
      return titleCaseFormatterTool(a);
    },
  });

export default i18n;
