import { useRepositoryFeature } from "src/app/features/repositories/repositories.feature";
import { Sector } from "src/graphql/client";

export interface SectorsRepository {
  getSectors(): Promise<
    {
      id: Sector;
      name: string;
    }[]
  >;
}

export const useSectorsDomain = (repoId = "SectorsRepository") => {
  const { repository } = useRepositoryFeature<SectorsRepository>(repoId);

  const getSectors = () => {
    return repository.getSectors();
  };

  return {
    getSectors,
  };
};
