import { FC, useEffect, useState } from "react";
import { DropZoneInputUI } from "./drop-zone.input.ui";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSectorsDomain } from "src/domain/sectors/sectors.domain";
import { SelectInputUI } from "src/app/ui/inputs/select/select.ui";
import { useRequestFeature } from "src/app/features/requests/request.feature";
import { Errors } from "src/app/features/requests/features/errors/errors.ui";
import { FormComponent } from "../../../account/pages/edit-business/edit-business.form.page";
import { ButtonUI } from "src/app/ui/buttons/button.ui";
import { FormErrorsWidget } from "src/app/pages/signup/form-errors.widget";
import { BrandExtraAttribute, Sector } from "src/graphql/client";
import { ImageUI } from "src/app/ui/images/image.ui";
import { COUNTRIES } from "src/app/features/repositories/catalog/countries.repository";
import { Collapse } from "antd";
import { BrandExtraAttributesWidget } from "src/app/pages/creative-intelligence-suite/widgets/brand-extra-attributes.widget";
const { Panel } = Collapse;

export const CreateBrandFormPage: FC<FormComponent> = ({
  onSubmit,
  onCancel,
  submitBtnLabel,
  defaultValues,
}) => {
  const { getSectors } = useSectorsDomain();
  const { sendRequest, getErrors, isLoading } = useRequestFeature();

  const [currentExtraAttributes, setCurrentExtraAttributes] =
    useState<BrandExtraAttribute>(defaultValues?.extraAttributes ?? {});

  const { register, setValue, handleSubmit, control, formState, watch } =
    useForm({
      defaultValues,
    });
  const [sectors, setSectors] = useState<
    {
      id: Sector;
      name: string;
    }[]
  >([]);

  const logoUrl = watch("logoUrl");

  useEffect(() => {
    sendRequest({
      request: getSectors(),
      id: "get-sectors",
    }).then((response) => {
      setSectors(response);
    });
  }, []);

  const submitHandler: SubmitHandler<FieldValues> = async ({
    name,
    logoUrl,
    country,
    sector,
  }) => {
    onSubmit &&
      onSubmit({
        brand: {
          name,
          logoUrl,
          country,
          sector,
        },
        extraAttributes: currentExtraAttributes,
      });
  };

  const updateBrandSingleCue = (
    ket: keyof BrandExtraAttribute,
    values: string[],
  ) => {
    setCurrentExtraAttributes((prev) => ({
      ...prev,
      [ket]: values,
    }));
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(submitHandler)}>
      <div className="flex gap-4">
        <div style={{ width: "120px" }}>
          {logoUrl ? (
            <div className="flex flex-col items-center justify-center gap-2">
              <ImageUI
                src={logoUrl}
                className="flex justify-center"
                style={{
                  height: "90px",
                  width: "90px",
                  borderRadius: "50%",
                }}
              />
              <ButtonUI type="text" onClick={() => setValue("logoUrl", null)}>
                Remove
              </ButtonUI>
            </div>
          ) : (
            <DropZoneInputUI
              listType="picture-circle"
              onFileUploaded={(data: any) => {
                setValue("logoUrl", data.url);
              }}
            />
          )}
        </div>
        <div className="w-full ">
          <label htmlFor="name">
            <div className="pb-2">Brand name *</div>
            <input
              {...register("name", { required: "Name are required" })}
              placeholder="Type your brand name"
              className="w-full"
              id="name"
            />
          </label>

          <div className="h-4" />

          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <>
                <div className="pb-2">Country</div>
                <SelectInputUI
                  onChange={(value) => {
                    setValue("country", value);
                  }}
                  className="w-full"
                  isSearchable={true}
                  placeholder="Select your brand’s country"
                  options={COUNTRIES?.map((country) => {
                    return {
                      key: country.code,
                      label: country.name,
                    };
                  })}
                  value={field.value}
                />
              </>
            )}
          />
          <div className="h-4" />
          <Controller
            control={control}
            rules={{ required: "Industry is required" }}
            name="sector"
            render={({ field }) => (
              <>
                <div className="pb-2">Industry *</div>
                <SelectInputUI
                  onChange={(value) => {
                    setValue("sector", value);
                  }}
                  className="w-full"
                  isSearchable={true}
                  isLoading={isLoading("get-sectors")}
                  placeholder="Select your brand’s industry"
                  options={sectors?.map((sector) => {
                    return {
                      key: sector.id,
                      label: sector.name,
                    };
                  })}
                  value={field.value}
                />
              </>
            )}
          />
        </div>
      </div>

      <div className="w-full">
        <Collapse accordion ghost={true}>
          <Panel
            key={1}
            header={
              <div className="flex w-full items-center gap-2">
                <h3>Extra attributes</h3>
              </div>
            }
          >
            <BrandExtraAttributesWidget
              extraAttributes={currentExtraAttributes}
              onUpdateAttribute={updateBrandSingleCue}
            />
          </Panel>
        </Collapse>
      </div>

      <FormErrorsWidget formState={formState} />
      <Errors errors={getErrors("create-brand")} />

      <div className="flex items-center justify-end gap-2">
        {onCancel ? (
          <ButtonUI type="text" onClick={onCancel}>
            Cancel
          </ButtonUI>
        ) : (
          <></>
        )}
        <ButtonUI
          isLoading={isLoading("create-brand")}
          onClick={handleSubmit(submitHandler)}
        >
          {submitBtnLabel ?? "Create"}
        </ButtonUI>
      </div>
    </form>
  );
};
