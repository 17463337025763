import { SectorsRepository } from "src/domain/sectors/sectors.domain";
import { enumSector } from "src/graphql/client";

const INDUSTRIES = [
  "Apparel and Accessories",
  "Beauty and Personal Care",
  "Food and Beverage",
  "Home and Garden",
  "Sports and Fitness",
  "Home Appliances",
  "Home Improvement",
  "Household Supplies",
  "Pet Care",
  "Tobacco and Smoking Accessories",
  "Toys and Games",
  "Oil and Gas",
  "Renewable Energy",
  "Utilities",
  "Banking and Lending",
  "Insurance",
  "Investment and Wealth Management",
  "Pharmaceuticals and Biotechnology",
  "Medical Devices",
  "Healthcare Services",
  "Construction and Engineering",
  "Aerospace and Defense",
  "Transportation Equipment",
  "Software and IT Services",
  "Hardware and Electronics",
  "Internet Services",
  "Telecommunications Equipment",
  "Telecommunications Services",
  "Networking Equipment",
  "Airlines and air transportation",
  "Hotels and resorts",
  "Restaurants and food services",
  "Travel agencies and tour operators",
  "Amusement parks and attractions",
  "Television broadcasting and production",
  "Film production and distribution",
  "Music recording and production",
  "Publishing (books, magazines, newspapers)",
  "Gaming (video games, mobile games)",
  "Residential real estate",
  "Commercial real estate",
  "Real estate investment trusts",
  "Real estate development and construction",
  "Public and private schools (K-12)",
  "Colleges and universities",
  "Online education platforms",
  "Vocational and technical schools",
  "Corporate training and development",
  "Other",
];

export class SectorsBackendRepository implements SectorsRepository {
  async getSectors() {
    return Object.keys(enumSector).map((key, index) => {
      return {
        id: enumSector[key],
        name: INDUSTRIES[index],
      };
    });
  }
}
