import { FCC } from "@types";
import CardUI, { CardProps } from "./card.ui";

interface CardClickableProps extends CardProps {
  onClick: () => void;
}

const CardClickableUI: FCC<CardClickableProps> = (props) => {
  const { children, onClick } = props;
  return (
    <button className="w-full" onClick={onClick}>
      <CardUI {...props}>{children}</CardUI>
    </button>
  );
};
export default CardClickableUI;
