import { create } from "zustand";

export interface HeapLoadingState {
  isLoaded: boolean;
  setIsLoaded: (isLoaded: boolean) => void;
}

export const useHeapTrackingState = create<HeapLoadingState>((set) => ({
  isLoaded: false,
  setIsLoaded: (isLoaded: boolean) => set({ isLoaded }),
}));
