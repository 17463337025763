export function sortByArray<T>(
  data: T[] | undefined,
  orderCriteria: string[],
  field?: string,
): T[] {
  if (data?.length === 0 || !data) return [];

  if (!field) {
    if (data.every((e) => typeof e === "string")) {
      return sortStringArrayWithPriority(
        data as string[],
        orderCriteria,
      ) as T[];
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const fieldName = field!;

  const order = Object.fromEntries(
    orderCriteria.map((criteria, index) => [criteria, index]),
  );

  const sorted = [...(data ?? [])].sort((a, b) => {
    const orderA =
      order[(a as any)[fieldName]] !== undefined
        ? order[(a as any)[fieldName]]
        : orderCriteria.length;
    const orderB =
      order[(b as any)[fieldName]] !== undefined
        ? order[(b as any)[fieldName]]
        : orderCriteria.length;

    // If orderA and orderB are equal, maintain the original order in the data array
    return orderA - orderB || data?.indexOf(a) - data?.indexOf(b);
  });

  return sorted;
}

function sortStringArrayWithPriority(array: string[], priorityList: string[]) {
  const priorityMap = new Map();

  // Creating a map of priority strings with their corresponding indices
  priorityList.forEach((priority, index) => {
    priorityMap.set(priority, index);
  });

  // Custom sorting function based on priority
  array.sort((a, b) => {
    const priorityA = priorityMap.get(a);
    const priorityB = priorityMap.get(b);

    // If both strings have priority, compare their indices in the priority list
    if (priorityA !== undefined && priorityB !== undefined) {
      return priorityA - priorityB;
    }
    // If only one string has priority, it comes first
    else if (priorityA !== undefined) {
      return -1;
    } else if (priorityB !== undefined) {
      return 1;
    }
    // If none of the strings have priority, use regular string comparison
    else {
      return a.localeCompare(b);
    }
  });

  return array;
}
