import WarningOutlined from "@ant-design/icons/WarningOutlined";
import { FC } from "react";
import { Platform, enumPlatform } from "src/graphql/client";
const PlatformRequirementsWidget: FC<{ platform: Platform }> = ({
  platform,
}) => {
  switch (platform) {
    case enumPlatform.GOOGLE:
      return null;
    case enumPlatform.META:
      return (
        <div className="flex flex-1 items-center justify-center gap-3">
          <WarningOutlined
            style={{
              fontSize: "1.6rem",
              color: "rgb(245, 158, 11)",
              background: "rgb(255, 251, 235)",
              paddingBottom: "8px",
              width: "50px",
              height: "50px",
              borderRadius: "100%",
              aspectRatio: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <div
            style={{
              fontSize: "0.9rem",
            }}
          >
            <p>You need to have Admin access to:</p>
            <ul
              style={{
                listStyle: "disc",
                paddingLeft: "20px",
              }}
            >
              <li>Ad Account</li>
              <li>Facebook Page</li>
              <li>Instagram Account</li>
            </ul>
          </div>
        </div>
      );
    default:
      return null;
  }
};
export default PlatformRequirementsWidget;
