import { Insight, Highlight } from "src/graphql/client";

export interface CopilotChatMessage {
  id: number;
  type: CopilotChatMessageType;
  content: any;
  author: CopilotChatMessageAuthor;
  send_timestamp: number;
  status?: CopilotChatMessageStatus;
}

export enum CopilotChatMessageType {
  Text = "text",
  Image = "image",
  Video = "video",
  Highlight = "highlight",
  Loading = "loading",
  Insight = "insight",
}

interface CopilotChatMessageStatus {
  type: CopilotChatMessageStatusType;
  message?: string;
}

export enum CopilotChatMessageStatusType {
  Error = "error",
}

export enum CopilotChatMessageAuthor {
  User = "user",
  Bot = "bot",
}

export interface TextMessage extends CopilotChatMessage {
  type: CopilotChatMessageType.Text;
  content: string;
}

type ImageUrl = string;

export interface ImageMessage extends CopilotChatMessage {
  type: CopilotChatMessageType.Image;
  content: ImageUrl;
}

export interface InsightMessage extends CopilotChatMessage {
  type: CopilotChatMessageType.Insight;
  content: Insight;
}

export interface HighlightMessage extends CopilotChatMessage {
  type: CopilotChatMessageType.Highlight;
  content: Highlight;
}
