import { client } from "src/app/features/repositories/clients/graphql.client";
import { TagsRepository } from "src/domain/tags/tags.domain";
import {
  CreateCustomTagInput,
  CreativeManualTagInput,
  Tag,
  TreeNode,
} from "src/graphql/client";

export class TagsBackendRepository implements TagsRepository {
  getBrandTags(brandId: string): Promise<TreeNode[]> {
    return client.chain.query.getBrandTags({ brandId }).get({
      key: 1,
      nestedTotal: 1,
      children: {
        key: 1,
        nestedTotal: 1,
        children: {
          key: 1,
          nestedTotal: 1,
        },
      },
    }) as Promise<TreeNode[]>;
  }

  createCustomTags(input: CreateCustomTagInput): Promise<boolean> {
    return client.chain.mutation
      .createCustomTags({ input })
      .get() as Promise<boolean>;
  }

  updateCreativeManualTags(input: CreativeManualTagInput): Promise<boolean> {
    return client.chain.mutation
      .updateCreativeManualTags({ input })
      .get() as Promise<boolean>;
  }

  getTagsFromCreative(creativeId: string): Promise<Tag[]> {
    return client.chain.query.getTagsFromCreative({ creativeId }).get({
      tagClass: 1,
      tagValue: 1,
      tagSuperClass: 1,
    }) as Promise<Tag[]>;
  }
}
