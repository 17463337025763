import { FC, PropsWithChildren } from "react";
import CardUI from "./card.ui";

const CardPageUI: FC<
  PropsWithChildren<{ className?: string; style?: React.CSSProperties }>
> = ({ children, style, className }) => {
  return (
    <CardUI
      style={{
        margin: "1rem 1.8rem",
        padding: "1rem",
        ...style,
      }}
      className={`animFadeIn ${className}`}
    >
      {children}
    </CardUI>
  );
};
export default CardPageUI;
