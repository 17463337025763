import { Route } from "src/app/features/navigation/models/route.model";
import MyProfilePage from "./my-profile.page";

export const MyProfileRoutes: Route[] = [
  {
    path: "/profile",
    element: MyProfilePage,
    title: "My Profile",
  },
];
