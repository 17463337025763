import { isEnv } from "src/app/tools/utils/is-env.tool";
import { token } from "src/app/tools/utils/token.tool";
import { CopilotChatRepository } from "src/domain/copilot-chat/copilot-chat.domain";
import { CopilotChatMessage } from "src/domain/copilot-chat/message-copilot-chat.model";

type ErrorResponse = {
  detail: string;
};

export class CopilotChatBackendRepository implements CopilotChatRepository {
  getSuggestions(): {
    understand: string[];
    test: string[];
    generate: string[];
  } {
    return {
      understand: this.getRandomSuggestions(
        [
          "What are my top-performing ads?",
          "What are my top-performing ads from last year?",
          "What are my top creative insights?",
          "What are my top creative elements for ER?",
          "How can I boost my next Traffic campaign?",
        ],
        2,
      ),
      generate: this.getRandomSuggestions(
        [
          "Propose 10 impactful ways of transmitting the concept of sustainability",
          "Propose 10 impactful ways of transmitting the concept of efficiency",
          "Generate copy for my ad",
        ],
        2,
      ),
      test: this.getRandomSuggestions(
        [
          "Can you give me recommendations for this ad?",
          "Can you give me recommendations for this Instagram ad?",
          "Can you compare the cognitive scores of the last 2 uploaded ads?",
          "Is this ad memorable?",
          "Is this ad aesthetic?",
        ],
        2,
      ),
    };
  }

  private getRandomSuggestions(
    suggestionsArray: string[],
    maxElements: number,
  ): string[] {
    const shuffled = suggestionsArray.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, maxElements);
  }

  async sendMessage(
    message: CopilotChatMessage,
    history: CopilotChatMessage[],
    brandId: string,
  ): Promise<CopilotChatMessage> {
    const endpointUrl = this.getEndpointUrl(message);
    history.shift();
    try {
      const response = await fetch(endpointUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "Brand-id": brandId,
          Authorization: token.asHeader(),
        },
        body: JSON.stringify([...history, message]),
      });
      const data: CopilotChatMessage | ErrorResponse = await response.json();

      if (!response.ok) {
        throw new Error((data as ErrorResponse).detail);
      }

      return data as CopilotChatMessage;
    } catch (error: any) {
      const errorMsg = error?.message ?? "MemChat server is down";
      throw new Error(errorMsg);
    }
  }
  // eslint-disable-next-line unused-imports/no-unused-vars
  private getEndpointUrl(message: CopilotChatMessage): string {
    let BASE_URL = "https://chat-dev.memorable.io";

    if (isEnv("STAGING")) {
      BASE_URL = "https://chat-staging.memorable.io";
    }
    if (isEnv("BETA")) {
      BASE_URL = "https://chat.memorable.io";
    }
    // sending all types as text to enable chatbot to take new creative uploaded
    // if (message.type === CopilotChatMessageType.Image) {
    //   return `${BASE_URL}/image`;
    // }
    // if (message.type === CopilotChatMessageType.Video) {
    //   return `${BASE_URL}/video`;
    // }
    return `${BASE_URL}/text`;
  }
}
