import { useEffect } from "react";
import { useNavigationFeature } from "src/app/features/navigation/navigation.feature";
import { useSessionFeature } from "src/app/features/session/session.feature";
import { OnBoardingRoutes } from "./onboarding.routes";
import { useOnBoardingState } from "./onboarding.state";

export const useOnboardingFeature = () => {
  const { currentIndex, setIndex } = useOnBoardingState();
  const { logout } = useSessionFeature();
  const { goto, getPathName } = useNavigationFeature();

  useEffect(() => {
    const b = getPathName().split("/").pop();
    if (!b) return;
    const a = getCurrentStepIndexByPath(b);
    setIndex(a);
  }, [getPathName()]);

  const gotoNextStep = () => {
    if (isLastStep()) {
      return successOnBoarding();
    }
    const b = currentIndex + 1;
    setIndex(b);
    const a = getCurrentStepByIndex(b);
    goto(`/welcome/${a.path}`);
  };

  const isLastStep = () => {
    return currentIndex === OnBoardingRoutes.length - 1;
  };

  const successOnBoarding = () => {
    goto("/login");
  };

  const getCurrentStepByIndex = (index: number) => {
    return OnBoardingRoutes[index];
  };

  const getCurrentStepIndexByPath = (index: string): number => {
    return OnBoardingRoutes.findIndex((x) => x.path === index);
  };

  const cancelOnBoarding = async () => {
    logout().then(() => goto("/"));
  };

  return {
    gotoNextStep,
    currentIndex,
    cancelOnBoarding,
    setIndex,
  };
};
