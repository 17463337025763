import { Route } from "src/app/features/navigation/models/route.model";
import { BrandSettingsRoutes } from "./pages/brand-settings/brand-settings.routes";
import { BusinessSettingsRoutes } from "./pages/business-settings/business-settings.routes";
import { CopilotChatRoutes } from "./pages/copilot-chat/copilot-chat.routes";
import { CreativeAnalyticsRoutes } from "./pages/creative-analytics/creative-analytics.routes";
import { CreativeLabRoutes } from "./pages/creative-lab/creative-lab.routes";
import { MyProfileRoutes } from "./pages/my-profile/my-profile.routes";

export const ROOT_DEFAULT_ROUTE = "/creative-analytics";

export const CreativeIntelligenceSuiteRoutes: Route[] = [
  ...CreativeAnalyticsRoutes,
  ...CreativeLabRoutes,
  ...CopilotChatRoutes,
  ...BusinessSettingsRoutes,
  ...BrandSettingsRoutes,
  ...MyProfileRoutes,
];
