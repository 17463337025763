import { FC } from "react";
import { Outlet } from "react-router-dom";
import { StepsWidget } from "./widgets/step.widget";

const OnBoardingPage: FC = () => {
  return (
    <div className="flex h-full flex-col">
      <div className="animSlideTopIn px-40">
        <StepsWidget />
      </div>
      <div className="h-10"></div>
      <div className="flex flex-1 items-center">
        <div className="flex-1 items-center p-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default OnBoardingPage;
