import { lazy } from "react";
import { Route } from "src/app/features/navigation/models/route.model";

const CopilotChatPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/copilot-chat/copilot-chat.page"
    ),
);

export const CopilotChatRoutes: Route[] = [
  {
    path: "/copilot",
    element: CopilotChatPage,
    title: "Copilot Chat",
    controlledByFeatureFlag: "enableCopilotFeature",
  },
];
