import { FC } from "react";
import { ButtonUI } from "src/app/ui/buttons/button.ui";

import CardPageUI from "src/app/ui/cards/card-page.ui";

const AccountIsDisabledPage: FC = () => {
  return (
    <CardPageUI className="mx-auto max-w-4xl">
      <div className="m-10">
        <h1 className="mb-8">Looks like you don&apos;t have access</h1>
        <p>
          Contact the{" "}
          <ButtonUI
            type="text"
            style={{
              fontSize: "1.1rem",
              padding: "0px 4px",
              textDecoration: "underline",
              color: "#1777ff",
            }}
          >
            <a href="mailto:contact@memorable.io">Memorable team</a>
          </ButtonUI>{" "}
          to reopen your account.
        </p>
      </div>
    </CardPageUI>
  );
};
export default AccountIsDisabledPage;
