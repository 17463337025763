import { FC, lazy } from "react";
import { Route as ReactDomRoute, Routes } from "react-router-dom";
import NotFoundPage from "./features/navigation/pages/not-found/not-found.page";

import { AppRoutesConfig } from "./app.routes.config";
import {
  shouldNotRenderBasedOnFeatureFlag,
  useFlags,
} from "./features/feature-flags/feature-flags.feature";
import { Route } from "./features/navigation/models/route.model";
import { PageTitleUI } from "./features/navigation/ui/page-title.ui";
import { usePermissionsFeature } from "./features/permissions.feature";
const Login = lazy(() => import("src/app/pages/login/login.page"));
const Logout = lazy(() => import("src/app/pages/logout/logout.page"));

export const AppRoutes: FC = () => {
  const flags = useFlags();
  const { canINavigateTo } = usePermissionsFeature();
  function renderRoute(route: Route) {
    if (
      !canINavigateTo(route) ||
      shouldNotRenderBasedOnFeatureFlag(flags, route?.controlledByFeatureFlag)
    )
      return null;

    return (
      <ReactDomRoute
        key={route.path + route.title}
        path={route.path}
        element={
          <>
            <PageTitleUI title={route.title} />
            <route.element />
          </>
        }
      >
        {Array.isArray(route.children) && route.children.map(renderRoute)}
      </ReactDomRoute>
    );
  }

  return (
    <Routes>
      <ReactDomRoute path="/login" element={<Login />} />
      <ReactDomRoute path="/logout" element={<Logout />} />
      {AppRoutesConfig.map(renderRoute)}
      <ReactDomRoute path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
