import { Steps } from "antd";
import { FC } from "react";
import { OnBoardingRoutes } from "../onboarding.routes";
import { useOnboardingFeature } from "../onboarding.feature";

export const StepsWidget: FC = () => {
  const { currentIndex } = useOnboardingFeature();

  return (
    <div>
      <Steps
        current={currentIndex}
        items={OnBoardingRoutes.map((a) => {
          return {
            title: a.title,
          };
        })}
      />
    </div>
  );
};
