import { ButtonUI } from "@ui/buttons/button.ui";
import { FC, ReactNode, useRef } from "react";

export const TableContainerWithLoaderWidget: FC<{
  children: ReactNode;
  allItemsLoaded: boolean;
  isLoading: boolean;
  isValidating: boolean;
  loadMore: () => void;
}> = ({ children, isLoading, isValidating, allItemsLoaded, loadMore }) => {
  const loadMoreRef = useRef(null);
  return (
    <div className="relative ">
      {children}
      <div
        className="absolute"
        style={{
          bottom: "0%",
          left: "0px",
          width: "2px",
          height: "1200px",
          zIndex: "9",
        }}
        ref={loadMoreRef}
      />
      <div className="flex items-center justify-center p-4">
        {!allItemsLoaded && !isLoading ? (
          <ButtonUI isLoading={isValidating} onClick={loadMore} type="text">
            {isValidating ? "Loading more..." : "Load more"}
          </ButtonUI>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
