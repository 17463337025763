import { CheckCircleFilled, DeleteOutlined } from "@ant-design/icons";
import { Avatar, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC } from "react";
import toast from "react-hot-toast";
import { useFlags } from "src/app/features/feature-flags/feature-flags.feature";
import { useRequestFeature } from "src/app/features/requests/request.feature";
import { titleCaseFormatterTool } from "src/app/tools/formatters/title-case.formatter.tool";
import { ButtonUI } from "src/app/ui/buttons/button.ui";
import { TableUI } from "src/app/ui/tables/table.ui";
import { useIntegrationDomain } from "src/domain/integrations/integration.domain";
import { Platform, PlatformState, enumPlatform } from "src/graphql/client";
import { TableComponent } from "../../../advertising-assets/advertising-assets.table.widget";
import { PlatformIconWidget } from "./platform-icon.widget";
import PlatformRequirementsWidget from "./platform-requirements.widget";

export const PlatformsIntegrationsTableWidget: FC<TableComponent> = ({
  data = [],
  onRefresh,
  isLoading: isTableLoading,
}) => {
  const { getPlatformOauthUrl, disconnect } = useIntegrationDomain();
  const { sendRequest, isLoading } = useRequestFeature();

  const flags = useFlags();

  const disconnectIntegration = (platform: Platform) => {
    sendRequest({
      id: `disconnectToPlatform-${platform}`,
      request: disconnect(platform),
      onSuccess: () => {
        toast.success("Platform disconnected");
        if (onRefresh) onRefresh();
      },
    }).then();
  };

  const launchPopup = (integration: PlatformState) => {
    sendRequest({
      id: `connectToPlatform-${integration.platform}`,
      request: getPlatformOauthUrl(integration.platform, flags),
      onSuccess: (url) => {
        if (!url) return;
        window.open(
          url,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=700",
        );
      },
    });
  };

  const getPlatformName = (platform: Platform) => {
    switch (platform) {
      case enumPlatform.GOOGLE:
        return "Connect to Google Adwords";
      case enumPlatform.META:
        return "Connect to Meta";
      case enumPlatform.CTV:
        return "Connect to CTV";
      case enumPlatform.TIKTOK:
        return "Connect to Tiktok";
      default:
        return "";
    }
  };

  const columns: ColumnsType<PlatformState> = [
    {
      title: "",
      dataIndex: "platform",
      onCell: (record) => {
        if (record.status) {
          return { colSpan: 1 };
        }
        return { colSpan: 2 };
      },
      render: (platform: Platform, record) => {
        if (!record.status) {
          return (
            <div className="flex flex-1 flex-col justify-center gap-2">
              <div className="flex flex-1 justify-center">
                <ButtonUI
                  isLoading={isLoading(`connectToPlatform-${record.platform}`)}
                  onClick={() => launchPopup(record)}
                  icon={<PlatformIconWidget platform={platform} size={21} />}
                  type={"text"}
                  className="m-1 flex min-w-[50%] justify-center border-slate-300 align-middle"
                >
                  {getPlatformName(platform)}
                </ButtonUI>
              </div>
              <PlatformRequirementsWidget platform={platform} />
            </div>
          );
        }
        return (
          <div className="flex items-center gap-2">
            <Avatar
              src={<PlatformIconWidget platform={platform} size={21} />}
              style={{
                backgroundColor: "rgb(204 204 204 / 41%)",
                padding: "2px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <div>
              <div>{titleCaseFormatterTool(platform)}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      onCell: (record) => {
        if (record.status) {
          return { colSpan: 1 };
        }
        return { colSpan: 0 };
      },
      dataIndex: "action",
      render: (_, record) => {
        return (
          <div className="flex items-center justify-end gap-4">
            {getIntegrationStatus(record)}
          </div>
        );
      },
    },
  ];

  const getIntegrationStatus = (integration: PlatformState) => {
    if (integration.status === "OK")
      return (
        <div className="flex w-full items-center justify-end gap-3">
          <small>
            Connection by:{" "}
            {integration.userEmail ?? <span className="px-2">-</span>}
          </small>
          <Tag
            icon={<CheckCircleFilled />}
            color="success"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Connected
          </Tag>
          <div>
            <ButtonUI
              isLoading={isLoading(
                `disconnectToPlatform-${integration.platform}`,
              )}
              onClick={() => disconnectIntegration(integration.platform)}
              type="light"
            >
              {isLoading(`disconnectToPlatform-${integration.platform}`) ? (
                <></>
              ) : (
                <DeleteOutlined />
              )}
            </ButtonUI>
          </div>
        </div>
      );
    return null;
  };

  return (
    <div
      style={{
        // background: "#F5F5F5",
        borderRadius: "8px",
        border: "1px solid #E5E5E5",
      }}
    >
      <TableUI
        showHeader={false}
        isLoading={isTableLoading}
        data={data}
        columns={columns}
      />
    </div>
  );
};
