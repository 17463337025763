import Icon from "@ant-design/icons";
import googleIcon from "src/app/ui/icons/google-ads-icon/google-ads.svg";

const GoogleAdsIcon = ({ props }: { props?: Record<string, any> }) => {
  return (
    <Icon
      component={() => (
        <img src={googleIcon} alt="Google" width={20} height={20} {...props} />
      )}
    />
  );
};

export default GoogleAdsIcon;
