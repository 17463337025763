import { Typography } from "antd";
import { FC } from "react";
import { BrandKeywords } from "../pages/brand-settings/pages/brand-detail/components/brand-keywords/brand-keywords.component";
import { BrandExtraAttribute } from "src/graphql/client";

export const BrandExtraAttributesWidget: FC<{
  extraAttributes: BrandExtraAttribute;
  onUpdateAttribute: (
    attribute: keyof BrandExtraAttribute,
    values: string[],
  ) => void;
}> = ({ extraAttributes, onUpdateAttribute }) => {
  return (
    <section>
      <Typography.Title level={5}>Must win attributes</Typography.Title>
      <small>
        What attributes do you want your brand to be associated with?
      </small>
      <div>
        <div className="h-2" />
        <BrandKeywords
          words={extraAttributes.mustWinAttributes ?? []}
          onChange={(tags) => onUpdateAttribute("mustWinAttributes", tags)}
        />
        <div className="h-2" />
      </div>
      <div className="h-6" />
      <Typography.Title level={5}>Distinctive brand assets</Typography.Title>
      <small>
        Our models can help you make sure your DBAs are present in your
        creatives.
      </small>
      <div>
        <div className="h-4" />
        <h5>Brand names</h5>
        <small>
          We’ll use this to enhance our detection of brand presence in your ads.
          Load them like this: “Bidvest Insurance”, “Bidvest”, “BI”, “B
          Insurance”.
        </small>
        <div className="h-2" />
        <BrandKeywords
          words={extraAttributes.names ?? []}
          onChange={(tags) => onUpdateAttribute("names", tags)}
        />
        <div className="h-2" />
      </div>
      <div>
        <div className="h-4" />
        <h5>Brand communication idea</h5>
        <small>
          One concept that you generally want to transmit as a brand. E.g. “..”.
          We recommend using only one.
        </small>
        <div className="h-2" />
        <BrandKeywords
          words={extraAttributes.communicationIdeas ?? []}
          onChange={(tags) => onUpdateAttribute("communicationIdeas", tags)}
        />
        <div className="h-2" />
      </div>
      <div>
        <div className="h-4" />
        <h5>Visual element</h5>
        <small>
          A visual concept owned by your brand. E.g. “A drop of water”. We
          recommend using only one.
        </small>
        <div className="h-2" />
        <BrandKeywords
          words={extraAttributes.visualElements ?? []}
          onChange={(tags) => onUpdateAttribute("visualElements", tags)}
        />
        <div className="h-2" />
      </div>
      <div>
        <div className="h-4" />
        <h5>Overlay text tagline</h5>
        <small>
          Specific phrase that you aim to place in your supers. E.g. “Just do
          it”.
        </small>
        <div className="h-2" />
        <BrandKeywords
          words={extraAttributes.overlayTextTaglines ?? []}
          onChange={(tags) => onUpdateAttribute("overlayTextTaglines", tags)}
        />
        <div className="h-2" />
      </div>
      <div>
        <div className="h-4" />
        <h5>Speech tagline</h5>
        <small>
          Specific phrase that you aim to mention in your speech. E.g. “I’m
          loving it”.
        </small>
        <div className="h-2" />
        <BrandKeywords
          words={extraAttributes.speechTaglines ?? []}
          onChange={(tags) => onUpdateAttribute("speechTaglines", tags)}
        />
        <div className="h-2" />
      </div>
      <div className="h-4" />
    </section>
  );
};
