import { client } from "@repositories/clients/graphql.client";
import { PretestRepository } from "src/domain/pretest/pretest.domain";

export class PretestBackendRepository implements PretestRepository {
  async getPretest({ creativeId }: any): Promise<any> {
    return {
      id: creativeId,
      name: `Pretest ${creativeId}`,
    };
  }
  ctvBundles() {
    return client.chain.query.ctvBundles.get() as Promise<string[]>;
  }
}
