export const shortDecimalsFormatterTool = (number: number) => {
  let formattedNumber;

  if (number >= 1000000) {
    formattedNumber = formatNumber(number / 1000000) + "M";
  } else if (number >= 1000) {
    formattedNumber = formatNumber(number / 1000) + "K";
  } else {
    formattedNumber = formatNumber(number);
  }

  return String(formattedNumber);
};

export function formatNumber(num: number): number {
  if (Number.isInteger(num)) {
    return Number(num.toString());
  } else {
    return Number(num.toFixed(2));
  }
}
