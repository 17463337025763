import { Button, ButtonProps, ConfigProvider } from "antd";
import classNames from "classnames";
import { omit } from "lodash";
import { FC } from "react";

const variantConfig = {
  default: {
    borderRadius: 4,
    colorBorder: "var(--black-white-black)",
  },
  magic: {
    borderRadius: 4,
    colorPrimaryHover: "red",
  },
  light: {
    borderRadius: 4,
    defaultBg: "var(--slate-200)",
    defaultBorderColor: "var(--slate-200)",
    defaultHoverBg: "var(--slate-200)",
    defaultHoverBorderColor: "var(--slate-200)",
    defaultHoverColor: "var(--black-white-black)",
  },
  withIcon: {
    borderRadius: 4,
    defaultColor: "var(--slate-600)",
    defaultBg: "var(--black-white-white)",
    defaultBorderColor: "var(--slate-400)",
    defaultHoverBg: "var(--black-white-white)",
    defaultHoverBorderColor: "var(--slate-400)",
    defaultHoverColor: "var(--slate-600)",
  },
  dark: {
    borderRadius: 4,
    defaultColor: "var(--black-white-white)",
    defaultBg: "var(--black-white-black)",
    defaultBorderColor: "var(--black-white-black)",
    defaultHoverBg: "var(--black-white-black)",
    defaultHoverBorderColor: "var(--black-white-black)",
    defaultHoverColor: "var(--black-white-white)",
  },
};

const classesToApply = {
  magic: "button-border-pink-gradient",
};
export const ButtonUI: FC<
  ButtonProps & { variant?: keyof typeof variantConfig }
> = ({ variant = "default", ...props }) => {
  const buttonProps = omit(props, ["children", "className"]);

  const classnames = classNames(props.className, {
    [classesToApply[variant as keyof typeof classesToApply] ?? ""]:
      !!classesToApply[variant as keyof typeof classesToApply],
  });

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: variantConfig[variant],
        },
      }}
    >
      <Button className={classnames} {...buttonProps}>
        {props.children}
      </Button>
    </ConfigProvider>
  );
};

ButtonUI.displayName = "SelectInputUI";
