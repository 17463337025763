import { client } from "src/app/features/repositories/clients/graphql.client";
import { UsersRepository } from "src/domain/users/users.domain";
import {
  AuthenticationInput,
  ForgetPasswordInput,
  SignUpInput,
  User,
  UserListElement,
  UserProfileInput,
  UserWithPermissions,
} from "src/graphql/client";

export class UsersBackendRepository implements UsersRepository {
  deleteUser(userId: string): Promise<boolean> {
    return client.chain.mutation.deleteUser({ userId }).get();
  }

  updateUser(input: UserProfileInput): Promise<User> {
    return client.chain.mutation.updateUserProfile({ input }).get({
      id: true,
    }) as Promise<User>;
  }

  getUserByBrand(brandId: string): Promise<UserListElement[]> {
    return client.chain.query.getUsersByBrand({ brandId }).get({
      id: true,
      name: true,
      email: true,
      isAdmin: true,
      brands: {
        brandId: true,
        permissions: {
          id: true,
          name: true,
        },
      },
    }) as Promise<UserListElement[]>;
  }

  getUsersByBusinessAccount(): Promise<UserListElement[]> {
    return client.chain.query.getUsersByBusinessAccount.get({
      id: true,
      name: true,
      isAdmin: true,
      brands: {
        brandId: true,
        permissions: {
          id: true,
          name: true,
        },
      },
      email: true,
    }) as Promise<UserListElement[]>;
  }

  getLoggedInUser() {
    return client.chain.query.getLoggedInUser.get({
      id: true,
      name: true,
      email: true,
      userPermissions: true,
      config: {
        allowedCreativeAnalyticsChannels: true,
        allowedCreativeLabChannels: true,

        pack: true,
        visibleFeatures: true,
        visibleTagMetrics: true,
        visibleAdMetrics: true,
        visibleCognitiveMetrics: true,
        visibleRecommendationSources: true,

        visibleCreativeAnalyticsCampaignObjectives: true,
        visibleCreativeLabCampaignObjectives: true,

        purchasableCreativeLabChannels: true,
        purchasableCreativeAnalyticsChannels: true,
      },
      brandPermissions: {
        brandId: true,
        permissions: true,
      },
      businessAccount: {
        id: true,
        status: true,
        brands: {
          id: true,
          name: true,
          logoUrl: true,
          status: true,
          adAccounts: true,
          socialAccounts: true,
        },
      },
      isAdmin: true,
    }) as Promise<UserWithPermissions>;
  }

  login(input: AuthenticationInput) {
    return client.chain.mutation
      .logIn({
        input: {
          email: input.email,
          password: input.password,
        },
      })
      .get({
        sessionToken: true,
      });
  }

  impersonateLogin(input: AuthenticationInput) {
    return client.chain.mutation
      .impersonateLogin({
        input: {
          email: input.email,
          password: input.password,
        },
      })
      .get({
        sessionToken: true,
      });
  }

  logout() {
    return client.chain.mutation.logout.get();
  }

  regeneratePassword(input: ForgetPasswordInput) {
    return client.chain.mutation.regeneratePassword({ input }).get();
  }

  createUser(user: SignUpInput, code: string): Promise<User> {
    return client.chain.mutation
      .signUp({
        user,
        code,
      })
      .get({
        id: 1,
      }) as Promise<User>;
  }

  getUserHash() {
    return client.chain.query.generateIntercomUserHash.get();
  }
}
