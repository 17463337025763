import { useMemo } from "react";
import { RecommendationGoal } from "src/domain/recommendations/recommendantions.model";
import {
  BusinessAccountConfig,
  CampaignObjective,
  CognitiveMetric,
  Metric,
} from "src/graphql/client";

export const useConfigurationFeature = (
  configs: BusinessAccountConfig | undefined,
) => {
  const canIAccess = useMemo(
    () => ({
      tagPerformanceOverTime: () =>
        configs?.visibleFeatures.includes("tagPerformanceOverTime") ?? false,
      creativePerformanceOverTime: () =>
        configs?.visibleFeatures.includes("creativePerformanceOverTime") ??
        false,
      timeRange: () => configs?.visibleFeatures.includes("timeRange") ?? false,
      metric: (metric: Metric) =>
        configs?.visibleTagMetrics.includes(metric) ?? false,
      cognitiveMetric: (metric: CognitiveMetric): boolean =>
        configs?.visibleCognitiveMetrics.includes(metric) ?? false,
      visibleTagMetrics: (metric: Metric): boolean =>
        configs?.visibleTagMetrics.includes(metric) ?? false,
      visibleCreativeAnalyticsCampaignObjectives: (): CampaignObjective[] => {
        return configs?.visibleCreativeAnalyticsCampaignObjectives ?? [];
      },
      visibleCreativeLabCampaignObjectives: (): CampaignObjective[] => {
        return configs?.visibleCreativeLabCampaignObjectives ?? [];
      },
      visibleAdMetrics: (metric: Metric): boolean =>
        configs?.visibleAdMetrics.includes(metric) ?? false,
      visibleRecommendationSources: (goal: RecommendationGoal): boolean =>
        configs?.visibleRecommendationSources.includes(goal) ?? false,
    }),
    [configs],
  );

  return {
    canIAccess,
  };
};

export type ConfigurationFeature = ReturnType<typeof useConfigurationFeature>;

export type CanIAccessType = ConfigurationFeature["canIAccess"];
