import { FC, useEffect } from "react";

import { useRequestFeature } from "src/app/features/requests/request.feature";
import AnimationUI from "src/app/ui/animations/animation.ui";
import { LoaderStateUI } from "src/app/ui/states/loader-state.ui";
import { useBusinessDomain } from "src/domain/business/business.domain";
import { useUsersDomain } from "src/domain/users/users.domain";
import { enumBusinessStatus } from "src/graphql/client";
import useSWR from "swr";
import validAnimation from "./valid.animation.json";

const ReadyPage: FC = () => {
  const { sendRequest, isLoading } = useRequestFeature();
  const { updateBusinessAccountStatus } = useBusinessDomain();
  const { getUser } = useUsersDomain();
  const {
    data: user,
    isLoading: isLoadingUser,
    mutate,
  } = useSWR("getUser", getUser);

  const setBusinessAsOnboardingCompleted = () => {
    sendRequest({
      id: "ReadyPage-updateBusinessAccountStatus",
      request: updateBusinessAccountStatus({
        status: enumBusinessStatus.onboardingCompleted,
      }).then(),
    });
  };

  const checkStatus = async () => {
    await mutate();
    if (!user) return;
    if (
      user?.businessAccount?.status ===
        enumBusinessStatus.onboardingCompleted ||
      user?.businessAccount?.status === enumBusinessStatus.businessActive
    )
      return;
    setBusinessAsOnboardingCompleted();
  };

  useEffect(() => {
    checkStatus();
  }, [user]);

  if (isLoading("ReadyPage-updateBusinessAccountStatus") || isLoadingUser) {
    return (
      <div className="mx-auto flex h-40 max-w-4xl flex-col justify-center">
        <LoaderStateUI isLoading={true} />
      </div>
    );
  }

  return (
    <div
      className="animFadeIn w-full"
      style={{
        maxWidth: "660px",
        margin: "0 auto",
      }}
    >
      <h1>Ready!</h1>
      <p>We will notify you once the full integration is finished.</p>
      <div className="h-8"></div>
      <AnimationUI
        style={{
          height: "240px",
        }}
        animation={validAnimation}
      />
      <div className="h-8"></div>
    </div>
  );
};
export default ReadyPage;
