import { FCC } from "@types";

type CardOutline = {
  className?: string;
  style?: React.CSSProperties;
};

export const CardOutlineUI: FCC<CardOutline> = ({
  children,
  className,
  style,
}) => {
  return (
    <div
      className={className}
      style={{
        borderRadius: "8px",
        border: "1px solid #E5E5E5",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
