import { Typography } from "antd";
import { FC } from "react";
import { CreateBrandFormPage } from "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/account-and-brands/pages/brands/pages/new-brand/create-brand.form.page";
import { useOnboardingFeature } from "../onboarding.feature";
import { useRequestFeature } from "src/app/features/requests/request.feature";
import { useBrandsDomain } from "src/domain/brands/brands.domain";
import { BrandExtraAttribute, CreateBrandInput } from "src/graphql/client";

const CreateYourBrandPage: FC = () => {
  const { gotoNextStep, cancelOnBoarding } = useOnboardingFeature();

  const { sendRequest } = useRequestFeature();
  const { createBrand, updateBrandExtraAttributes } = useBrandsDomain();

  const onSubmit = async (newBrand: CreateBrandInput) => {
    return await sendRequest({
      id: "create-brand",
      request: createBrand(newBrand),
      onSuccess: () => {
        gotoNextStep();
      },
    });
  };

  const updateExtraAttributes = async (
    brandId: string,
    extraAttributes: BrandExtraAttribute,
  ) => {
    if (!brandId) return;
    await sendRequest({
      request: updateBrandExtraAttributes(extraAttributes, brandId),
    });
  };

  return (
    <div
      className="animFadeIn w-full"
      style={{
        maxWidth: "460px",
        margin: "0 auto",
      }}
    >
      <Typography.Title level={4}>Create your brand</Typography.Title>
      <div className="h-4" />
      <CreateBrandFormPage
        onSubmit={async (response) => {
          const newBrand = await onSubmit(response.brand);
          await updateExtraAttributes(newBrand.id, response.extraAttributes);
        }}
        onCancel={cancelOnBoarding}
      />
    </div>
  );
};
export default CreateYourBrandPage;
