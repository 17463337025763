import MetaIcon from "@ui/icons/meta-ads-icon/meta-ads-icon";
import { FC } from "react";
import { SocialAccountType, enumChannel } from "src/graphql/client";
import { getChannelIcon } from "./channel.icon.ui";

type AssetTypeIconUIProps = {
  assetType: SocialAccountType;
};

export const AssetTypeIconUI: FC<AssetTypeIconUIProps> = ({ assetType }) => {
  return getAssetTypeIcon(assetType);
};

export const getAssetTypeIcon = (platform: SocialAccountType, props = {}) => {
  if (platform === ("MetaAdAccount" as any)) return <MetaIcon />;

  if (platform === "FacebookPage")
    return getChannelIcon(enumChannel.facebook, props);
  if (platform === "TiktokAccount")
    return getChannelIcon(enumChannel.tiktok, props);
  if (platform === "InstagramAccount")
    return getChannelIcon(enumChannel.instagram, props);
  return <></>;
};
