import { FC, useEffect, useState } from "react";
import { useFlags } from "src/app/features/feature-flags/feature-flags.feature";
import { useRequestFeature } from "src/app/features/requests/request.feature";
import { useUsersDomain } from "src/domain/users/users.domain";
import { enumPlatform } from "src/graphql/client";
import useSWR from "swr";
import {
  PlatformsIntegrations,
  useIntegrationDomain,
} from "../../../../../../../../../../../domain/integrations/integration.domain";
import { PlatformsIntegrationsTableWidget } from "./platforms-integrations.table.widget";

type PlatformsIntegrationsPageProps = {
  onIntegrationsStatusChanges?: (integrations: PlatformsIntegrations[]) => void;
};

const PlatformsIntegrationsPage: FC<PlatformsIntegrationsPageProps> = ({
  onIntegrationsStatusChanges,
}) => {
  const { getIntegrations } = useIntegrationDomain();
  const { getUser } = useUsersDomain();
  const { sendRequest, isLoading } = useRequestFeature();

  const { data: user, isLoading: isUserLoading } = useSWR("getUser", getUser);

  const [integrations, setIntegrations] = useState<PlatformsIntegrations[]>();

  const {
    enableGoogleAdsFeature,
    enableTiktokFeature,
    enableTvScientificFeature,
  } = useFlags();

  const fetchIntegration = () => {
    if (!user?.businessAccount) return;
    return sendRequest({
      request: getIntegrations({ path: "platforms-integrations" }),
      id: "fetchingIntegrations",
    }).then((response) => {
      if (onIntegrationsStatusChanges) onIntegrationsStatusChanges(response);
      setIntegrations(response);
    });
  };

  useEffect(() => {
    if (isUserLoading) return;
    if (!user?.businessAccount) return;
    fetchIntegration();
  }, [user]);

  function popupMessageReceiver(event: any) {
    const { type, status } = event.data;

    if (status === "success" && type === "oauth-integrations")
      fetchIntegration();
  }

  useEffect(() => {
    window.addEventListener("message", popupMessageReceiver);
    return () => {
      window.removeEventListener("message", popupMessageReceiver);
    };
  }, []);

  // Filter based on Feature Flags for google adds
  const filteredIntegrations = integrations?.filter((int) => {
    if (int.platform === enumPlatform.GOOGLE) {
      return enableGoogleAdsFeature;
    }

    if (int.platform === enumPlatform.TIKTOK) {
      return enableTiktokFeature;
    }

    if (int.platform === enumPlatform.CTV) {
      return enableTvScientificFeature;
    }

    return true;
  });

  return (
    <div>
      <PlatformsIntegrationsTableWidget
        data={filteredIntegrations}
        onRefresh={() => fetchIntegration()}
        isLoading={isLoading("fetchingIntegrations")}
      />
    </div>
  );
};
export default PlatformsIntegrationsPage;
