import {
  linkTypeMap,
  createClient as createClientOriginal,
  generateGraphqlOperation,
  assertSameVersion,
} from "@genql/runtime";
import types from "./types.esm";
var typeMap = linkTypeMap(types);
export * from "./guards.esm";

export var version = "2.10.0";
assertSameVersion(version);

export var createClient = function (options) {
  options = options || {};
  var optionsCopy = {
    url: "https://api-beta.memorable.io/graphql",
    queryRoot: typeMap.Query,
    mutationRoot: typeMap.Mutation,
    subscriptionRoot: typeMap.Subscription,
  };
  for (var name in options) {
    optionsCopy[name] = options[name];
  }
  return createClientOriginal(optionsCopy);
};

export const enumChannel = {
  facebook: "facebook",
  instagram: "instagram",
  tiktok: "tiktok",
  ooh: "ooh",
  youtube: "youtube",
  content: "content",
  automatedAppAds: "automatedAppAds",
  crossChannel: "crossChannel",
  ctv: "ctv",
  pinterest: "pinterest",
  snapchat: "snapchat",
  reddit: "reddit",
};

export const enumMetric = {
  impressions: "impressions",
  reach: "reach",
  frequency: "frequency",
  brandLift: "brandLift",
  costPerMille: "costPerMille",
  engagement: "engagement",
  engagementRate: "engagementRate",
  costPerEngagement: "costPerEngagement",
  videoViews: "videoViews",
  videoThroughRate: "videoThroughRate",
  costPerCompletedView: "costPerCompletedView",
  clicks: "clicks",
  clickThroughRate: "clickThroughRate",
  costPerClick: "costPerClick",
  conversion: "conversion",
  conversionRate: "conversionRate",
  costPerAcquisition: "costPerAcquisition",
  costPerAppInstall: "costPerAppInstall",
  costPerLead: "costPerLead",
  leadRate: "leadRate",
  appInstallRate: "appInstallRate",
  usage: "usage",
  spend: "spend",
  leads: "leads",
  appInstall: "appInstall",
  brandLiftRatio: "brandLiftRatio",
  costPerBrandLift: "costPerBrandLift",
  attention: "attention",
  positiveEmotion: "positiveEmotion",
  distraction: "distraction",
  confusion: "confusion",
  awareness: "awareness",
  action: "action",
};

export const enumCountry = {
  AFG: "AFG",
  ALA: "ALA",
  ALB: "ALB",
  DZA: "DZA",
  ASM: "ASM",
  AND: "AND",
  AGO: "AGO",
  AIA: "AIA",
  ATA: "ATA",
  ATG: "ATG",
  ARG: "ARG",
  ARM: "ARM",
  ABW: "ABW",
  AUS: "AUS",
  AUT: "AUT",
  AZE: "AZE",
  BHS: "BHS",
  BHR: "BHR",
  BGD: "BGD",
  BRB: "BRB",
  BLR: "BLR",
  BEL: "BEL",
  BLZ: "BLZ",
  BEN: "BEN",
  BMU: "BMU",
  BTN: "BTN",
  BOL: "BOL",
  BES: "BES",
  BIH: "BIH",
  BWA: "BWA",
  BVT: "BVT",
  BRA: "BRA",
  IOT: "IOT",
  BRN: "BRN",
  BGR: "BGR",
  BFA: "BFA",
  BDI: "BDI",
  CPV: "CPV",
  KHM: "KHM",
  CMR: "CMR",
  CAN: "CAN",
  CYM: "CYM",
  CAF: "CAF",
  TCD: "TCD",
  CHL: "CHL",
  CHN: "CHN",
  CXR: "CXR",
  CCK: "CCK",
  COL: "COL",
  COM: "COM",
  COG: "COG",
  COD: "COD",
  COK: "COK",
  CRI: "CRI",
  CIV: "CIV",
  HRV: "HRV",
  CUB: "CUB",
  CUW: "CUW",
  CYP: "CYP",
  CZE: "CZE",
  DNK: "DNK",
  DJI: "DJI",
  DMA: "DMA",
  DOM: "DOM",
  ECU: "ECU",
  EGY: "EGY",
  SLV: "SLV",
  GNQ: "GNQ",
  ERI: "ERI",
  EST: "EST",
  SWZ: "SWZ",
  ETH: "ETH",
  FLK: "FLK",
  FRO: "FRO",
  FJI: "FJI",
  FIN: "FIN",
  FRA: "FRA",
  GUF: "GUF",
  PYF: "PYF",
  ATF: "ATF",
  GAB: "GAB",
  GMB: "GMB",
  GEO: "GEO",
  DEU: "DEU",
  GHA: "GHA",
  GIB: "GIB",
  GRC: "GRC",
  GRL: "GRL",
  GRD: "GRD",
  GLP: "GLP",
  GUM: "GUM",
  GTM: "GTM",
  GGY: "GGY",
  GIN: "GIN",
  GNB: "GNB",
  GUY: "GUY",
  HTI: "HTI",
  HMD: "HMD",
  VAT: "VAT",
  HND: "HND",
  HKG: "HKG",
  HUN: "HUN",
  ISL: "ISL",
  IND: "IND",
  IDN: "IDN",
  IRN: "IRN",
  IRQ: "IRQ",
  IRL: "IRL",
  IMN: "IMN",
  ISR: "ISR",
  ITA: "ITA",
  JAM: "JAM",
  JPN: "JPN",
  JEY: "JEY",
  JOR: "JOR",
  KAZ: "KAZ",
  KEN: "KEN",
  KIR: "KIR",
  PRK: "PRK",
  KOR: "KOR",
  KWT: "KWT",
  KGZ: "KGZ",
  LAO: "LAO",
  LVA: "LVA",
  LBN: "LBN",
  LSO: "LSO",
  LBR: "LBR",
  LBY: "LBY",
  LIE: "LIE",
  LTU: "LTU",
  LUX: "LUX",
  MAC: "MAC",
  MDG: "MDG",
  MWI: "MWI",
  MYS: "MYS",
  MDV: "MDV",
  MLI: "MLI",
  MLT: "MLT",
  MHL: "MHL",
  MTQ: "MTQ",
  MRT: "MRT",
  MUS: "MUS",
  MYT: "MYT",
  MEX: "MEX",
  FSM: "FSM",
  MDA: "MDA",
  MCO: "MCO",
  MNG: "MNG",
  MNE: "MNE",
  MSR: "MSR",
  MAR: "MAR",
  MOZ: "MOZ",
  MMR: "MMR",
  NAM: "NAM",
  NRU: "NRU",
  NPL: "NPL",
  NLD: "NLD",
  NCL: "NCL",
  NZL: "NZL",
  NIC: "NIC",
  NER: "NER",
  NGA: "NGA",
  NIU: "NIU",
  NFK: "NFK",
  MKD: "MKD",
  MNP: "MNP",
  NOR: "NOR",
  OMN: "OMN",
  PAK: "PAK",
  PLW: "PLW",
  PSE: "PSE",
  PAN: "PAN",
  PNG: "PNG",
  PRY: "PRY",
  PER: "PER",
  PHL: "PHL",
  PCN: "PCN",
  POL: "POL",
  PRT: "PRT",
  PRI: "PRI",
  QAT: "QAT",
  REU: "REU",
  ROU: "ROU",
  RUS: "RUS",
  RWA: "RWA",
  BLM: "BLM",
  SHN: "SHN",
  KNA: "KNA",
  LCA: "LCA",
  MAF: "MAF",
  SPM: "SPM",
  VCT: "VCT",
  WSM: "WSM",
  SMR: "SMR",
  STP: "STP",
  SAU: "SAU",
  SEN: "SEN",
  SRB: "SRB",
  SYC: "SYC",
  SLE: "SLE",
  SGP: "SGP",
  SXM: "SXM",
  SVK: "SVK",
  SVN: "SVN",
  SLB: "SLB",
  SOM: "SOM",
  ZAF: "ZAF",
  SGS: "SGS",
  SSD: "SSD",
  ESP: "ESP",
  LKA: "LKA",
  SDN: "SDN",
  SUR: "SUR",
  SJM: "SJM",
  SWE: "SWE",
  CHE: "CHE",
  SYR: "SYR",
  TWN: "TWN",
  TJK: "TJK",
  TZA: "TZA",
  THA: "THA",
  TLS: "TLS",
  TGO: "TGO",
  TKL: "TKL",
  TON: "TON",
  TTO: "TTO",
  TUN: "TUN",
  TUR: "TUR",
  TKM: "TKM",
  TCA: "TCA",
  TUV: "TUV",
  UGA: "UGA",
  UKR: "UKR",
  ARE: "ARE",
  GBR: "GBR",
  USA: "USA",
  UMI: "UMI",
  URY: "URY",
  UZB: "UZB",
  VUT: "VUT",
  VEN: "VEN",
  VNM: "VNM",
  VGB: "VGB",
  VIR: "VIR",
  WLF: "WLF",
  ESH: "ESH",
  YEM: "YEM",
  ZMB: "ZMB",
  ZWE: "ZWE",
};

export const enumOrderingDirection = {
  ASC: "ASC",
  DESC: "DESC",
};

export const enumAdRankingOrderingColumn = {
  impressions: "impressions",
  spend: "spend",
  frequencyValue: "frequencyValue",
  engagementRateValue: "engagementRateValue",
  videoThroughRateValue: "videoThroughRateValue",
  clickThroughRateValue: "clickThroughRateValue",
  leadRateValue: "leadRateValue",
  conversionRateValue: "conversionRateValue",
  appInstallRateValue: "appInstallRateValue",
  costPerMileValue: "costPerMileValue",
  costPerEngagementValue: "costPerEngagementValue",
  costPerCompletedVideoValue: "costPerCompletedVideoValue",
  costPerClickValue: "costPerClickValue",
  costPerLeadValue: "costPerLeadValue",
  costPerAcquisitionValue: "costPerAcquisitionValue",
  costPerAppInstallValue: "costPerAppInstallValue",
  brandLiftValue: "brandLiftValue",
};

export const enumSector = {
  ApparelAndAccessories: "ApparelAndAccessories",
  BeautyAndPersonalCare: "BeautyAndPersonalCare",
  FoodAndBeverage: "FoodAndBeverage",
  HomeAndGarden: "HomeAndGarden",
  SportsAndFitness: "SportsAndFitness",
  HomeAppliances: "HomeAppliances",
  HomeImprovement: "HomeImprovement",
  HouseholdSupplies: "HouseholdSupplies",
  PetCare: "PetCare",
  TobaccoAndSmokingAccessories: "TobaccoAndSmokingAccessories",
  ToysAndGames: "ToysAndGames",
  OilAndGas: "OilAndGas",
  RenewableEnergy: "RenewableEnergy",
  Utilities: "Utilities",
  BankingAndLending: "BankingAndLending",
  Insurance: "Insurance",
  InvestmentAndWealthManagement: "InvestmentAndWealthManagement",
  PharmaceuticalsAndBiotechnology: "PharmaceuticalsAndBiotechnology",
  MedicalDevices: "MedicalDevices",
  HealthcareServices: "HealthcareServices",
  ConstructionAndEngineering: "ConstructionAndEngineering",
  AerospaceAndDefense: "AerospaceAndDefense",
  TransportationEquipment: "TransportationEquipment",
  SoftwareAndITServices: "SoftwareAndITServices",
  HardwareAndElectronics: "HardwareAndElectronics",
  InternetServices: "InternetServices",
  TelecommunicationsEquipment: "TelecommunicationsEquipment",
  TelecommunicationsServices: "TelecommunicationsServices",
  NetworkingEquipment: "NetworkingEquipment",
  AirlinesAndAirTransportation: "AirlinesAndAirTransportation",
  HotelsAndResorts: "HotelsAndResorts",
  RestaurantsAndFoodServices: "RestaurantsAndFoodServices",
  TravelAgenciesAndTourOperators: "TravelAgenciesAndTourOperators",
  AmusementParksAndAttractions: "AmusementParksAndAttractions",
  TelevisionBroadcastingAndProduction: "TelevisionBroadcastingAndProduction",
  FilmProductionAndDistribution: "FilmProductionAndDistribution",
  MusicRecordingAndProduction: "MusicRecordingAndProduction",
  Publishing: "Publishing",
  Gaming: "Gaming",
  ResidentialRealEstate: "ResidentialRealEstate",
  CommercialRealEstate: "CommercialRealEstate",
  RealEstateInvestmentTrusts: "RealEstateInvestmentTrusts",
  RealEstateDevelopmentAndConstruction: "RealEstateDevelopmentAndConstruction",
  PublicAndPrivateSchools: "PublicAndPrivateSchools",
  CollegesAndUniversities: "CollegesAndUniversities",
  OnlineEducationPlatforms: "OnlineEducationPlatforms",
  VocationalAndTechnicalSchools: "VocationalAndTechnicalSchools",
  CorporateTrainingAndDevelopment: "CorporateTrainingAndDevelopment",
  Other: "Other",
};

export const enumBrandStatus = {
  IN_PROGRESS: "IN_PROGRESS",
  DATA_READY: "DATA_READY",
  MODEL_TRAINING: "MODEL_TRAINING",
  READY: "READY",
};

export const enumBusinessStatus = {
  onboardingPending: "onboardingPending",
  onboardingCompleted: "onboardingCompleted",
  onboardingRejected: "onboardingRejected",
  businessActive: "businessActive",
  businessInactive: "businessInactive",
};

export const enumPlatform = {
  META: "META",
  TIKTOK: "TIKTOK",
  GOOGLE: "GOOGLE",
  CUSTOM: "CUSTOM",
  CTV: "CTV",
};

export const enumAdAccountType = {
  CustomAdAccount: "CustomAdAccount",
  MetaAdAccount: "MetaAdAccount",
  TiktokAdAccount: "TiktokAdAccount",
  GoogleAdAccount: "GoogleAdAccount",
};

export const enumSocialAccountType = {
  CustomSocialAccount: "CustomSocialAccount",
  FacebookPage: "FacebookPage",
  InstagramAccount: "InstagramAccount",
  TiktokAccount: "TiktokAccount",
};

export const enumFeature = {
  timeRange: "timeRange",
  tagPerformanceOverTime: "tagPerformanceOverTime",
  creativePerformanceOverTime: "creativePerformanceOverTime",
  chatBot: "chatBot",
};

export const enumCognitiveMetric = {
  adRecallRate: "adRecallRate",
  bai: "bai",
  fiveSecondsIndex: "fiveSecondsIndex",
  textSaliencyScore: "textSaliencyScore",
  logoSaliencyScore: "logoSaliencyScore",
  memorability: "memorability",
  brandPresence: "brandPresence",
  aesthetics: "aesthetics",
  engagementPotential: "engagementPotential",
  authenticity: "authenticity",
  distinctiveness: "distinctiveness",
  aestheticAIGenerated: "aestheticAIGenerated",
  memorabilityTvc: "memorabilityTvc",
  memorabilityOoh: "memorabilityOoh",
  memorabilityLong: "memorabilityLong",
  memorabilitySm: "memorabilitySm",
  memorabilityDecay: "memorabilityDecay",
  brandLift: "brandLift",
  focus: "focus",
  cognitiveLoad: "cognitiveLoad",
  memorabilityRate: "memorabilityRate",
  cognitiveEase: "cognitiveEase",
};

export const enumCampaignObjective = {
  AWARENESS: "AWARENESS",
  BRAND_LIFT: "BRAND_LIFT",
  ENGAGEMENT: "ENGAGEMENT",
  VIDEO_VIEWS: "VIDEO_VIEWS",
  TRAFFIC: "TRAFFIC",
  LEAD_GENERATION: "LEAD_GENERATION",
  CONVERSIONS: "CONVERSIONS",
  PRODUCT_SALES: "PRODUCT_SALES",
  APP_PROMOTION: "APP_PROMOTION",
  CROSS_OBJECTIVE: "CROSS_OBJECTIVE",
};

export const enumConnectionState = {
  OK: "OK",
  ERROR: "ERROR",
};

export const enumFileType = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
};

export const enumRecommendationType = {
  bestPractices: "bestPractices",
  insights: "insights",
  llmKeyClaim: "llmKeyClaim",
  llmHeatmap: "llmHeatmap",
};

export const enumAction = {
  add: "add",
  avoid: "avoid",
  swap: "swap",
  fix: "fix",
  addSome: "addSome",
  addToExisting: "addToExisting",
  removeSome: "removeSome",
};

export const enumRecommendationMetric = {
  costPerMille: "costPerMille",
  adRecallRate: "adRecallRate",
  brandLift: "brandLift",
  brandLiftRatio: "brandLiftRatio",
  costPerBrandLift: "costPerBrandLift",
  videoThroughRate: "videoThroughRate",
  costPerCompletedView: "costPerCompletedView",
  engagementRate: "engagementRate",
  costPerEngagement: "costPerEngagement",
  clickThroughRate: "clickThroughRate",
  costPerClick: "costPerClick",
  conversionRate: "conversionRate",
  costPerAcquisition: "costPerAcquisition",
  leadRate: "leadRate",
  costPerLead: "costPerLead",
  appInstallRate: "appInstallRate",
  costPerAppInstall: "costPerAppInstall",
  readability: "readability",
  brandAssociation: "brandAssociation",
  simplicity: "simplicity",
  attention: "attention",
  hookRate: "hookRate",
};

export const enumTrend = {
  positive: "positive",
  negative: "negative",
  neutral: "neutral",
};

export const enumImpactScore = {
  veryHigh: "veryHigh",
  high: "high",
  medium: "medium",
  low: "low",
  veryLow: "veryLow",
};

export const enumSuperClass = {
  VISUAL_VALUES: "VISUAL_VALUES",
  SCENE: "SCENE",
  PEOPLE: "PEOPLE",
  OVERLAY_TEXT: "OVERLAY_TEXT",
  METADATA: "METADATA",
  BRAND_ELEMENTS: "BRAND_ELEMENTS",
  ELEMENTS: "ELEMENTS",
  AUDIO: "AUDIO",
  AD_COPY: "AD_COPY",
  SPEECH: "SPEECH",
};

export const enumHighlightsOrderingColumn = {
  costPerMille: "costPerMille",
  engagementRate: "engagementRate",
  videoThroughRate: "videoThroughRate",
  clickThroughRate: "clickThroughRate",
  costPerLead: "costPerLead",
  costPerAcquisition: "costPerAcquisition",
  costPerAppInstall: "costPerAppInstall",
  brandLiftRatio: "brandLiftRatio",
};

export const enumTipValue = {
  noTip: "noTip",
  keepUsing: "keepUsing",
  superstar: "superstar",
  useLess: "useLess",
  avoidUse: "avoidUse",
  caution: "caution",
  useMore: "useMore",
  dontExpand: "dontExpand",
  hiddenTreasure: "hiddenTreasure",
};

export const enumTipDetail = {
  noTip: "noTip",
  keepUsing: "keepUsing",
  superstar: "superstar",
  useLess: "useLess",
  avoidUse: "avoidUse",
  caution: "caution",
  useMore: "useMore",
  dontExpand: "dontExpand",
  hiddenTreasure: "hiddenTreasure",
};

export const enumPlaceHolderType = {
  STRING: "STRING",
  NUMBER: "NUMBER",
  PERCENTAGE: "PERCENTAGE",
  CURRENCY: "CURRENCY",
  METRIC: "METRIC",
  USAGE: "USAGE",
  METRIC_LIST: "METRIC_LIST",
};

export const enumTagRankingOrderingColumn = {
  tagImpressions: "tagImpressions",
  tagSpend: "tagSpend",
  frequencyValue: "frequencyValue",
  engagementRateValue: "engagementRateValue",
  videoThroughRateValue: "videoThroughRateValue",
  clickThroughRateValue: "clickThroughRateValue",
  leadRateValue: "leadRateValue",
  conversionRateValue: "conversionRateValue",
  appInstallRateValue: "appInstallRateValue",
  brandLiftRatioValue: "brandLiftRatioValue",
  costPerMileValue: "costPerMileValue",
  costPerEngagementValue: "costPerEngagementValue",
  costPerCompletedVideoValue: "costPerCompletedVideoValue",
  costPerClickValue: "costPerClickValue",
  costPerLeadValue: "costPerLeadValue",
  costPerAcquisitionValue: "costPerAcquisitionValue",
  costPerAppInstallValue: "costPerAppInstallValue",
  costPerBrandLiftValue: "costPerBrandLiftValue",
  brandLiftValue: "brandLiftValue",
};

export const enumCreativeStatus = {
  InProgress: "InProgress",
  Failed: "Failed",
  Ready: "Ready",
};

export const enumCreativeType = {
  Creative: "Creative",
  Animatic: "Animatic",
};

export const enumRecommendationFeedbackAction = {
  like: "like",
  dislike: "dislike",
};

export const enumInvitationType = {
  referral: "referral",
  internal: "internal",
};

export const enumUserCreationSource = {
  dashboard: "dashboard",
  externalApi: "externalApi",
};

export var generateQueryOp = function (fields) {
  return generateGraphqlOperation("query", typeMap.Query, fields);
};
export var generateMutationOp = function (fields) {
  return generateGraphqlOperation("mutation", typeMap.Mutation, fields);
};
export var generateSubscriptionOp = function (fields) {
  return generateGraphqlOperation("subscription", typeMap.Subscription, fields);
};
export var everything = {
  __scalar: true,
};
