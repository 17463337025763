import { isEnv } from "src/app/tools/utils/is-env.tool";

const userIdsBeta = ["01H57TYD6ERQBVV0FKCH9RPRK6"];

const userIdsStaging = ["01H57TYD6ERQBVV0FKCH9RPRK6"];

let userIdsExcludedHeap: string[] = [];
if (isEnv("STAGING")) {
  userIdsExcludedHeap = userIdsStaging;
}
if (isEnv("BETA")) {
  userIdsExcludedHeap = userIdsBeta;
}

export { userIdsExcludedHeap };
