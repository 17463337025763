import { Alert } from "antd";
import { FC } from "react";
import { FormState } from "react-hook-form";

type FormErrorsWidget = {
  formState: FormState<any>;
};

export const FormErrorsWidget: FC<FormErrorsWidget> = ({ formState }) => {
  const { errors } = formState;
  return (
    <>
      {Object.entries(errors).map(([key, value]) => {
        return (
          <div key={key} className="my-2">
            <Alert
              key={key}
              message={<>{value?.message}</>}
              type="error"
              showIcon
            />
          </div>
        );
      })}
    </>
  );
};
