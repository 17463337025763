import { Alert } from "antd";
import { FC } from "react";
import { OperationError } from "./error.model";

type ErrorsProps = {
  errors: OperationError[];
};

export const Errors: FC<ErrorsProps> = ({ errors }) => {
  return (
    <div>
      {errors?.map(
        (error) =>
          Boolean(error) && (
            <Alert
              key={error.message}
              message={error.message}
              type="error"
              showIcon
            />
          ),
      )}
    </div>
  );
};
