interface IEnumSectionPageType {
  readonly ca: "ca";
  readonly preTest: "pre-test";
  readonly all: "all";
}

export type SectionPageType =
  | IEnumSectionPageType["ca"]
  | IEnumSectionPageType["preTest"]
  | IEnumSectionPageType["all"];

export const enumSectionType: IEnumSectionPageType = {
  ca: "ca",
  preTest: "pre-test",
  all: "all",
};
