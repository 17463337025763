import { FC } from "react";
import CardPageUI from "src/app/ui/cards/card-page.ui";
import { CardScrolledStyle } from "src/app/ui/cards/card-scrolled-style.ui";
import { Avatar, Tabs, TabsProps, Tag } from "antd";
import EditPasswordPage from "./pages/password/edit-password.page";
import EditPersonalInfoPage from "./pages/personal-info/edit-personal-info.page";
import { useUsersDomain } from "src/domain/users/users.domain";
import useSWR from "swr";
import { useRequestFeature } from "src/app/features/requests/request.feature";

const MyProfilePage: FC = () => {
  const { getUser } = useUsersDomain();
  const { sendRequest } = useRequestFeature();

  const { data: currentUser } = useSWR("getUser", () =>
    sendRequest({
      id: "MyProfilePage-getUser",
      request: getUser(),
    }),
  );

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Personal information",
      children: <EditPersonalInfoPage />,
    },
    {
      key: "2",
      label: "Change your password",
      children: <EditPasswordPage />,
    },
  ];

  return (
    <CardPageUI>
      <CardScrolledStyle
        className="flex p-2"
        style={{
          position: "sticky",
          zIndex: "77",
          top: "0",
          background: "white",
        }}
      >
        <div className="flex flex-1 items-center gap-4">
          <Avatar size={60} alt="">
            {currentUser?.name?.charAt(0)}
          </Avatar>
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <strong>{currentUser?.name}</strong>
            <small>{currentUser?.email}</small>
            <span>
              <Tag>{currentUser?.isAdmin ? "Admin" : "User"}</Tag>
            </span>
          </div>
        </div>
      </CardScrolledStyle>
      <div className="p-2">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </CardPageUI>
  );
};
export default MyProfilePage;
