import {
  Creative,
  SaveCreativeInFolderInput,
  UploadDataResponse,
  UploadRequestInput,
} from "src/graphql/client";
import { UploaderRepository } from "../../uploader/uploader.feature";
import { client } from "../clients/graphql.client";

export class UploaderBackendRepository implements UploaderRepository {
  async generatePresignedUrl(
    input: UploadRequestInput,
  ): Promise<UploadDataResponse> {
    return client.chain.mutation
      .requestUploadData({
        input,
      })
      .get({
        id: 1,
        presignedData: {
          fields: 1,
          url: 1,
        },
      }) as Promise<UploadDataResponse>;
  }

  async saveFile(input: SaveCreativeInFolderInput): Promise<Creative> {
    return client.chain.mutation.saveCreativeInFolder({ input }).get({
      id: 1,
      name: 1,
      updatedAt: 1,
      url: 1,
      clipEmbeddingUrl: 1,
      status: 1,
    }) as Promise<Creative>;
  }
}
