import { create } from "zustand";
import { OperationLoading } from "./loading.model";
import { Directory } from "src/app/tools/models/directory.model";
import { Operation } from "../../models/operation.model";

export type LoadingsState = {
  loadings: Directory<Operation<any>>;
  isLoading: (operationId: string) => boolean;
  isAnyLoading: () => boolean;
  addLoading: (loading: Operation<any>) => void;
  removeLoading: (operationId: string) => void;
};

export const useLoadingsState = create<LoadingsState>((set, get) => ({
  loadings: {},
  addLoading: (loading: OperationLoading) =>
    set((state) => ({
      loadings: {
        ...state.loadings,
        [loading.id]: loading,
      },
    })),
  isLoading: (operationId: string) => !!get().loadings[operationId],
  isAnyLoading: () => {
    const loadings = get().loadings;
    return Boolean(Object.keys(loadings)?.length);
  },
  removeLoading: (operationId: string) =>
    set((state) => {
      const loadings = { ...state.loadings };
      delete loadings[operationId];
      return {
        loadings,
      };
    }),
}));
