import { useCallback } from "react";
import { useFlags } from "src/app/features/feature-flags/feature-flags.feature";
import useDebuggingState from "./debuging.state";

const useDebuggingDomain = () => {
  const { enableDebuggingFeature } = useFlags();

  const { debugging, setDebugging, context, setContext } = useDebuggingState();

  const toggleDebuggingMode = useCallback(() => {
    // Logic for toggling debugging mode goes here
    setDebugging(!debugging);
  }, [setDebugging, debugging]);

  return {
    toggleDebuggingMode,
    debugMode: debugging && enableDebuggingFeature,
    enableDebugging: enableDebuggingFeature,
    context,
    setContext,
  };
};

export default useDebuggingDomain;
