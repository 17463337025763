import { lazy } from "react";
import { Route } from "src/app/features/navigation/models/route.model";

const AccountAndBrands = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/account-and-brands/account-and-brands.page"
    ),
);

const BrandDetail = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/brand-settings/pages/brand-detail/brand-detail.page"
    ),
);
const IntegrationsAndAssets = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/integrations-and-assets/integrations-and-assets.page"
    ),
);
const People = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/people/business-people.page"
    ),
);
// const PlansAndBilling = lazy(
//   () =>
//     import(
//       "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/plans-and-billing/plans-and-billing.page"
//     ),
// );

export const BusinessSettingsRoutes: Route[] = [
  {
    path: "/account-and-brands",
    element: AccountAndBrands,
    isOnlyAdmin: true,
    title: "Account & Brands",
  },
  {
    path: "/account-and-brands/brand/:brandId",
    element: BrandDetail,
    isOnlyAdmin: true,
    title: "Brand Detail",
  },
  {
    path: "/integrations-and-assets",
    element: IntegrationsAndAssets,
    isOnlyAdmin: true,
    title: "Integrations",
  },
  {
    path: "/people",
    element: People,
    isOnlyAdmin: true,
    title: "People",
  },
  // {
  //   path: "/plans-and-billings",
  //   element: PlansAndBilling,
  //   title: "Plans & Billing",
  // },
];
