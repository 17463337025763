import { FC, useEffect, useRef, useState } from "react";
import pauseIcon from "./assets/pause.icon.svg";
import playIcon from "./assets/play.icon.svg";

import { CreativeProps } from "../asset.ui";
import "./video.asset.ui.css";

interface PhotoAssetProps extends CreativeProps {
  creative: any;
  isHeatMode?: boolean;
  isDetailMode?: boolean;
  assetRef?: React.MutableRefObject<HTMLVideoElement | HTMLImageElement | null>;
}

export const VideoAssetUI: FC<PhotoAssetProps> = ({
  creative,
  isDetailMode,
  isHeatMode,
  assetRef,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [isPlaying, setIsPlaying] = useState(isDetailMode ? true : false);

  const mouseEnterHandler = () => {
    setIsPlaying(true);
    if (!videoRef.current || !isDetailMode) return;
    videoRef.current.controls = true;
  };
  const mouseLeaveHandler = () => {
    if (!videoRef.current) return;
    if (!isDetailMode) {
      setIsPlaying(false);
    }
    videoRef.current.controls = false;
  };

  useEffect(() => {
    isPlaying ? videoRef.current?.play() : videoRef.current?.pause();
  }, [isPlaying]);

  // copy ref to like to context if it was used
  useEffect(() => {
    if (videoRef.current && assetRef) {
      assetRef.current = videoRef.current;
    }
  }, [videoRef.current]);

  const heatmapUrl = creative?.heatmapUrl;
  return (
    <div className="video-asset-wrapper">
      <video
        style={{
          objectFit: isDetailMode ? "contain" : "cover",
          height: "100%",
          width: "100%",
        }}
        className="bg-black-white-black"
        ref={videoRef}
        onMouseLeave={mouseLeaveHandler}
        onMouseEnter={mouseEnterHandler}
        controls={false}
        muted
        loop
        src={isHeatMode ? heatmapUrl : creative.url}
      />
      <div className="vid-icon">
        <img src={isPlaying ? pauseIcon : playIcon} alt="" />
      </div>
    </div>
  );
};
