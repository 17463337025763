import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

export type CardProps = {
  className?: string;
  style?: React.CSSProperties;
};

const CardUI: FC<PropsWithChildren<CardProps>> = ({
  children,
  className,
  style = {},
}) => {
  const classnames = classNames(
    "border border-solid border-slate-300 rounded-[8px] bg-black-white-white",
    {
      [className ?? ""]: !!className,
    },
  );
  return (
    <div className={classnames} style={style}>
      {children}
    </div>
  );
};
export default CardUI;
