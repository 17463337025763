import { ReactNode, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig, SWRConfiguration } from "swr";

import { ConfigProvider } from "antd";
import { themeConfig } from "./app/css/themeConfig";
import ErrorBoundaries from "./app/features/requests/features/errors/components/error-boundaries.component";
import ErrorsPage from "./app/features/requests/features/errors/pages/error.page";
import { LoadingPage } from "./app/features/requests/features/loadings/pages/loading.page";

const swrConfig: SWRConfiguration = {
  suspense: false,
  revalidateOnFocus: false,
};

export const Providers = ({ children }: { children: ReactNode }) => (
  <ErrorBoundaries fallback={<ErrorsPage />}>
    <BrowserRouter>
      <HelmetProvider>
        <SWRConfig value={{ ...swrConfig, provider: () => new Map() }}>
          <Suspense fallback={<LoadingPage />}>
            <ConfigProvider theme={themeConfig}>{children}</ConfigProvider>
          </Suspense>
        </SWRConfig>
      </HelmetProvider>
    </BrowserRouter>
  </ErrorBoundaries>
);
