import Icon from "@ant-design/icons";
import metaIcon from "./meta.svg";
const MetaIcon = ({ props }: { props?: Record<string, any> }) => {
  return (
    <Icon
      component={() => (
        <img src={metaIcon} alt="Google" width={20} height={20} {...props} />
      )}
    />
  );
};

export default MetaIcon;
