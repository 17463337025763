import { Route } from "src/app/features/navigation/models/route.model";
import { useSessionFeature } from "src/app/features/session/session.feature";
import { usePermissionsDomain } from "../../domain/permissions/permissions.domain";

export const usePermissionsFeature = () => {
  const { user, isAdmin, currentBrand } = useSessionFeature();

  const {
    canISeeBrandSettings: _canISeeBrandSettings,
    hasUserPermission,
    canUserImpersonate,
    ...rest
  } = usePermissionsDomain();

  const canISeeBrandSettings = () => {
    const isUserAdmin = isAdmin();
    return _canISeeBrandSettings(!!isUserAdmin, user, currentBrand);
  };

  const canINavigateTo = (route: Route) => {
    if (user === null) return true;
    const missingPermissions = route.requiredPermissions?.some((permission) => {
      return !hasUserPermission(permission, user, currentBrand);
    });
    if (missingPermissions) return false;

    if (route.isOnlyAdmin) return user?.isAdmin;

    if (route.isExcludeAdmin) return !user?.isAdmin;

    return true;
  };

  return {
    ...rest,
    canISeeBrandSettings,
    canINavigateTo,
    canUserImpersonate,
  };
};
