import { create } from "zustand";
import { persist } from "zustand/middleware";

type DebuggingState = {
  debugging: boolean;
  context: Record<"getPerformance" | string, any>;
  setDebugging: (value: boolean) => void;
  clearContext: () => void;
  setContext: (value: Record<string, any>) => void;
};

const useDebuggingState = create<DebuggingState>(
  persist(
    (set) => ({
      debugging: false,
      context: {},
      setDebugging: (value: { debugging: boolean }) =>
        set({ debugging: value }),
      setContext: (value: Record<"getPerformance" | string, any>) =>
        set((state: Record<"getPerformance" | string, any>) => ({
          context: { ...state.context, ...value },
        })),
      clearContext: () => set({ context: {} }),
    }),
    {
      name: "debugging-storage",
      getStorage: () => localStorage,
    },
  ) as any,
);

export default useDebuggingState;
