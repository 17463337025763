import { FC } from "react";
import { Ad, AdRankingElement } from "src/graphql/client";
import { AdsTableThumbnailWidget } from "./ads.table.thumbail.widget";

export const AdsTableAdNameWidget: FC<{
  ad: Ad;
  record: AdRankingElement;
}> = ({ ad, record }) => {
  const showId = false;
  return (
    <div className="flex justify-start gap-5">
      <div className="flex flex-col items-center justify-center">
        <AdsTableThumbnailWidget ad={ad} />
      </div>
      <div className="flex flex-col justify-center gap-1 break-all">
        <div className="body-12-regular flex text-black-white-black">
          {showId ? ad.id + " " : ""}
          {ad?.name}
        </div>
        <div className="flex break-all text-caption text-slate-500">
          {record?.campaign.name} / {record?.adGroup?.name}
        </div>
      </div>
    </div>
  );
};
