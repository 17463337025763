import { CampaignObjective, Channel, TimeRange } from "src/graphql/client";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type CreativeAnalyticsState = {
  currentDateRange: TimeRange;
  currentChannels: Channel[];
  currentObjective: CampaignObjective;
  setCurrentDateRange: (timeRange: TimeRange) => void;
  setCurrentChannels: (channels: Channel[]) => void;
  setCurrentObjective: (campaign: CampaignObjective) => void;
  reset: () => void;
};

const INITIAL_STATE = {
  currentDateRange: {},
  currentChannels: [],
  currentObjective: "all" as CampaignObjective,
};
export const useCreativeAnalyticsState = create<CreativeAnalyticsState>(
  persist(
    (set) => ({
      ...INITIAL_STATE,
      setCurrentDateRange: (timeRange: TimeRange) =>
        set({ currentDateRange: timeRange }),
      setCurrentChannels: (channels: Channel[]) =>
        set({ currentChannels: channels }),
      setCurrentObjective: (campaign: CampaignObjective) =>
        set({ currentObjective: campaign }),
      reset: () => set(INITIAL_STATE),
    }),
    {
      name: "creative-analytics-storage",
      getStorage: () => localStorage,
    },
  ) as any,
);
