import { Tabs, TabsProps } from "antd";
import { FC, useMemo } from "react";
import { TabsType } from "src/domain/ads/ads.state";
import { AdsGroupsTableWidget } from "./ads-groups.table.widget";
import { MetricValueMode } from "./ads-reporting.widget";
import { AdsTableWidget } from "./ads.table.widget";
import { CampaignsTableWidget } from "./campaigns.table.widget";
import { TableContainerWithLoaderWidget } from "./table-container.with-loader.widget";

export const TabsConfig: Record<TabsType, TabsOptionsType> = {
  campaigns: {
    key: "campaigns",
    label: "Campaigns",
  },
  "ad-groups": {
    key: "ad-groups",
    label: "Ad Groups",
  },
  ads: {
    key: "ads",
    label: "Ads",
  },
};

export type TabsOptionsType = {
  key: TabsType;
  label: string;
};

export type TabsValuesType = keyof typeof TabsConfig;

export const AdsTabTableWidget: FC<{
  activeTabKey: TabsValuesType;
  onChange: (activeKey: TabsValuesType) => void;
  queryVariables: any;
  currentMode: MetricValueMode;
}> = ({ activeTabKey, onChange, queryVariables, currentMode }) => {
  const campaignsData = queryVariables[TabsConfig.campaigns.key];

  const adsGroupData = queryVariables[TabsConfig["ad-groups"].key];

  const adsData = queryVariables[TabsConfig.ads.key];

  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: TabsConfig.campaigns.key,
        label: TabsConfig.campaigns.label,
        children: (
          <TableContainerWithLoaderWidget
            allItemsLoaded={campaignsData.allItemsLoaded ?? false}
            isLoading={campaignsData.isLoading}
            isValidating={campaignsData.isValidating}
            loadMore={() => campaignsData.loadMore()}
          >
            <CampaignsTableWidget
              currentMode={currentMode}
              data={
                campaignsData.data?.flatMap((page: any) =>
                  page.map((t: any) => ({
                    key: t.campaign.id,
                    ...t,
                  })),
                ) ?? ([] as any)
              }
              isLoading={campaignsData.isLoading || campaignsData.isLoadingMore}
            />
          </TableContainerWithLoaderWidget>
        ),
      },
      {
        key: TabsConfig["ad-groups"].key,
        label: TabsConfig["ad-groups"].label,
        children: (
          <TableContainerWithLoaderWidget
            allItemsLoaded={adsGroupData.allItemsLoaded ?? false}
            isLoading={adsGroupData.isLoading}
            isValidating={adsGroupData.isValidating}
            loadMore={() => adsGroupData.loadMore()}
          >
            <AdsGroupsTableWidget
              currentMode={currentMode}
              data={
                adsGroupData.data?.flatMap((page: any) =>
                  page.map((t: any) => ({
                    key: t.adGroup.id,
                    ...t,
                  })),
                ) ?? ([] as any)
              }
              isLoading={adsGroupData.isLoading || adsGroupData.isLoadingMore}
            />
          </TableContainerWithLoaderWidget>
        ),
      },
      {
        key: TabsConfig.ads.key,
        label: TabsConfig.ads.label,
        children: (
          <TableContainerWithLoaderWidget
            allItemsLoaded={adsData.allItemsLoaded ?? false}
            isLoading={adsData.isLoading}
            isValidating={adsData.isValidating}
            loadMore={() => adsData.loadMore()}
          >
            <AdsTableWidget
              currentMode={currentMode}
              data={
                adsData.data?.flatMap((page: any) =>
                  page.map((t: any) => ({
                    key: `${t.ad?.id}-${t.ad?.creativeId}`,
                    ...t,
                  })),
                ) ?? ([] as any)
              }
              isLoading={adsData.isLoading || adsData.isLoadingMore}
            />
          </TableContainerWithLoaderWidget>
        ),
      },
    ],
    [campaignsData, adsGroupData, adsData],
  );

  return (
    <Tabs
      className="ads-tabs"
      size="large"
      activeKey={activeTabKey}
      items={items}
      onChange={(activeKey) => onChange(activeKey as TabsValuesType)}
    />
  );
};
