import convertCamelToDash from "src/app/tools/utils/to-dash-case.tool";

// We should add feature flags here to have types
export const defaultValues = {
  enableAnimaticsFeature: false,
  enableCopilotFeature: false,
  enableKeyClaimLlmFeature: false,
  enableGoogleAdsFeature: false,
  enableMetaOauthV18Feature: false,
  enableAdsLibraryFeature: false,
  enableAdCopyTest: false,
  enableCountryFilterAdsLibFeature: false,
  enableCopyGenerationFeature: false,
  enableLibAdsRecommendationsFeature: false,
  enableVideoGraphCognitiveScoresFeature: false,
  enableCognitivesScoresMarkesFeature: false,
  enableKpiMarkWarningFeature: false,
  enableTooltipsWithLinksFeature: false,
  enableTiktokFeature: false,
  enableCognitiveRecosFeature: false,
  enableTvScientificFeature: false,
  enableDebuggingFeature: false,
  hideMarkersOnScoresWithHighValuesFeature: false,
  enablePinterestFeature: false,
  enableSnapchatFeature: false,
  enableCreativeAnalyticsChangesFeature: false,
  enableHidingPretestResultsFeature: false,
  enableNewEssentialsPlanFeature: false,
  maintenanceMode: {
    date: null,
    format: null,
    isMaintenanceOn: false,
  } as {
    date: number | null;
    format: string | null;
    isMaintenanceOn: boolean;
  },
  enableWarningLabelOnPageFeature: [] as {
    exactMatch: boolean;
    displayMessage: string;
    pageUrl: string;
  }[],
  customFeatures: {} as Record<string, any>,
  customFeaturesGapac: {} as Record<string, any>,
  customFeaturesMars: {} as Record<string, any>,
  customFeaturesKelloggs: {} as Record<string, any>,
  enableMultiTagFeature: false,
};

type CustomFeaturesType = {
  percentageMark: number;
};
export type FeatureFlagsType = typeof defaultValues & {
  customFeatures: CustomFeaturesType;
};

export const defaultFlagValues = convertCamelToDash(defaultValues);
