import { client } from "src/app/features/repositories/clients/graphql.client";
import { RecommendationsRepository } from "src/domain/recommendations/recommendations.domain";
import {
  Creative,
  RecommendationFeedback,
  RecommendationFeedbackInput,
  RecommendationsInput,
} from "src/graphql/client";

export const RecommendationResponseFields = {
  action: 1,
  type: 1,
  goal: 1,
  campaignObjective: 1,
  channel: 1,
  title: 1,
  metricImpacts: {
    impact: 1,
    metric: 1,
    impactScore: 1,
    trend: 1,
  },
  ruleId: 1,
  customMessage: 1,
  sourceTag: {
    importance: 1,
    tagClass: 1,
    tagSuperClass: 1,
    tagValue: 1,
  },
  targetTag: {
    importance: 1,
    tagClass: 1,
    tagSuperClass: 1,
    tagValue: 1,
  },
  sourceTags: {
    importance: 1,
    tagClass: 1,
    tagSuperClass: 1,
    tagValue: 1,
  },
  targetTags: {
    importance: 1,
    tagClass: 1,
    tagSuperClass: 1,
    tagValue: 1,
  },
};

export const ReducedRecommendationResponseFields = {
  type: 1,
  goal: 1,
  title: 1,
  customMessage: 1,
  impactedCognitive: 1,
};

export class RecommendationBackendRepository
  implements RecommendationsRepository
{
  async getCreativeRecommendations(
    creativeId: string,
    input: RecommendationsInput,
  ) {
    return client.chain.query.creative({ creativeId }).get({
      id: 1,
      name: 1,
      recommendations: [
        {
          input,
        },
        RecommendationResponseFields,
      ],
    }) as Promise<Creative>;
  }

  async saveFeedbackRecommendation(input: RecommendationFeedbackInput) {
    return client.chain.mutation.saveFeedbackRecommendation({ input }).get({
      creativeId: 1,
      userId: 1,
      action: 1,
    }) as Promise<RecommendationFeedback>;
  }
}
