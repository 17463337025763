import { AdsGroupsBackendRepository } from "./ads-groups.repository";
import { AdsBackendRepository } from "./ads.repository";
import { AssetsBackendRepository } from "./assets.repository";
import { BrandBackendRepository } from "./brand.repository";
import { BusinessBackendRepository } from "./business.repository";
import { CampaignsBackendRepository } from "./campaigns.repository";
import { CopilotChatBackendRepository } from "./copilot-chat.repository";
import { CreativeAnalyticsBackendRepository } from "./creative-analytics.repository";
import { CreativesBackendRepository } from "./creatives.repository";
import { EarlyAccessBackendRepository } from "./early-access.repository";
import { IntegrationBackendRepository } from "./integration.repository";
import { PermissionsBackendRepository } from "./permissions.repository";
import { PretestBackendRepository } from "./pretest.repository";
import { RecommendationBackendRepository } from "./recommendations.repository";
import { SectorsBackendRepository } from "./sectors.repository";
import { TagsBackendRepository } from "./tags.repository";
import { UploaderBackendRepository } from "./uploader.repository";
import { UsersBackendRepository } from "./users.repository";

export const repository = new Map();

repository.set("AdsRepository", AdsBackendRepository);
repository.set("BrandRepository", BrandBackendRepository);
repository.set("SectorsRepository", SectorsBackendRepository);
repository.set("IntegrationsRepository", IntegrationBackendRepository);
repository.set("PermissionsRepository", PermissionsBackendRepository);
repository.set("UsersRepository", UsersBackendRepository);
repository.set("AssetsRepository", AssetsBackendRepository);
repository.set("TagsRepository", TagsBackendRepository);
repository.set("RecommendationsRepository", RecommendationBackendRepository);
repository.set("UploaderRepository", UploaderBackendRepository);
repository.set("CopilotChatRepository", CopilotChatBackendRepository);
repository.set("EarlyAccessRepository", EarlyAccessBackendRepository);
repository.set("CreativesRepository", CreativesBackendRepository);
repository.set("BusinessRepository", BusinessBackendRepository);
repository.set("CampaignsRepository", CampaignsBackendRepository);
repository.set("AdsGroupsRepository", AdsGroupsBackendRepository);
repository.set(
  "CreativeAnalyticsRepository",
  CreativeAnalyticsBackendRepository,
);
repository.set("PretestRepository", PretestBackendRepository);
