import { lazy } from "react";
import { Route } from "src/app/features/navigation/models/route.model";

const CreativesPretestPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/creative-lab/pages/creative-pretest/pretest.page"
    ),
);

const CreativeLibraryPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/creative-lab/pages/creative-library/creative-library.page"
    ),
);

const AnimaticsLibraryPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/creative-lab/pages/animatics-library/animatics-library.page"
    ),
);

export const CreativeDetailPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/creative-analytics/pages/overview/pages/ad-detail/ad-detail.page"
    ),
);

export const CreativeLabRoutes: Route[] = [
  {
    path: "/library",
    element: CreativeLibraryPage,
    title: "Creative Pretest",
  },
  {
    path: "/library/pretest/:resourceId",
    element: CreativesPretestPage,
    title: "Creative Pretest",
  },
  {
    path: "/animatics",
    element: AnimaticsLibraryPage,
    title: "Animatics Pretest",
    controlledByFeatureFlag: "enableAnimaticsFeature",
  },
  {
    path: "/animatics/pretest/:resourceId",
    element: CreativesPretestPage,
    title: "Animatics Pretest",
    controlledByFeatureFlag: "enableAnimaticsFeature",
  },
];
