import * as Sentry from "@sentry/react";
import { env } from "src/app/tools/utils/env.tool";
import { Store } from "src/app/tools/utils/store.tool";
import { token } from "src/app/tools/utils/token.tool";
import { createClient } from "src/graphql/client";
import { handleTokenRefresh } from "./handle-token-refresh";
export const graphqlEndpoint = env("VITE_GRAPHQL_ENDPOINT");
const ERROR_TIMEOUT = 1000 * 60 * 2;

export const client = createClient({
  url: graphqlEndpoint,
  batch: {
    maxBatchSize: 1,
  },
  fetcher: async (operation) => {
    const storeKeepUserLoggedIn = new Store<boolean>("keep-logged-in");

    const hasHandleTokenRefresh = !!storeKeepUserLoggedIn.get();

    const authorization = await handleTokenRefresh(
      token.get(),
      hasHandleTokenRefresh,
    );

    const signal = AbortSignal.timeout(ERROR_TIMEOUT);
    try {
      const response = await fetch(graphqlEndpoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authorization,
        },
        body: JSON.stringify(operation),
        signal,
      });

      if (!response.ok) {
        throw new Error("Error on server response");
      }
      return await response.json();
    } catch (error: any) {
      if (
        ["The user aborted a request.", "signal timed out"].includes(
          error.message,
        )
      ) {
        const newError = new Error("API did not respond or timeout");
        Sentry.captureException(newError, { event_id: "server-api-timeout" });

        // will bubble up to the query
        return { errors: [newError] };
      }
      // any other error is captured by sentry
      Sentry.captureException(error);

      // will bubble up to the query
      return {
        errors: [error],
      };
    }
  },
});
