import "./app/css/tailwind.css";

import {
  asyncWithLDProvider,
  basicLogger,
} from "launchdarkly-react-client-sdk";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import "src/app/features/translations/i18n/i18n";
import App from "./app/app.page";
import { defaultFlagValues } from "./app/features/feature-flags/flags.model";
import { SentryErrorsInit } from "./app/features/requests/features/errors/lib/sentry.errors";
import { env } from "./app/tools/utils/env.tool";
import { isEnv } from "./app/tools/utils/is-env.tool";
import { Providers } from "./providers";
const rootElement = document.getElementById("root") as HTMLElement;

SentryErrorsInit();

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: env("VITE_LAUNCHDARKLY_SDK_CLIENT"),
    flags: defaultFlagValues,
    reactOptions: {
      useCamelCaseFlagKeys: true,
    },
    options: {
      logger: basicLogger({ level: isEnv("BETA") ? "none" : "info" }),
    },
  });

  const root = createRoot(rootElement);
  root.render(
    <StrictMode>
      <LDProvider>
        <Providers>
          <App />
        </Providers>
      </LDProvider>
    </StrictMode>,
  );
};

renderApp();
