import { FCC } from "@types";
import { shortCurrencyFormatterTool } from "src/app/tools/formatters/currency.formatter.tool";
import { shortDecimalsFormatterTool } from "src/app/tools/formatters/decimals-short.formatter.tool";
import {
  AdGroupRankingElement,
  AdRankingElement,
  CampaignRankingElement,
  MetricVariance,
} from "src/graphql/client";
import { MetricValueMode } from "./ads-reporting.widget";

export const getFixedValue = (
  field: MetricVariance,
  currentMode: MetricValueMode,
  options?: {
    decimals?: number;
    valueTypePosition?: "before" | "after";
    valueType?: "%" | "$" | undefined;
    defaultValue?: string;
  },
) => {
  if (!field?.[currentMode]) {
    return options?.defaultValue ?? "-";
  }

  const value = Number(field?.[currentMode]).toFixed(options?.decimals ?? 2);

  if (options?.valueTypePosition === "before") {
    return `${options?.valueType ? options?.valueType : ""}${value}`;
  }

  return `${value}${options?.valueType ? options?.valueType : ""}`;
};

export const TitleWrapper: FCC = ({ children }) => {
  return <div className="body-12-medium text-slate-500">{children}</div>;
};

type ColumnFieldTypes =
  | AdRankingElement["metrics"]
  | CampaignRankingElement["metrics"]
  | AdGroupRankingElement["metrics"];

export const commonTableFields = ({
  canIAccess,
  currentMode,
}: {
  canIAccess: Record<string, any>;
  currentMode: MetricValueMode;
}) =>
  [
    canIAccess.visibleAdMetrics("spend") && {
      title: <TitleWrapper>Spend</TitleWrapper>,
      width: 100,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return element.spendTotal.value
          ? shortCurrencyFormatterTool(element.spendTotal.value)
          : "-";
      },
    },
    canIAccess.visibleAdMetrics("impressions") && {
      title: <TitleWrapper>Impressions</TitleWrapper>,
      width: 130,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div
            style={{ minWidth: "85px" }}
            className="flex justify-end text-xs"
          >
            {element?.impressionsTotal?.[currentMode]
              ? shortDecimalsFormatterTool(
                  element.impressionsTotal?.[currentMode],
                )
              : "-"}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("frequency") && {
      title: <TitleWrapper>Frequency</TitleWrapper>,
      width: 120,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div style={{ minWidth: "85px" }} className="text-xs">
            {getFixedValue(element.frequency, currentMode)}
            dd
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("brandLift") && {
      title: <TitleWrapper>Brand Lift</TitleWrapper>,
      width: 120,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div style={{ minWidth: "85px" }} className="text-xs">
            {getFixedValue(element?.brandLift, currentMode, { valueType: "%" })}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("costPerMille") && {
      title: <TitleWrapper>CPM</TitleWrapper>,
      width: 90,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div className="text-xs" style={{ minWidth: "85px" }}>
            {getFixedValue(element.costPerMille, currentMode, {
              valueTypePosition: "before",
              valueType: "$",
            })}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("engagementRate") && {
      title: <TitleWrapper>ER</TitleWrapper>,
      width: 90,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div className="text-xs" style={{ minWidth: "85px" }}>
            {getFixedValue(element?.engagementRate, currentMode)}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("costPerEngagement") && {
      title: <TitleWrapper>CPE</TitleWrapper>,
      width: 90,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div className="text-xs" style={{ minWidth: "85px" }}>
            {getFixedValue(element.costPerEngagement, currentMode, {
              valueTypePosition: "before",
              valueType: "$",
            })}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("clickThroughRate") && {
      title: <TitleWrapper>CTR</TitleWrapper>,
      width: 90,
      dataIndex: "metrics",

      render: (element: ColumnFieldTypes) => {
        return (
          <div className="text-xs" style={{ minWidth: "85px" }}>
            {getFixedValue(element.clickThroughRate, currentMode)}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("costPerClick") && {
      title: <TitleWrapper>CPC</TitleWrapper>,
      width: 90,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div className="text-xs" style={{ minWidth: "85px" }}>
            {getFixedValue(element.costPerClick, currentMode, {
              valueTypePosition: "before",
              valueType: "$",
            })}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("videoThroughRate") && {
      title: <TitleWrapper>VTR</TitleWrapper>,
      width: 90,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div className="text-xs" style={{ minWidth: "85px" }}>
            {getFixedValue(element.videoThroughRate, currentMode)}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("costPerCompletedView") && {
      title: <TitleWrapper>CPCV</TitleWrapper>,
      width: 90,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div className="text-xs" style={{ minWidth: "85px" }}>
            {getFixedValue(element.costPerCompletedView, currentMode, {
              valueTypePosition: "before",
              valueType: "$",
            })}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("conversionRate") && {
      title: <TitleWrapper>CR</TitleWrapper>,
      width: 90,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div className="text-xs" style={{ minWidth: "85px" }}>
            {getFixedValue(element.conversionRate, currentMode, {
              valueTypePosition: "before",
              valueType: "$",
            })}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("costPerAppInstall") && {
      title: <TitleWrapper>CPAI</TitleWrapper>,
      width: 90,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div className="text-xs" style={{ minWidth: "85px" }}>
            {getFixedValue(element.costPerAppInstall, currentMode, {
              valueTypePosition: "before",
              valueType: "$",
            })}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("costPerAcquisition") && {
      title: <TitleWrapper>CPA</TitleWrapper>,
      width: 90,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div className="text-xs" style={{ minWidth: "85px" }}>
            {getFixedValue(element.costPerAcquisition, currentMode, {
              valueTypePosition: "before",
              valueType: "$",
            })}
          </div>
        );
      },
    },
    canIAccess.visibleAdMetrics("costPerLead") && {
      title: <TitleWrapper>CPL</TitleWrapper>,
      width: 90,
      dataIndex: "metrics",
      render: (element: ColumnFieldTypes) => {
        return (
          <div className="text-xs" style={{ minWidth: "85px" }}>
            {getFixedValue(element.costPerLead, currentMode, {
              valueTypePosition: "before",
              valueType: "$",
            })}
          </div>
        );
      },
    },
  ].filter(Boolean);
