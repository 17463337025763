import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { userIdsExcludedHeap } from "src/domain/users/heap-excluded";
import { UserWithPermissions } from "src/graphql/client";
import { useHeapTrackingState } from "./heap.state";

const isUserTrackEnabled = (user: UserWithPermissions) => {
  if (user?.id) {
    return !userIdsExcludedHeap.find((id) => id === user.id);
  }
  return false;
};
export const HeapTracking: FC<{ user: UserWithPermissions }> = ({ user }) => {
  const isTrackingEnabled = isUserTrackEnabled(user);
  const { setIsLoaded, isLoaded } = useHeapTrackingState();

  const [scripLoaded, setScriptLoaded] = useState(false);
  useEffect(() => {
    if (isTrackingEnabled && !isLoaded && scripLoaded) {
      setTimeout(() => {
        setIsLoaded(true);
      }, 3000);
    }
  }, [isTrackingEnabled, isLoaded, scripLoaded]);

  if (!isTrackingEnabled) return null;

  return (
    <Helmet
      onChangeClientState={(_, addedTags) => {
        const { scriptTags } = addedTags;
        if (scriptTags) {
          setScriptLoaded(true);
        }
      }}
    >
      {/* Add your Heap tracking code here */}
      <script type="text/javascript">
        {`
          (window.heap = window.heap || []),
          (heap.load = function (e, t) {
            (window.heap.appid = e), (window.heap.config = t = t || {});
            var r = document.createElement("script");
            (r.type = "text/javascript"),
              (r.async = !0),
              (r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js");
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(r, a);
            for (
              var n = function (e) {
                  return function () {
                    if(!heap.push) return;
                    heap.push(
                      [e].concat(Array.prototype.slice.call(arguments, 0)),
                    );
                  };
                },
                p = [
                  "addEventProperties",
                  "addUserProperties",
                  "clearEventProperties",
                  "identify",
                  "resetIdentity",
                  "removeEventProperty",
                  "setEventProperties",
                  "track",
                  "unsetEventProperty",
                ],
                o = 0;
              o < p.length;
              o++
            )
              heap[p[o]] = n(p[o]);
          });
          heap.load("1695372337");
        `}
      </script>
    </Helmet>
  );
};
