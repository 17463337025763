import { FC } from "react";

export const CTvSvg: FC<{
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
}> = ({ width = 14, height = 14, style }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      width={width}
      height={height}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9937 2H1.9999C1.2624 2 0.665527 2.59687 0.665527 3.33437V5.33125H1.9999V3.33437H13.9937V12.6656H9.33115V14H13.9999C14.7374 14 15.3343 13.4031 15.3343 12.6656V3.33437C15.3343 2.59687 14.7312 2 13.9937 2ZM0.665527 11.9875V13.9844H2.6624C2.6624 12.8813 1.76865 11.9875 0.665527 11.9875ZM0.665527 9.33125V10.6562C2.50615 10.6562 3.99678 12.1594 3.99678 14H5.33115C5.33428 11.425 3.24053 9.33438 0.665527 9.33125ZM6.66865 14H8.00303C7.9874 9.95312 4.7124 6.67812 0.665527 6.66875V7.99375C3.97803 7.9875 6.66553 10.6875 6.66865 14Z"
        fill="currentColor"
      />
    </svg>
  );
};
