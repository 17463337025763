import { FC } from "react";
import { AiFillCheckSquare, AiFillCloseSquare } from "react-icons/ai";
import { useNavigationFeature } from "src/app/features/navigation/navigation.feature";
import { ButtonUI } from "src/app/ui/buttons/button.ui";
import CardPageUI from "src/app/ui/cards/card-page.ui";

const SkipIntegrationPage: FC = () => {
  const { goto } = useNavigationFeature();

  return (
    <CardPageUI className="mx-auto max-w-4xl">
      <div className="m-10">
        <div className="flex">
          <h1 className="mb-8">
            This will limit the features available for you
          </h1>
          <div className="flex flex-col gap-6">
            <div className="flex items-start gap-2">
              <div className="mt-1 h-8 w-6">
                <AiFillCloseSquare color="#ff5550" size={22} />
              </div>
              <div>
                <h3>Brand AI: Pretest</h3>
                <small>
                  AI models that learn to predict the KPIs of your creatives.
                </small>
              </div>
            </div>
            <div className="flex items-start gap-2">
              <div className="mt-1 h-8 w-6">
                <AiFillCloseSquare color="#ff5550" size={22} />
              </div>
              <div>
                <h3>Brand AI: Insights</h3>
                <small>
                  AI models that tell you which creative elements help or hurt
                  your brand.
                </small>
              </div>
            </div>
            <div className="flex items-start gap-2">
              <div className="mt-1 h-8 w-6">
                <AiFillCloseSquare color="#ff5550" size={22} />
              </div>
              <div>
                <h3>Custom recommendations for your brand</h3>
                <small>
                  Recommendations derived from your own history or ads and the
                  creative tactics that make them work.
                </small>
              </div>
            </div>
            <div className="flex items-start gap-2">
              <div className="mt-1 h-8 w-6">
                <AiFillCheckSquare color="#6de17e" size={22} />
              </div>
              <div>
                <h3>Cognitive scores & attention heatmaps</h3>
                <small>
                  General AI models that predict the impact of your ad on
                  Memory, Attention, Cognitive Ease, Distinctiveness and others.
                </small>
              </div>
            </div>
            <div className="flex items-start gap-2">
              <div className="mt-1 h-8 w-6">
                <AiFillCheckSquare color="#6de17e" size={22} />
              </div>
              <div>
                <h3>General recommendations</h3>
                <small>
                  The creative basics on each media platform, good practices for
                  neuro accessibility, and others.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto mt-12 flex gap-2">
          <ButtonUI onClick={() => goto("/")}>Go back to integrations</ButtonUI>
          <div className="flex-1"></div>
          <ButtonUI
            type="light"
            onClick={() => goto("/welcome/skip-integration/finish")}
          >
            Got it. Take me to the limited app
          </ButtonUI>
        </div>
      </div>
    </CardPageUI>
  );
};
export default SkipIntegrationPage;
