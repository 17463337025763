function convertCamelToDash(obj: Record<string, any>) {
  const newObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = key.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
      newObj[newKey] = obj[key];
    }
  }
  return newObj;
}

export default convertCamelToDash;
