import { AdsGroupsRepository } from "src/domain/ads-groups/ads-groups.domain";
import { AdGroupRankingElement, AdRankingInput } from "src/graphql/client";
import { client } from "../clients/graphql.client";

export class AdsGroupsBackendRepository implements AdsGroupsRepository {
  async getAdGroupRanking(args: AdRankingInput) {
    return client.chain.query.getAdGroupRanking({ filter: args }).get({
      adGroup: {
        id: 1,
        name: 1,
      },
      channels: 1,
      campaign: { id: 1, name: 1, objective: 1 },
      metrics: {
        conversionRate: {
          value: 1,
          variance: 1,
        },
        clickThroughRate: {
          value: 1,
          variance: 1,
        },
        costPerMille: {
          value: 1,
          variance: 1,
        },
        frequency: {
          value: 1,
          variance: 1,
        },
        engagementRate: {
          value: 1,
          variance: 1,
        },
        impressionsTotal: {
          value: 1,
          total: 1,
        },
        costPerClick: {
          metric: 1,
          variance: 1,
          value: 1,
          average: 1,
        },
        spendTotal: {
          value: 1,
        },
        brandLift: {
          value: 1,
          variance: 1,
        },
        costPerEngagement: {
          value: 1,
          variance: 1,
        },
        costPerCompletedView: {
          value: 1,
          variance: 1,
        },
        videoThroughRate: {
          value: 1,
          variance: 1,
        },
        costPerAcquisition: {
          value: 1,
          variance: 1,
        },
        costPerLead: {
          value: 1,
          variance: 1,
        },
      },
    }) as Promise<AdGroupRankingElement[]>;
  }
}
