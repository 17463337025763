import { Operation } from "../../models/operation.model";
import { useLoadingsState } from "./loadings.state";

export const useLoadings = () => {
  const {
    addLoading: _addLoading,
    removeLoading,
    isLoading: _isLoading,
    isAnyLoading,
  } = useLoadingsState();

  const addLoading = <T>(operation: Operation<T>, isLoading: boolean) => {
    if (isLoading) {
      _addLoading(operation);
    } else {
      removeLoading(operation.id ?? "undefined");
    }
  };

  const isLoading = (operationId: string) => {
    if (!operationId) {
      return isAnyLoading();
    }
    return _isLoading(operationId);
  };

  return {
    addLoading,
    isAnyLoading,
    isLoading,
  };
};
