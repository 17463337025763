import { BrandRepository } from "src/domain/brands/brands.domain";
import {
  Brand,
  BrandListElement,
  CreateBrandInput,
  UpdateBrandInput,
} from "src/graphql/client";
import { client } from "../clients/graphql.client";

export class BrandBackendRepository implements BrandRepository {
  updateBrand(input: UpdateBrandInput, brandId: string): Promise<Brand> {
    return client.chain.mutation.updateBrand({ input, brandId }).get({
      id: 1,
    }) as Promise<Brand>;
  }

  getBrand(brandId: string): Promise<Brand> {
    return client.chain.query.getBrand({ brandId }).get({
      id: 1,
      country: 1,
      countries: 1,
      name: 1,
      logoUrl: 1,
      extraAttributes: {
        communicationIdeas: 1,
        mustWinAttributes: 1,
        names: 1,
        overlayTextTaglines: 1,
        speechTaglines: 1,
        visualElements: 1,
      },
      sector: 1,
    }) as Promise<Brand>;
  }

  getBrandCountries(
    brandId: string,
  ): Promise<Pick<Brand, "id" | "countries" | "name">> {
    return client.chain.query.getBrand({ brandId }).get({
      id: 1,
      countries: 1,
      name: 1,
    });
  }

  getBusinessAccountBrands(): Promise<BrandListElement[]> {
    return client.chain.query.getBusinessAccountBrands.get({
      country: 1,
      id: 1,
      name: 1,
      status: 1,
      users: 1,
      logoUrl: 1,
    }) as Promise<BrandListElement[]>;
  }

  async getBrands(): Promise<Brand[]> {
    return new Promise((resolve) => {
      client.chain.query.getLoggedInUser
        .get({
          id: 1,
          businessAccount: {
            brands: {
              id: 1,
              name: 1,
              logoUrl: 1,
              sector: 1,
              adAccounts: 1,
              socialAccounts: 1,
            },
          },
        })
        .then((res) => {
          if (!res?.businessAccount?.brands) return resolve([]);
          return resolve(res?.businessAccount?.brands as Brand[]);
        });
    });
  }

  async createBrand(input: CreateBrandInput) {
    return client.chain.mutation.createBrand({ input }).get({
      id: 1,
      name: 1,
      logoUrl: 1,
      sector: 1,
    }) as Promise<Brand>;
  }
}
