import { lazy } from "react";
import { Route } from "src/app/features/navigation/models/route.model";

const BrandDetail = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/brand-settings/pages/brand-detail/brand-detail.page"
    ),
);
const BrandUsers = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/brand-settings/pages/brand-users/brand-users.page"
    ),
);

export const BrandSettingsRoutes: Route[] = [
  {
    path: "/brand-detail",
    element: BrandDetail,
    isExcludeAdmin: true,
    requiredPermissions: [2],
    title: "Brand Detail",
  },
  {
    path: "/brand-users",
    element: BrandUsers,
    requiredPermissions: [2],
    isExcludeAdmin: true,
    title: "Brand Users",
  },
];
