import { Switch } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import { RouteHiderWidget } from "src/app/widgets/hider/route-hider.widget";
import useDebuggingDomain from "src/domain/debugging/debuging.domain";
import { LogoUI } from "../../../../../domain/brand/logo.ui";
import { ROOT_DEFAULT_ROUTE } from "../../creative-intelligence-suite.routes";
import BrandIdDebugger from "./widgets/brand-id-debugger";
import { BrandSelectorWidget } from "./widgets/brand-selector.widget";
import { CredentialsPanelWidget } from "./widgets/credentials-panel.widget";

export const HeaderWidget: FC = () => {
  const { debugMode, toggleDebuggingMode, enableDebugging } =
    useDebuggingDomain();

  return (
    <div
      className="animSlideTopIn flex items-center gap-4 border-b border-gray-200 bg-white"
      style={{ padding: "1.5rem 2rem" }}
    >
      <Link to={ROOT_DEFAULT_ROUTE}>
        <LogoUI style={{ height: "34px" }} />
      </Link>

      {enableDebugging && (
        <div className="flex">
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            checked={debugMode}
            size="small"
            onChange={() => {
              toggleDebuggingMode();
            }}
          />
        </div>
      )}
      <div className="flex-1" />
      <RouteHiderWidget
        routes={["/account-and-brands", "/integrations-and-assets", "/people"]}
        className="flex items-center gap-4"
      >
        <BrandIdDebugger />
        <BrandSelectorWidget />
      </RouteHiderWidget>
      <CredentialsPanelWidget />
    </div>
  );
};
