import { formatNumber } from "./decimals-short.formatter.tool";

export const currencyFormatterTool = (number: number) => {
  const a = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return a.format(number);
};

export const shortCurrencyFormatterTool = (number: number) => {
  if (number >= 1e6) {
    return `${currencyFormatterTool(formatNumber(number / 1e6))}M`;
  }
  if (number >= 1e3) {
    return `${currencyFormatterTool(formatNumber(number / 1e3))}K`;
  }
  return currencyFormatterTool(number);
};
