import {
  CopilotChatMessage,
  CopilotChatMessageAuthor,
  CopilotChatMessageType,
} from "src/domain/copilot-chat/message-copilot-chat.model";
import { create } from "zustand";

export type CopilotChatState = {
  history: CopilotChatMessage[];
  setHistory: (messages: CopilotChatMessage[]) => void;
  addMessage: (message: CopilotChatMessage) => void;
  updateMessage: (
    messageId: number,
    partialMessage: Partial<CopilotChatMessage>,
  ) => void;
  reset: () => void;
};

export const useCopilotChatState = create<CopilotChatState>((set) => ({
  history: [
    {
      id: 1,
      content: "Hello, I'm MemChat. How can I help you?",
      send_timestamp: new Date().getTime(),
      type: CopilotChatMessageType.Text,
      author: CopilotChatMessageAuthor.Bot,
    },
  ],
  setHistory: (messages: CopilotChatMessage[]) => set({ history: messages }),
  addMessage: (message: CopilotChatMessage) =>
    set((state) => ({ history: [...state.history, message] })),
  updateMessage: (
    messageId: number,
    partialMessage: Partial<CopilotChatMessage>,
  ) =>
    set((state) => ({
      history: state.history.map((message) =>
        message.id === messageId ? { ...message, ...partialMessage } : message,
      ),
    })),
  reset: () => set({ history: [] }),
}));
