import { FCC } from "@types";
import classnames from "classnames";
import { useNavigationFeature } from "src/app/features/navigation/navigation.feature";

interface RouteHiderProps {
  routes: string[];
  className?: string;
}

export const RouteHiderWidget: FCC<RouteHiderProps> = ({
  children,
  routes,
  className,
}) => {
  const { getPathName } = useNavigationFeature();

  const basePath = "/" + getPathName()?.split("/")[1];

  if (routes.includes(basePath)) return <></>;

  const classNames = classnames("animFadeIn", {
    [className ?? ""]: !!className,
  });
  return <div className={classNames}>{children}</div>;
};
