import { ChannelListUI } from "@creative-intelligence-suite-pages/creative-analytics/pages/overview/components/creative-highlight.widget";
import { TableUI } from "@ui/tables/table.ui";
import { ColumnsType } from "antd/es/table";
import { useDeferredValue, useMemo } from "react";
import { useNavigationFeature } from "src/app/features/navigation/navigation.feature";
import { useSessionFeature } from "src/app/features/session/session.feature";
import { titleCaseAllFormatterTool } from "src/app/tools/formatters/title-case-all.formatter.tool";
import { Ad, AdRankingElement, Channel } from "src/graphql/client";
import { MetricValueMode } from "./ads-reporting.widget";
import { AdsTableAdNameWidget } from "./ads.table.ad-name.widget";
import { commonTableFields, TitleWrapper } from "./common-table-fields.widget";
export const AdsTableWidget = ({
  isLoading,
  data,
  currentMode,
}: {
  data: AdRankingElement[] | undefined;
  isLoading: boolean;
  currentMode: MetricValueMode;
}) => {
  const dataDeferred = useDeferredValue(data);
  const { goto } = useNavigationFeature();

  const { canIAccess } = useSessionFeature();

  const columns: ColumnsType<AdRankingElement> = [
    {
      title: <TitleWrapper>Ad name</TitleWrapper>,
      width: 500,
      dataIndex: "ad",
      className: "px-6 py-4 max-w-175 flex-1",
      render: (ad: Ad, record: AdRankingElement) => (
        <AdsTableAdNameWidget ad={ad} record={record} />
      ),
    },
    {
      title: <TitleWrapper>Channel</TitleWrapper>,
      dataIndex: "channels",
      width: 105,
      className: "px-6 py-4 max-w-20 w-20",
      render: (channels: Channel[]) => {
        return (
          <div className="flex items-center justify-center">
            <ChannelListUI channels={channels} />
          </div>
        );
      },
    },
    {
      title: <TitleWrapper>Objective</TitleWrapper>,
      width: 135,
      dataIndex: "ad",
      className: "px-6 py-4",
      render: (element: Ad) => {
        return (
          <div className="text-xs break-word">
            {titleCaseAllFormatterTool(element.campaignObjective)}
          </div>
        );
      },
    },
    {
      title: <TitleWrapper>Countries</TitleWrapper>,
      width: 120,
      dataIndex: "ad",
      className: "px-6 py-4",
      render: (element: Ad) => {
        return <div className="text-xs">{element.targetingCountries}</div>;
      },
    },
    ...commonTableFields({ canIAccess, currentMode }),
  ];

  const withCalculation = useMemo(
    () =>
      columns.reduce((acc, current) => {
        return acc + Number(current.width);
      }, 0),
    [columns],
  );

  return (
    <div className="w-full">
      <TableUI
        className="andt-table-header"
        isLoading={isLoading}
        columns={columns}
        scrollDimensions={{ y: 500, x: withCalculation }}
        data={dataDeferred ?? []}
        maxItemPerPage={false}
        onRowClick={(a) => {
          goto(`/ads-library/${a.ad.id}`);
        }}
      />
    </div>
  );
};
