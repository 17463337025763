import { Typography } from "antd";
import { FC } from "react";
import { useOnboardingFeature } from "../../onboarding.feature";
import AssignAssetsForm from "./assign-assets.form.page";
import { CardOutlineUI } from "src/app/ui/cards/card-outline.ui";
import WarningOutlined from "@ant-design/icons/WarningOutlined";

const AssignAssetsPage: FC = () => {
  const { gotoNextStep, cancelOnBoarding } = useOnboardingFeature();
  return (
    <div
      className="w-full"
      style={{
        minHeight: "280px",
        maxWidth: "660px",
        margin: "0 auto",
      }}
    >
      <Typography.Title level={4}>
        Assign the accounts to your brand
      </Typography.Title>
      <p>
        Link your Ad Account to the Social Accounts (Facebook Page of Instagram
        Account) for your brand.
      </p>
      <CardOutlineUI className="my-10 flex items-center gap-3 p-4">
        <WarningOutlined
          style={{
            fontSize: "1.6rem",
            color: "rgb(245, 158, 11)",
            background: "rgb(255, 251, 235)",
            paddingBottom: "8px",
            width: "50px",
            height: "50px",
            borderRadius: "100%",
            aspectRatio: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        <div
          style={{
            fontSize: "0.9rem",
          }}
        >
          <p>You can add accounts for other brands after the initial setup.</p>
        </div>
      </CardOutlineUI>
      <div className="h-8"></div>
      <AssignAssetsForm onSubmit={gotoNextStep} onCancel={cancelOnBoarding} />
      <div className="h-8"></div>
    </div>
  );
};
export default AssignAssetsPage;
