import { useCallback, useMemo } from "react";
import { useFlags } from "src/app/features/feature-flags/feature-flags.feature";
import { useSessionFeature } from "src/app/features/session/session.feature";
import { sortByArray } from "src/app/tools/utils/sort-by-array";
import { Channel, ChannelOptions } from "src/graphql/client";
import { Optional } from "utility-types";
import {
  PlatformsIntegrations,
  useIntegrationDomain,
} from "../integrations/integration.domain";
import { enumLockPages, LockStatus } from "./lock-status.model";
import { enumPacks } from "./packs.model";
import { enumSectionType, SectionPageType } from "./section.model";

// GENERAL
//   pack: Scalars["String"];
//   allowedChannels: Channel[];
//   purchasableChannels: Channel[];
//   visibleRecommendationSources: Scalars["String"][];

// CA
//   allowedCreativeAnalyticsChannels: Channel[];
//   purchasableCreativeAnalyticsChannels: Channel[];
//   visibleCreativeAnalyticsCampaignObjectives: CampaignObjective[];

// PRETEST
//   allowedCreativeLabChannels: Channel[];
//   purchasableCreativeLabChannels: Channel[];
//   visibleCreativeLabCampaignObjectives: CampaignObjective[];

//   visibleFeatures: Feature[];
//   visibleCognitiveMetrics: CognitiveMetric[];
//   visibleTagMetrics: Metric[];
//   visibleAdMetrics: Metric[];

export const useSalesDomain = () => {
  const { user } = useSessionFeature();
  const {
    enableTiktokFeature,
    enableTvScientificFeature,
    enablePinterestFeature,
    enableSnapchatFeature,
    enableNewEssentialsPlanFeature,
  } = useFlags();
  const { isAnyIntegrated, isChannelIntegrated } = useIntegrationDomain();

  const channelsDisabledEnabledByFF: Channel[] = useMemo(() => {
    const channelsDisabledEnabledByFF: Channel[] = [];

    const arrayConfig: Optional<Record<Channel, boolean>>[] = [
      { tiktok: enableTiktokFeature },
      { ctv: enableTvScientificFeature },
      { pinterest: enablePinterestFeature },
      { snapchat: enableSnapchatFeature },
    ];

    arrayConfig.forEach((feature) => {
      if (!feature[Object.keys(feature)[0] as Channel]) {
        channelsDisabledEnabledByFF.push(Object.keys(feature)[0] as Channel);
      }
    });
    return channelsDisabledEnabledByFF;
  }, [enableTiktokFeature, enableTvScientificFeature]);

  /**
   * Returns all allowedChannels from a business from config
   *
   * @returns Channel[]
   */
  const getAvailableChannels = useCallback(
    (section: SectionPageType | SectionPageType[]): Channel[] => {
      let channels;

      if (
        section === enumSectionType.ca ||
        (Array.isArray(section) && section.includes(enumSectionType.ca))
      ) {
        channels = user?.config?.allowedCreativeAnalyticsChannels;
      } else if (
        section === enumSectionType.preTest ||
        (Array.isArray(section) && section.includes(enumSectionType.preTest))
      ) {
        channels = [
          ...(user?.config?.allowedCreativeLabChannels ?? []),
        ] as Channel[];
      } else if (section === enumSectionType.all) {
        channels = [
          ...new Set([
            ...(user?.config?.allowedCreativeAnalyticsChannels ?? []),
            ...(user?.config?.allowedCreativeLabChannels ?? []),
          ]),
        ];
      }

      if (channelsDisabledEnabledByFF.length == 0) {
        return channels ?? [];
      }

      return (
        channels?.filter(
          (channel: Channel) => !channelsDisabledEnabledByFF.includes(channel),
        ) ?? []
      );
    },
    [
      channelsDisabledEnabledByFF,
      user?.config?.allowedCreativeAnalyticsChannels,
      user?.config?.allowedCreativeLabChannels,
    ],
  );
  /**
   * Returns all purchasableChannels from a business
   * based on available channels to be purchased from config
   *
   * @returns Channel[]
   */
  const getPurchasableChannels = useCallback(
    (section: SectionPageType | SectionPageType[]) => {
      let channels;

      if (
        section === enumSectionType.ca ||
        (Array.isArray(section) && section.includes(enumSectionType.ca))
      ) {
        channels = user?.config?.purchasableCreativeAnalyticsChannels;
      } else if (
        section === enumSectionType.preTest ||
        (Array.isArray(section) && section.includes(enumSectionType.preTest))
      ) {
        channels = [
          ...(user?.config?.purchasableCreativeLabChannels ?? []),
        ] as Channel[];
      } else if (section === enumSectionType.all) {
        channels = [
          ...new Set([
            ...(user?.config?.purchasableCreativeLabChannels ?? []),
            ...(user?.config?.purchasableCreativeAnalyticsChannels ?? []),
          ]),
        ];
      }

      if (channelsDisabledEnabledByFF.length == 0) {
        return sortByArray<Channel>(channels ?? [], [
          "facebook",
          "instagram",
          "youtube",
          "tiktok",
          "snapchat",
          "pinterest",
        ]);
      }

      return sortByArray<Channel>(
        channels?.filter(
          (channel: Channel) => !channelsDisabledEnabledByFF.includes(channel),
        ) ?? [],
        ["facebook", "instagram", "youtube", "tiktok"],
      );
    },
    [
      channelsDisabledEnabledByFF,
      user?.config?.purchasableCreativeAnalyticsChannels,
      user?.config?.purchasableCreativeLabChannels,
    ],
  );

  const getCurrentPack = (): enumPacks => {
    return user?.config?.pack as enumPacks;
  };

  const isPack = (pack: enumPacks) => {
    return getCurrentPack() === pack;
  };

  const getTalkToSalesUrl = () => {
    return "https://calendly.com/juan-rodriguez-momesso/memorable-upgrades/";
  };

  /**
   * Checks if Channel is available from config: needs to be integrated and available
   *
   * @param {Channel} channel
   * @param {SectionPageType | SectionPageType[]} section
   * @returns {LockStatus} LockStatus
   */
  const getChannelLockStatus = async (
    channel: Channel,
    section: SectionPageType | SectionPageType[],
  ): Promise<LockStatus> => {
    if (enableNewEssentialsPlanFeature) {
      // for this plan we don't need to check if the channel is integrated
      if (getCurrentPack() === enumPacks.base) {
        if (!getAvailableChannels(section).includes(channel)) {
          return {
            isLocked: true,
            pageId: enumLockPages.YourPlanDoesNotIncludeThisChannel,
          };
        }
        return {
          isLocked: false,
        };
      }
    }

    // if plan is higher then we should check if it's available and integrated
    if (!getAvailableChannels(section).includes(channel)) {
      return {
        isLocked: true,
        pageId: enumLockPages.YourPlanDoesNotIncludeThisChannel,
      };
    }

    if (
      getAvailableChannels(section).includes(channel) &&
      !(await isChannelIntegrated(channel))
    ) {
      return {
        isLocked: true,
        pageId: enumLockPages.IntegrateToGetInsightsInBrand,
      };
    }

    return {
      isLocked: false,
    };
  };

  const getCreativeAnalyticsBanner = (): LockStatus => {
    if (getCurrentPack() === enumPacks.base) {
      return {
        isLocked: true,
        pageId: enumLockPages.UpgradeBannerCreativeLabs,
      };
    }
    return {
      isLocked: false,
    };
  };
  const getCreativeAnalyticsLockStatus = (
    integrations: PlatformsIntegrations[] | undefined,
  ): LockStatus => {
    if (getCurrentPack() === enumPacks.base) {
      return {
        isLocked: true,
        pageId: enableNewEssentialsPlanFeature
          ? enumLockPages.CreativeLabElementLocked
          : enumLockPages.UpgradeAnyForChannel,
      };
    }

    if (integrations && !isAnyIntegrated(integrations)) {
      return {
        isLocked: true,
        pageId: enumLockPages.IntegrateToGetInsightsInBrand,
      };
    }

    // no channels added it won't be possible to set the channel in the filter
    // so needs to be locked
    if (!getAvailableChannels(enumSectionType.ca).length) {
      return {
        isLocked: true,
        pageId: enumLockPages.UpgradeAnyForChannel,
      };
    }

    return {
      isLocked: false,
    };
  };

  const getPretestLockStatus = (): LockStatus => {
    if (getCurrentPack() === enumPacks.base) {
      return {
        isLocked: true,
        pageId: enumLockPages.UpgradeAnyForChannel,
      };
    }
    return {
      isLocked: false,
    };
  };

  const customKPIStatus = (): LockStatus => {
    if (getCurrentPack() === enumPacks.base) {
      return {
        isLocked: true,
        pageId: enumLockPages.CustomKPIsFeature,
      };
    }
    return {
      isLocked: false,
    };
  };
  const preTestRecommendations = (): LockStatus => {
    if (getCurrentPack() === enumPacks.base) {
      return {
        isLocked: true,
        pageId: enumLockPages.Recommendation,
      };
    }
    return {
      isLocked: false,
    };
  };

  const getLockStatus = {
    channel: getChannelLockStatus,
    // @deprecated: replaced by pretestPage
    pretest: getPretestLockStatus,
    pretestPage: () => ({
      customKPIStatus,
      preTestRecommendations,
    }),
    creativeAnalytics: () => ({
      getCreativeAnalyticsLockStatus,
      getCreativeAnalyticsBanner,
    }),
  };

  const isPretestChannelAvailable = (
    channel: Channel,
    channelsOptions: ChannelOptions[],
  ) => {
    return (
      // channel is in the channelsOptions array
      _isPretestChannelCreativeAvailable(channel, channelsOptions) &&
      // channel was purchased by the business
      isPretestChannelBusinessAvailable(channel)
    );
  };

  const isPretestChannelBusinessAvailable = (channel: Channel) => {
    return getAvailableChannels(enumSectionType.preTest).some(
      (availableChannel) => availableChannel === channel,
    );
  };

  const _isPretestChannelCreativeAvailable = (
    channel: Channel,
    channelsOptions: ChannelOptions[],
  ) => {
    return channelsOptions.some(
      (placementChannel) => placementChannel.channel === channel,
    );
  };

  /**
   * Returns all channels enabled for an specific creative
   * Receives an array of channelsOptions that is returned
   * by getChannelAvailableOptions specific to the creative/s
   * @param {ChannelOptions[]} channelsOptions
   * @returns
   */
  const getPretestAvailableChannels = (channelsOptions: ChannelOptions[]) => {
    // get all possible channels that can be purchased
    return getPurchasableChannels(enumSectionType.preTest).filter((channel) => {
      return isPretestChannelAvailable(channel, channelsOptions);
    });
  };

  return {
    getAvailableChannels,
    getPurchasableChannels,
    getCurrentPack,
    getTalkToSalesUrl,
    isPretestChannelAvailable,
    isPretestChannelBusinessAvailable,
    getPretestAvailableChannels,
    getLockStatus,
    isPack,
  };
};
