import { FC } from "react";
import { useOnboardingFeature } from "../../onboarding.feature";
import BusinessInfoForm from "./business-info.form.page";
import { Typography } from "antd";

const BusinessInfo: FC = () => {
  const { gotoNextStep, cancelOnBoarding } = useOnboardingFeature();

  return (
    <div
      className="animFadeIn w-full"
      style={{
        maxWidth: "460px",
        margin: "0 auto",
      }}
    >
      <Typography.Title level={4}>Business info</Typography.Title>
      <div className="h-4" />
      <BusinessInfoForm onSubmit={gotoNextStep} onCancel={cancelOnBoarding} />
    </div>
  );
};
export default BusinessInfo;
