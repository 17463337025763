import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import { Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigationFeature } from "src/app/features/navigation/navigation.feature";
import { LoadingPage } from "src/app/features/requests/features/loadings/pages/loading.page";
import { useRequestFeature } from "src/app/features/requests/request.feature";
import { FormComponent } from "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/account-and-brands/pages/account/pages/edit-business/edit-business.form.page";
import { ButtonUI } from "src/app/ui/buttons/button.ui";
import { SelectInputUI } from "src/app/ui/inputs/select/select.ui";
import { useAssetsDomain } from "src/domain/assets/assets.domain";
import { useUsersDomain } from "src/domain/users/users.domain";
import { AdAccount, Brand, SocialAccountType } from "src/graphql/client";
import useSWR from "swr";

import { AssetTypeIconUI } from "src/app/pages/creative-intelligence-suite/pages/creative-analytics/pages/overview/components/tag-insight/asset-type.icon.ui";

const AssignAssetsForm: FC<FormComponent> = ({
  onSubmit,
  onCancel,
  submitBtnLabel = "Continue",
  defaultValues = {},
}) => {
  const { handleSubmit, setValue, control } = useForm({
    defaultValues: {
      ...defaultValues,
      brandId: defaultValues.brand?.id,
    },
  });

  const { sendRequest, isLoading } = useRequestFeature();
  const { goto } = useNavigationFeature();

  const { getUser } = useUsersDomain();
  const { data: user, isLoading: isUserLoading } = useSWR("getUser", getUser);

  const { getBusinessAccountAssets, updateBrandAssets } = useAssetsDomain();

  const { data: assets, isLoading: isAssetsLoading } = useSWR(
    "getBusinessAccountAssets",
    getBusinessAccountAssets,
  );

  const availableAdAccounts = assets?.adAccounts ?? [];

  const socialAccounts = assets?.socialAccounts ?? [];

  const [assignedSocialAccounts, setAssignedSocialAccounts] = useState<
    {
      type: SocialAccountType;
      value: string;
      id: string;
    }[]
  >([{} as any]);

  const [assignedBrand, setAssignedBrand] = useState<Brand | undefined>(
    undefined,
  );

  const [assignedAdAccounts, setAssignedAdAccounts] = useState<AdAccount[]>([
    {} as AdAccount,
  ]);

  useEffect(() => {
    if (!isUserLoading && !user?.businessAccount?.brands?.length) {
      goto("/welcome/create-your-brand");
    }
  }, [user?.businessAccount?.brands]);

  const submitHandler = () => {
    if (!user?.businessAccount?.brands?.length) return;
    sendRequest({
      id: "updatingAssets",
      request: updateBrandAssets({
        brandId:
          assignedBrand?.id ??
          (user?.businessAccount?.brands?.length === 1
            ? user?.businessAccount?.brands[0].id
            : ""),
        assets: {
          adAccount: assignedAdAccounts
            .filter((adAccount) => !!adAccount.id)
            .map((adAccount) => {
              return {
                id: Number(adAccount.id),
                type: adAccount.type,
              };
            }),
          socialAccount: assignedSocialAccounts
            .filter((socialAccount) => !!socialAccount.id)
            .map((socialAccount) => {
              return {
                id: Number(socialAccount.id),
                type: socialAccount.type,
              };
            }),
        },
      }),
      onSuccess(response) {
        onSubmit(response);
      },
    });
  };

  useEffect(() => {
    if (defaultValues.brand) {
      setAssignedBrand(defaultValues.brand);
    }
    if (defaultValues.adAccounts) {
      setAssignedAdAccounts(defaultValues.adAccounts ?? []);
    }
    if (defaultValues.socialAccounts) {
      setAssignedSocialAccounts(defaultValues.socialAccounts ?? []);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (user?.businessAccount?.brands?.length !== 1) return;
    setAssignedBrand(user?.businessAccount?.brands[0]);
  }, [user?.businessAccount?.brands]);

  useEffect(() => {
    if (availableAdAccounts?.length !== 1) return;
    if (assignedAdAccounts[0].id) return;
    setAssignedAdAccounts([availableAdAccounts[0]]);
  }, [availableAdAccounts]);

  useEffect(() => {
    // since there are no socialAdcounts, if it only has one AdAccount, we add it and submit it.
    if (availableAdAccounts?.length !== 1) return;
    if (!assignedAdAccounts[0].id) return;
    handleSubmit(submitHandler)();
  }, [assignedAdAccounts]);

  if (isUserLoading || isAssetsLoading) {
    return <LoadingPage />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(submitHandler)}>
        {user?.businessAccount?.brands?.length &&
        user?.businessAccount?.brands?.length > 1 ? (
          <label
            style={{
              display: defaultValues.brand ? "none" : "block",
            }}
            className="flex-1"
            htmlFor="meta-ad-account"
          >
            <Typography.Title level={5}>Select the brand</Typography.Title>
            <Typography.Text type="secondary" style={{ color: "#1F2630" }}>
              Select the Ad Account you are using to advertise the previously
              created brand.
            </Typography.Text>
            <div className="h-4"></div>
            <Controller
              control={control}
              rules={{ required: "Brand is required" }}
              name="brandId"
              render={({ field }) => (
                <SelectInputUI
                  style={{ width: "200px" }}
                  onChange={(brandId) => {
                    const selectedBrand = user?.businessAccount?.brands?.find(
                      (b) => b.id === brandId,
                    );
                    setValue("brandId", brandId);
                    setAssignedBrand(selectedBrand);
                  }}
                  value={
                    user?.businessAccount?.brands?.length === 1
                      ? (user?.businessAccount?.brands ?? [])[0]?.id
                      : field.value
                  }
                  isDisabled={Boolean(defaultValues.brand)}
                  isSearchable={false}
                  placeholder="Select Brand"
                  options={(user?.businessAccount?.brands ?? [])?.map((b) => ({
                    label: b.name,
                    key: b.id,
                  }))}
                />
              )}
            />

            <div className="h-6"></div>
          </label>
        ) : (
          <></>
        )}
        <div className="flex flex-col">
          {availableAdAccounts?.length && availableAdAccounts?.length > 1 ? (
            <label className="flex-1" htmlFor="meta-ad-account">
              <Typography.Title level={5}>
                Select your Ad Account
              </Typography.Title>
              <Typography.Text type="secondary" style={{ color: "#1F2630" }}>
                Select the Ad Account you are using to advertise{" "}
                {!defaultValues.brand
                  ? "the previously created brand"
                  : defaultValues.brand?.name}
                .
              </Typography.Text>
              <div className="h-4"></div>
              <div className="flex flex-col gap-4">
                {assignedAdAccounts.map((adAccount, key) => {
                  return (
                    <div
                      className="flex w-full flex-1"
                      key={String(adAccount.id) + String(key)}
                    >
                      <SelectInputUI
                        style={{ minWidth: "300px" }}
                        placeholder="Select Ad Account"
                        value={adAccount.name}
                        onChange={(value) => {
                          const newAssignedAdAccounts = [...assignedAdAccounts];
                          const selectedKey = availableAdAccounts.findIndex(
                            (availableAdAccount) =>
                              availableAdAccount.id === value,
                          );
                          newAssignedAdAccounts[key] =
                            availableAdAccounts[selectedKey];
                          setAssignedAdAccounts(newAssignedAdAccounts);
                        }}
                        options={availableAdAccounts.map(
                          (availableAdAccount) => {
                            return {
                              label: availableAdAccount.name ?? "",
                              isDisabled: assignedAdAccounts.some(
                                (aAccount) => {
                                  return aAccount.id === availableAdAccount.id;
                                },
                              ),
                              key: availableAdAccount.id,
                            };
                          },
                        )}
                        isSearchable={false}
                      />
                      <ButtonUI
                        isDisabled={assignedAdAccounts.length === 1}
                        type="text"
                        title={
                          assignedAdAccounts.length === 1
                            ? "You must have at least one ad account"
                            : undefined
                        }
                        onClick={() => {
                          setAssignedAdAccounts(
                            assignedAdAccounts.filter(
                              (sAccount, aAccountKey) => {
                                return aAccountKey !== key;
                              },
                            ),
                          );
                        }}
                      >
                        <DeleteOutlined />
                      </ButtonUI>
                    </div>
                  );
                })}
              </div>
              <div className="h-4" />
              <div>
                <ButtonUI
                  title={
                    assignedAdAccounts.length === availableAdAccounts.length
                      ? "You have added all the available ad accounts"
                      : undefined
                  }
                  onClick={() => {
                    setAssignedAdAccounts([...assignedAdAccounts, {} as any]);
                  }}
                  type="light"
                  isDisabled={
                    assignedAdAccounts.length === availableAdAccounts.length
                  }
                  icon={<PlusCircleOutlined />}
                >
                  Add ad account
                </ButtonUI>
              </div>
            </label>
          ) : (
            <></>
          )}
        </div>
        {socialAccounts.length > 0 && (
          <div>
            <div className="h-8" />
            <Typography.Title level={5}>
              Add your Social Accounts
            </Typography.Title>
            <Typography.Text type="secondary" style={{ color: "#1F2630" }}>
              Add any social accounts you use to advertise your brand.
            </Typography.Text>
            <div className="flex w-full flex-col  gap-4">
              {assignedSocialAccounts.map((socialAccount, key) => {
                return (
                  <div
                    className="flex"
                    key={socialAccount?.id ?? "undefined" + key}
                  >
                    <label
                      className="flex flex-1 gap-2 whitespace-nowrap"
                      htmlFor="instagram-account"
                    >
                      <div className="flex flex-1 flex-col">
                        <SelectInputUI
                          placeholder="Select Social Account"
                          value={socialAccount.id}
                          onChange={(value) => {
                            const newAssignedSocialAccounts: any = [
                              ...assignedSocialAccounts,
                            ];
                            const selectedKey = socialAccounts.findIndex(
                              (availableAdAccount) =>
                                availableAdAccount.id === value,
                            );
                            newAssignedSocialAccounts[key] =
                              socialAccounts[selectedKey];
                            setAssignedSocialAccounts(
                              newAssignedSocialAccounts,
                            );
                          }}
                          options={socialAccounts.map((availableAdAccount) => {
                            return {
                              label: (
                                <div className="flex items-center gap-2">
                                  <AssetTypeIconUI
                                    assetType={availableAdAccount.type}
                                  />
                                  <strong> {availableAdAccount.name}</strong>
                                  <span>{availableAdAccount.type}</span>
                                </div>
                              ),
                              labelString:
                                availableAdAccount.name +
                                availableAdAccount.type,
                              isDisabled: assignedSocialAccounts.some(
                                (aAccount) => {
                                  return aAccount.id === availableAdAccount.id;
                                },
                              ),
                              key: availableAdAccount.id,
                            };
                          })}
                          isSearchable={true}
                        />
                      </div>
                    </label>
                    <ButtonUI
                      onClick={() => {
                        setAssignedSocialAccounts(
                          assignedSocialAccounts.filter(
                            (a, socialAccountKey) => socialAccountKey !== key,
                          ),
                        );
                      }}
                      title={
                        assignedSocialAccounts.length === 1
                          ? "You must have at least one social account"
                          : undefined
                      }
                      isDisabled={assignedSocialAccounts.length === 1}
                      type="text"
                    >
                      <DeleteOutlined />
                    </ButtonUI>
                  </div>
                );
              })}
            </div>
            <div className="h-4" />
            <ButtonUI
              title={
                assignedSocialAccounts.length === socialAccounts.length
                  ? "You have added all the available socials accounts"
                  : undefined
              }
              onClick={() => {
                setAssignedSocialAccounts([
                  ...assignedSocialAccounts,
                  {} as any,
                ]);
              }}
              type="light"
              isDisabled={
                assignedSocialAccounts.length === socialAccounts.length
              }
              icon={<PlusCircleOutlined />}
            >
              Add social account
            </ButtonUI>
          </div>
        )}
        <div className="h-4"></div>
        <div className="flex items-center justify-end gap-2">
          <ButtonUI type="text" onClick={onCancel}>
            Cancel
          </ButtonUI>
          <ButtonUI
            isLoading={isLoading("updatingAssets")}
            isDisabled={
              !assignedBrand?.id ||
              !(
                assignedAdAccounts
                  .map((adAccount) => adAccount.id)
                  .filter((value) => !!value).length >= 1
              )
            }
            onClick={handleSubmit(submitHandler)}
          >
            {submitBtnLabel}
          </ButtonUI>
        </div>
      </form>
    </div>
  );
};
export default AssignAssetsForm;
