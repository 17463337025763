import { FC } from "react";
import useSWR from "swr";
import { useUsersDomain } from "src/domain/users/users.domain";
import { EditPasswordFormPage } from "./edit-password.form.page";

const EditPasswordPage: FC = () => {
  const { getUser } = useUsersDomain();
  const { data: userData, mutate } = useSWR("getUser", getUser);

  if (!userData) return <></>;

  return (
    <div style={{ maxWidth: "400px" }}>
      <EditPasswordFormPage
        defaultValues={userData}
        onSubmit={() => mutate()}
      />
    </div>
  );
};
export default EditPasswordPage;
