import {
  LDClient,
  useFlags as _useFlags,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import { useEffect, useRef, useState } from "react";
import { Brand, UserWithPermissions } from "src/graphql/client";
import { FeatureFlagsType } from "./flags.model";

const useFlags = () => {
  return _useFlags<FeatureFlagsType>();
};

const shouldNotRenderBasedOnFeatureFlag = (
  flags: FeatureFlagsType,
  flagName?: keyof FeatureFlagsType,
) => {
  if (!flagName) return false;

  // flag is off
  return flags[flagName] === false;
};

const identifyUser = (
  ldClient: LDClient,
  onDone: () => void,
  user?: UserWithPermissions,
  organization?: Brand,
) => {
  ldClient.identify(
    {
      kind: "multi",
      user: user
        ? {
            key: user?.id,
            email: user?.email,
            name: user?.name,
          }
        : { anonymous: true },
      organization: organization
        ? {
            key: organization?.id,
            businessAccountId: user?.businessAccount?.id,
            name: organization?.name,
            country: organization?.country,
          }
        : { anonymous: true },
    },
    undefined,
    (error) => {
      if (error) {
        throw new Error(error.message);
      }
      onDone();
    },
  );
};
const useLdcIdentifyUser = ({
  user,
  isLoadingUser,
  currentBrand,
}: {
  isLoadingUser: boolean;
  user?: UserWithPermissions | null;
  currentBrand?: Brand | null;
}) => {
  const isLoggedIn = !!user && !!currentBrand && !isLoadingUser;
  const isNotLoggedIn = user === null && !isLoadingUser;

  const ldClient = useLDClient();
  const [launchDarklyIdentified, setLaunchDarklyIdentified] = useState(false);

  const ldCLoadedRefWithUser = useRef(false);
  const ldCLoadedRefAnonymous = useRef(false);

  useEffect(() => {
    // call identify with all user data since user is logged in
    if (ldClient && isLoggedIn && !ldCLoadedRefWithUser.current) {
      ldCLoadedRefWithUser.current = true;
      ldCLoadedRefAnonymous.current = false;

      identifyUser(
        ldClient,
        () => setLaunchDarklyIdentified(true),
        user,
        currentBrand,
      );
    }
    // call identify as anonymous user
    if (ldClient && isNotLoggedIn && !ldCLoadedRefAnonymous.current) {
      ldCLoadedRefWithUser.current = false;
      ldCLoadedRefAnonymous.current = true;

      identifyUser(ldClient, () => setLaunchDarklyIdentified(true));
    }
  }, [ldClient, isLoggedIn, isNotLoggedIn]);

  return { launchDarklyIdentified };
};

export {
  shouldNotRenderBasedOnFeatureFlag,
  useFlags,
  useLDClient,
  useLdcIdentifyUser,
};
