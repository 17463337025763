import { useRepositoryFeature } from "src/app/features/repositories/repositories.feature";
import { useSessionFeature } from "src/app/features/session/session.feature";
import { SignUpFormValues } from "src/app/pages/signup/signup.form.page";
import {
  AuthenticationInput,
  ForgetPasswordInput,
  SignUpInput,
  User,
  UserListElement,
  UserProfileInput,
  UserWithPermissions,
  enumBusinessStatus,
} from "src/graphql/client";

export interface UsersRepository {
  createUser(user: SignUpInput, code: string): Promise<User>;
  login(loginProps: AuthenticationInput): Promise<{ sessionToken: string }>;
  logout(): Promise<boolean>;
  getLoggedInUser(): Promise<UserWithPermissions>;
  updateUser(input: UserProfileInput): Promise<User>;
  getUserByBrand(brandId: string): Promise<UserListElement[]>;
  getUsersByBusinessAccount(): Promise<UserListElement[]>;
  deleteUser(userId: string): Promise<boolean>;
  regeneratePassword(input: ForgetPasswordInput): Promise<boolean>;
  impersonateLogin(
    loginProps: AuthenticationInput,
  ): Promise<{ sessionToken: string }>;
  getUserHash(): Promise<string>;
}

export const useUsersDomain = (repoId = "UsersRepository") => {
  const { repository } = useRepositoryFeature<UsersRepository>(repoId);
  const { user } = useSessionFeature();

  const getUser = () => {
    return repository.getLoggedInUser();
  };

  const getUserHash = () => {
    return repository.getUserHash();
  };

  const getUserByBrand = (brandId: string) => {
    return repository.getUserByBrand(brandId);
  };

  const updateUser = (input: UserProfileInput) => {
    return repository.updateUser(input);
  };

  const getUsersByBusinessAccount = () => {
    return repository.getUsersByBusinessAccount();
  };

  const deleteUser = (userId: string) => {
    return repository.deleteUser(userId);
  };

  const regeneratePassword = (input: ForgetPasswordInput) => {
    return repository.regeneratePassword(input);
  };

  const isUserReady = () => {
    return user?.businessAccount?.status === enumBusinessStatus.businessActive;
  };

  const createUser = async (input: SignUpFormValues) => {
    return repository.createUser(
      {
        name: `${input.name} ${input.lastName}`,
        email: input.email,
        termsAndConditionsAccepted: true,
        password: input.password,
      },
      input.earlyAccessToken ?? "",
    );
  };

  const skipLoadingExtraData = (
    loggedUser: UserWithPermissions | undefined,
  ) => {
    if (loggedUser === undefined) {
      return false;
    }
    if (loggedUser?.businessAccount === null) {
      return false;
    }
    if (
      loggedUser?.businessAccount?.status &&
      // leaving this as an array in case we need to extend it
      ([enumBusinessStatus.businessInactive] as string[]).includes(
        loggedUser.businessAccount.status,
      )
    ) {
      return false;
    }
    return true;
  };

  return {
    skipLoadingExtraData,
    getUser,
    getUserByBrand,
    deleteUser,
    updateUser,
    getUsersByBusinessAccount,
    regeneratePassword,
    isUserReady,
    createUser,
    getUserHash,
  };
};
