import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigationFeature } from "src/app/features/navigation/navigation.feature";
import { useSessionState } from "src/app/features/session/session.state";
import { useTranslationFeature } from "src/app/features/translations/translation.feature";
import { titleCaseFormatterTool } from "src/app/tools/formatters/title-case.formatter.tool";
import { Directory } from "src/app/tools/models/directory.model";
import { Operation } from "../../models/operation.model";
import { OperationError } from "./error.model";

export const useErrors = () => {
  const [errors, setError] = useState<Directory<OperationError>>({});

  const { goto, getPathName } = useNavigationFeature();
  const { translateOrEmpty } = useTranslationFeature();
  const state = useSessionState();

  const addErrors = <T>(operation: Operation<T>, error: OperationError) => {
    setError((prev) => {
      return {
        ...prev,
        [operation.id ?? "undefined"]: error,
      };
    });
  };

  const getErrors = (operationId: string): OperationError[] => {
    return [errors[operationId]] ?? [];
  };

  const onOperationError = (
    operation: Operation<any>,
    error: OperationError,
  ) => {
    if (error.extensions?.code === "UNAUTHORIZED") {
      // this will remove the toast form main page
      if (getPathName() !== "/") {
        toast.error("Outdated session, please login again.", {
          id: "UNAUTHORIZED",
        });
      }

      state.removeUser();
      localStorage.clear();
      goto("/login");
      return;
    }

    if (error.extensions?.code === "TOO_MANY_REQUEST") {
      return toast.error(
        `Too many failed login attempts. Please try again in ${error.extensions?.timeWindow} minutes`,
      );
    }

    return showGenericError(error, operation);
  };

  const showGenericError = (
    error: OperationError,
    operation: Operation<unknown>,
  ) => {
    const translatedError = translateOrEmpty(`errors.${error?.message}`);
    const id = operation.id || error?.message;
    const msg = translatedError || titleCaseFormatterTool(error?.message);

    toast.error(msg, {
      id,
      duration: 4000,
    });
  };

  const generalErrorsHandler = (
    operation: Operation<any>,
    errors: OperationError[] = [],
  ) => {
    (errors.length ? errors : [errors as any])?.forEach(
      (error: OperationError) => onOperationError(operation, error),
    );
  };

  return {
    addErrors,
    generalErrorsHandler,
    getErrors,
  };
};
