import { lazy } from "react";
import { Route } from "src/app/features/navigation/models/route.model";

const OverviewPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/creative-analytics/pages/overview/overview.page"
    ),
);

const ElementsReportingPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/creative-analytics/pages/elements-reporting/elements-reporting.page"
    ),
);

export const TagDetailPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/creative-analytics/pages/overview/pages/tag-detail/tag-detail.page"
    ),
);

export const CreativeDetailPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/creative-analytics/pages/overview/pages/ad-detail/ad-detail.page"
    ),
);

const AdsLibraryPage = lazy(
  () =>
    import(
      "@creative-intelligence-suite-pages/creative-analytics/pages/ads-library/ads-library.page"
    ),
);

export const CreativeAnalyticsRoutes: Route[] = [
  {
    path: "/creative-analytics",
    element: OverviewPage,
    title: "Overview",
  },
  {
    path: "/creative-analytics/tag/:superclass/:class/:value",
    element: TagDetailPage,
    title: "Tag detail",
  },
  {
    path: "/creative-analytics/creative/:id",
    element: CreativeDetailPage,
    title: "Creative detail",
  },
  {
    path: "/elements-reporting",
    element: ElementsReportingPage,
    title: "All creative elements",
  },
  {
    path: "/ads-library",
    element: AdsLibraryPage,
    title: "Ads Library",
    controlledByFeatureFlag: "enableAdsLibraryFeature",
  },
  {
    path: "/ads-library/:id",
    element: CreativeDetailPage,
    title: "Ad Detail",
    controlledByFeatureFlag: "enableAdsLibraryFeature",
  },
];
