import { delayTool } from "src/app/tools/utils/delay.tool";
import { IntegrationRepository } from "src/domain/integrations/integration.domain";
import {
  IntegrationsAssets,
  Platform,
  PlatformState,
  enumPlatform,
} from "src/graphql/client";
import { FeatureFlagsType } from "../../feature-flags/flags.model";
import { client } from "../clients/graphql.client";

export class IntegrationBackendRepository implements IntegrationRepository {
  async getPlatformOauthUrl(
    platform: Platform,
    // eslint-disable-next-line unused-imports/no-unused-vars
    flags: FeatureFlagsType,
  ): Promise<string> {
    await delayTool(400);

    const config = {
      [enumPlatform.META]: this.getMetaOauthUrl.bind(this),
      [enumPlatform.GOOGLE]: this.getGoogleOauthUrl.bind(this),
      [enumPlatform.TIKTOK]: this.getTiktokOauthUrl.bind(this),
    };

    if (!config[platform as keyof typeof config]) {
      throw new Error(`Platform ${platform} OAuth not available.`);
    }
    return config[platform as keyof typeof config]();
  }

  private getPlatformOAuthRedirectUri(platform: Platform) {
    return `${window.origin}/oauth/${platform.toLowerCase()}`;
  }

  private async getMetaOauthUrl() {
    const params = new URLSearchParams({
      app_id: "5365968910151407",
      client_id: "5365968910151407",
      config_id: "352873217735342",
      redirect_uri: this.getPlatformOAuthRedirectUri(enumPlatform.META),
      state: "memorable",
    }).toString();

    return `https://www.facebook.com/v19.0/dialog/oauth?${params}`;
  }

  private async getGoogleOauthUrl() {
    const params = new URLSearchParams({
      access_type: "offline",
      prompt: "consent",
      scope: [
        "email",
        "profile",
        "https://www.googleapis.com/auth/adwords",
        "https://www.googleapis.com/auth/display-video",
      ].join(" "),

      client_id:
        "63866680040-1kqce2dqhmd4l0208seo22fldv4p048v.apps.googleusercontent.com",
      redirect_uri: this.getPlatformOAuthRedirectUri(enumPlatform.GOOGLE),
      response_type: "code",
    });

    return `https://accounts.google.com/o/oauth2/v2/auth?${params}`;
  }

  private async getTiktokOauthUrl() {
    const params = new URLSearchParams({
      app_id: "7142727659888312322",
      state: "memorable",
      redirect_uri: this.getPlatformOAuthRedirectUri(enumPlatform.TIKTOK),
    }).toString();

    return `https://business-api.tiktok.com/portal/auth?${params}`;
  }

  async disconnect(platform: Platform) {
    return client.chain.mutation
      .deletePlatformIntegration({
        platform,
      })
      .get();
  }

  async createPlatformIntegration(authCode: string, platform: Platform) {
    return client.chain.mutation
      .createPlatformIntegration({
        authCode,
        platform,
        redirectUri: this.getPlatformOAuthRedirectUri(platform),
      })
      .get({
        adAccounts: {
          id: true,
          name: true,
        },
        socialAccounts: {
          id: true,
          name: true,
        },
      }) as Promise<IntegrationsAssets>;
  }

  async getPlatformIntegrated() {
    return client.chain.query.getPlatformConnections.get({
      createdAt: true,
      platform: true,
      updatedAt: true,
      expirationDate: true,
      extractedAds: true,
      extractedCreatives: true,
      status: true,
      totalAds: true,
      totalCreatives: true,
      userEmail: true,
    }) as Promise<PlatformState[]>;
  }

  getBusinessAccountAssets() {
    return client.chain.query.getBusinessAccountAssets.get({
      adAccounts: { id: true, name: true, type: true },
      socialAccounts: { id: true, name: true, type: true },
    }) as Promise<IntegrationsAssets>;
  }
}
