import { FC } from "react";
import { Tag } from "antd";
import { Metric, MetricVariance } from "src/graphql/client";
import { percentFormatterTool } from "src/app/tools/formatters/percent.formatter.tool";
import { useTranslationFeature } from "src/app/features/translations/translation.feature";
import { shortCurrencyFormatterTool } from "src/app/tools/formatters/currency.formatter.tool";

type ImpactMetricProps = {
  variance: MetricVariance;
};

export const ImpactMetricUI: FC<ImpactMetricProps> = ({ variance }) => {
  const { t } = useTranslationFeature();

  const getFormattedValue = (rawValue: number, metric: Metric) => {
    if (metric.includes("cost")) {
      return shortCurrencyFormatterTool(rawValue);
    }
    return percentFormatterTool(rawValue);
  };

  const getVarianceColor = (variance: number, metric: Metric) => {
    if (metric.includes("cost")) {
      return variance <= 0 ? "green" : "red";
    }
    return variance >= 0 ? "green" : "red";
  };

  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center gap-1">
        <strong className="text-lg">{t(`metrics.${variance.metric}`)}</strong>{" "}
        {variance.value ? (
          <span className="text-l">
            {getFormattedValue(variance.value, variance.metric)}
          </span>
        ) : (
          <></>
        )}
      </div>
      <div className="flex-1"></div>
      {variance?.variance ? (
        <Tag
          style={{
            fontSize: "0.8rem",
            flexDirection: "row-reverse",
            border: "none",
            padding: "4px 4px",
            margin: 0,
            display: "inline-flex",
            alignItems: "center",
            gap: "4px",
          }}
          color={getVarianceColor(variance?.variance, variance.metric)}
        >
          {variance?.variance > 0 ? "+" : ""}
          {getFormattedValue(variance?.variance, variance.metric)}
        </Tag>
      ) : (
        <></>
      )}
    </div>
  );
};
