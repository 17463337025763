import { ChannelListUI } from "@creative-intelligence-suite-pages/creative-analytics/pages/overview/components/creative-highlight.widget";
import { TableUI } from "@ui/tables/table.ui";
import { ColumnsType } from "antd/es/table";
import { useCallback, useDeferredValue, useMemo } from "react";
import { useSessionFeature } from "src/app/features/session/session.feature";
import { useAdsLibraryState } from "src/domain/ads/ads.state";
import {
  AdGroup,
  AdGroupRankingElement,
  Campaign,
  Channel,
} from "src/graphql/client";
import { MetricValueMode } from "./ads-reporting.widget";
import { commonTableFields, TitleWrapper } from "./common-table-fields.widget";

export const AdsGroupsTableWidget = ({
  isLoading,
  data,
  currentMode,
}: {
  data: AdGroupRankingElement[] | undefined;
  isLoading: boolean;
  currentMode: MetricValueMode;
}) => {
  const dataDeferred = useDeferredValue(data);
  const { adsGroupsSelected, setAdsGroupsSelected } = useAdsLibraryState(
    (state) => ({
      setAdsGroupsSelected: state.setAdsGroupsSelected,
      adsGroupsSelected: state.adsGroupsSelected,
      campaignsSelected: state.campaignsSelected,
    }),
  );
  const { canIAccess } = useSessionFeature();

  const columns: ColumnsType<AdGroupRankingElement> = [
    {
      title: <TitleWrapper>Ad Group name</TitleWrapper>,
      width: 500,
      dataIndex: "adGroup",
      className: "px-6 py-4 max-w-175 flex-1",
      render: (adGroup: AdGroup) => {
        const showId = false;
        return (
          <div className="flex justify-start gap-2">
            <div className="body-12-regular flex text-black-white-black break-all">
              {showId ? adGroup.id + " " : ""}
              {adGroup?.name}
            </div>
          </div>
        );
      },
    },
    {
      title: <TitleWrapper>Channel</TitleWrapper>,
      dataIndex: "channels",
      width: 105,
      className: "px-6 py-4 max-w-20 w-20",
      render: (channels: Channel[]) => {
        return (
          <div className="flex items-center justify-center">
            <ChannelListUI channels={channels} />
          </div>
        );
      },
    },
    {
      title: <TitleWrapper>Objective</TitleWrapper>,
      width: 135,
      dataIndex: "campaign",
      className: "px-6 py-4",
      render: (campaign: Campaign) => {
        return (
          <div className="text-xs break-word">
            {campaign?.objective ?? "N/A"}
          </div>
        );
      },
    },
    ...commonTableFields({ canIAccess, currentMode }),
  ];

  const onSelected = useCallback((selectedRowKeys: React.Key[]) => {
    setAdsGroupsSelected((selectedRowKeys as string[]) ?? undefined);
  }, []);

  const withCalculation = useMemo(
    () =>
      columns.reduce((acc, current) => {
        return acc + Number(current.width);
      }, 0),
    [columns],
  );

  return (
    <div className="w-full">
      <TableUI
        className="andt-table-header"
        isLoading={isLoading}
        columns={columns}
        scrollDimensions={{ y: 500, x: withCalculation }}
        data={
          dataDeferred?.map((e) => ({
            ...e,
            isSelectionDisabled:
              adsGroupsSelected && adsGroupsSelected?.length >= 5
                ? !adsGroupsSelected?.includes(e.adGroup.id)
                : false,
          })) ?? []
        }
        maxItemPerPage={false}
        onSelectChange={onSelected}
        hideHeaderCheckbox={true}
        selectedRowKeys={adsGroupsSelected ?? []}
      />
    </div>
  );
};
