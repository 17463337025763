import { jwtDecode } from "jwt-decode";
import { env } from "src/app/tools/utils/env.tool";
import { token } from "src/app/tools/utils/token.tool";
export const graphqlEndpoint = env("VITE_GRAPHQL_ENDPOINT");

const mutation = `
  mutation RefreshToken {
    refreshLogInToken
  }
`;

let functionRef: any = undefined;

const callEndpointToRefresh = async (currentToken: string) => {
  if (functionRef) {
    return functionRef;
  }

  functionRef = fetch(graphqlEndpoint, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${currentToken}`,
    },
    body: JSON.stringify({ query: mutation }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Error on api call to refresh token");
      }
    })
    .finally(() => {
      functionRef = undefined;
    });

  return functionRef;
};

export const getFreshedToken = async (
  currentToken: string,
): Promise<string> => {
  const response = await callEndpointToRefresh(currentToken);
  // something went wrong
  if (response?.errors) {
    return "";
  }
  return response?.data?.refreshLogInToken;
};

export const handleTokenRefresh = async (
  currentToken: string | null,
  hasHandleTokenRefresh: boolean,
): Promise<{ authorization: string }> => {
  if (currentToken) {
    try {
      const decodedToken = jwtDecode<{
        userId: string;
        expires: number;
        issued: number;
      }>(currentToken);
      const currentDate = new Date();

      // JWT exp is in seconds
      if (
        decodedToken &&
        (decodedToken.expires ?? 0) < currentDate.getTime() &&
        hasHandleTokenRefresh
      ) {
        // refresh token
        const newToken = await getFreshedToken(currentToken);

        // set token for new requests
        token.set(newToken);

        return { authorization: token.asHeader() };
      } else {
        // no need refresh
        return { authorization: token.asHeader() };
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Token refresh did not work");
    }
  }
  return { authorization: "" };
};
