import { CreativeAnalyticsRepository } from "src/domain/creative-analytics/models/creative-analytics.repository.model";
import {
  AdProfile,
  BestAndWortsHighlight,
  CreativeAnalyticFilter,
  Highlight,
  HighlightsInput,
  Insight,
  Metric,
  MetricsSummary,
  PerformanceDataEntry,
  TagFilterInput,
  TagMetric,
  TagOverallMetrics,
  TagRankingOrderingInput,
  TagSummary,
  TreeNode,
} from "src/graphql/client";
import { client } from "../clients/graphql.client";

export const allMetrics = {
  impressions: {
    value: 1,
  },
  costPerEngagement: {
    value: 1,
  },
  videoThroughRate: {
    value: 1,
  },
  conversionRate: {
    value: 1,
  },
  costPerMille: {
    value: 1,
  },
  leadRate: {
    value: 1,
  },
  costPerLead: {
    value: 1,
  },
  engagement: {
    value: 1,
  },
  engagementRate: {
    value: 1,
  },
  videoViews: {
    value: 1,
  },
  costPerCompletedView: {
    value: 1,
  },
  costPerClick: {
    value: 1,
  },
  clicks: {
    value: 1,
  },
  clickThroughRate: {
    value: 1,
  },
  conversion: {
    value: 1,
  },
  usage: {
    value: 1,
  },
  spend: {
    value: 1,
  },
  costPerAcquisition: {
    value: 1,
  },
  frequency: {
    value: 1,
  },
  appInstallRate: {
    value: 1,
  },
  costPerAppInstall: {
    value: 1,
  },
  leads: {
    value: 1,
  },
};

export class CreativeAnalyticsBackendRepository
  implements CreativeAnalyticsRepository
{
  getCreativeAnalyticsSummary(filter: CreativeAnalyticFilter) {
    this.checkAllObjectiveCampaign(filter);
    return client.chain.query
      .getCreativeAnalyticsSummary({
        filter,
      })
      .get({
        creativesTotal: 1,
        adsTotal: 1,
        metrics: allMetrics,
      }) as Promise<MetricsSummary>;
  }

  getTagRanking(
    filter: CreativeAnalyticFilter,
    orderingColumn: TagRankingOrderingInput | null,
    tagFilter: TagFilterInput[],
  ) {
    this.checkAllObjectiveCampaign(filter);
    this.checkAllSuperClass(filter);
    return client.chain.query
      .getTagRanking({ filter, orderingColumn, tagFilter })
      .get({
        tip: {
          detail: 1,
          placeHolders: {
            key: 1,
            value: 1,
            type: 1,
          },
          value: 1,
        },
        tag: {
          tagSuperClass: 1,
          tagClass: 1,
          tagValue: 1,
        },
        channels: 1,
        spendTotal: {
          total: 1,
          value: 1,
        },
        brandLiftRatio: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        brandLift: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        impressionsTotal: {
          total: 1,
          value: 1,
        },
        frequency: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        costPerMille: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        engagementRate: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        costPerEngagement: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        costPerLead: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        leadRate: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        costPerAcquisition: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        clickThroughRate: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        costPerClick: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        costPerAppInstall: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        videoThroughRate: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        costPerCompletedView: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
        conversionRate: {
          average: 1,
          metric: 1,
          value: 1,
          variance: 1,
        },
      }) as Promise<TagOverallMetrics[]>;
  }

  getTagSummary(filter: CreativeAnalyticFilter) {
    this.checkAllObjectiveCampaign(filter);
    return client.chain.query
      .getTagSummary({
        filter,
      })
      .get({
        frequency: {
          max: 1,
          avg: 1,
          min: 1,
        },
        costPerMile: {
          max: 1,
          avg: 1,
          min: 1,
        },
        engagementRate: {
          max: 1,
          avg: 1,
          min: 1,
        },
        costPerEngagement: {
          max: 1,
          avg: 1,
          min: 1,
        },
        videoThroughRate: {
          max: 1,
          avg: 1,
          min: 1,
        },
        costPerCompletedVideo: {
          max: 1,
          avg: 1,
          min: 1,
        },
        clickThroughRate: {
          max: 1,
          avg: 1,
          min: 1,
        },
        costPerClick: {
          max: 1,
          avg: 1,
          min: 1,
        },
        leadRate: {
          max: 1,
          avg: 1,
          min: 1,
        },
        costPerLead: {
          max: 1,
          avg: 1,
          min: 1,
        },
        conversionRate: {
          max: 1,
          avg: 1,
          min: 1,
        },
        costPerAcquisition: {
          max: 1,
          avg: 1,
          min: 1,
        },
        appInstallRate: {
          max: 1,
          avg: 1,
          min: 1,
        },
        costPerAppInstall: {
          max: 1,
          avg: 1,
          min: 1,
        },
        brandLiftRatio: {
          max: 1,
          avg: 1,
          min: 1,
        },
        brandLift: {
          max: 1,
          avg: 1,
          min: 1,
        },
      }) as Promise<TagSummary>;
  }

  getTagUsageRanking(filter: CreativeAnalyticFilter) {
    this.checkAllObjectiveCampaign(filter);
    return client.chain.query.getTagUsageRanking({ filter }).get({
      tag: {
        tagSuperClass: 1,
        tagClass: 1,
        tagValue: 1,
      },
      channel: 1,
      metrics: allMetrics,
    }) as Promise<TagMetric[]>;
  }

  getTagsByUsage(filter: CreativeAnalyticFilter) {
    this.checkAllObjectiveCampaign(filter);
    return client.chain.query.getTagsByUsage({ filter }).get({
      key: 1,
      nestedTotal: 1,
      children: {
        key: 1,
        nestedTotal: 1,
        children: {
          key: 1,
          nestedTotal: 1,
        },
      },
    }) as Promise<TreeNode[]>;
  }

  getTagPerformanceOverTime(filter: CreativeAnalyticFilter, metric: Metric) {
    this.checkAllObjectiveCampaign(filter);
    return client.chain.query
      .getTagPerformanceOverTime({ filter, metric })
      .get({
        date: 1,
        data: {
          key: 1,
          metric: 1,
          main: 1,
        },
      }) as Promise<PerformanceDataEntry[]>;
  }

  getHighlightsV2(filter: HighlightsInput) {
    this.checkAllObjectiveCampaign(filter);
    return client.chain.query
      .getHighlightsV2({
        filter,
      })
      .get({
        advertisementId: 1,
        name: 1,
        fileType: 1,
        result: {
          metric: 1,
          variance: 1,
          value: 1,
          average: 1,
        },
        campaignObjective: 1,
        channel: 1,
        url: 1,
      }) as Promise<Highlight[]>;
  }
  // @deprecated
  getHighlights(filter: CreativeAnalyticFilter) {
    this.checkAllObjectiveCampaign(filter);
    return client.chain.query
      .getHighlights({
        filter,
      })
      .get({
        best: {
          fileType: 1,
          url: 1,
          name: 1,
          campaignObjective: 1,
          advertisementId: 1,
          result: {
            metric: 1,
            value: 1,
            variance: 1,
          },
          channel: 1,
        },
        worst: {
          fileType: 1,
          url: 1,
          name: 1,
          campaignObjective: 1,
          advertisementId: 1,
          result: {
            metric: 1,
            value: 1,
            variance: 1,
          },
          channel: 1,
        },
      }) as Promise<BestAndWortsHighlight>;
  }

  getInsights(filter: CreativeAnalyticFilter) {
    this.checkAllObjectiveCampaign(filter);
    return client.chain.query
      .getInsights({
        filter,
      })
      .get({
        channel: 1,
        spend: 1,
        expectedImpact: 1,
        result: {
          metric: 1,
          value: 1,
          variance: 1,
        },
        campaignObjective: 1,
        tag: {
          tagValue: 1,
          tagClass: 1,
          tagSuperClass: 1,
        },
        tags: {
          tagValue: 1,
          tagClass: 1,
          tagSuperClass: 1,
        },
      }) as Promise<Insight[]>;
  }

  getCreativePerformanceOverTime(
    filter: CreativeAnalyticFilter,
    adId: string,
    metric: Metric,
  ) {
    return client.chain.query
      .getCreativePerformanceOverTime({ filter, adId, metric })
      .get({
        date: 1,
        data: {
          main_0: 1,
          metric_0: 1,
        },
      }) as Promise<any>;
  }

  getAdProfile(filter: CreativeAnalyticFilter, adId: string) {
    return client.chain.query.getAdProfile({ filter, adId }).get({
      advertisementId: 1,
      fileType: 1,
      url: 1,
      creativeId: 1,
      campaignObjective: 1,
      channel: 1,
      name: 1,
      tags: {
        tagClass: 1,
        importance: 1,
        tagValue: 1,
        tagSuperClass: 1,
      },
      heatmapUrl: 1,
      creativeMetrics: {
        brandLift: {
          value: 1,
        },
        memorability: {
          value: 1,
        },
        brandPresence: {
          value: 1,
        },
        aesthetics: {
          value: 1,
        },
        engagementPotential: {
          value: 1,
        },
        authenticity: {
          value: 1,
        },
        focus: {
          value: 1,
        },
        cognitiveLoad: {
          value: 1,
        },
        cognitiveEase: {
          value: 1,
        },
        distinctiveness: {
          value: 1,
        },
      },
      metrics: {
        ...allMetrics,
        brandLift: {
          value: 1,
        },
        spend: {
          value: 1,
        },
      },
    }) as Promise<AdProfile>;
  }

  getAdProfileRecommendations(filter: CreativeAnalyticFilter, adId: string) {
    return client.chain.query.getAdProfile({ filter, adId }).get({
      advertisementId: 1,
      creativeId: 1,
      recommendations: {
        ruleId: 1,
        goal: 1,
        type: 1,
        campaignObjective: 1,
        action: 1,
        title: 1,
        customMessage: 1,
        metricImpacts: {
          impact: 1,
          metric: 1,
          impactScore: 1,
          trend: 1,
        },
        sourceTag: {
          importance: 1,
          tagClass: 1,
          tagSuperClass: 1,
          tagValue: 1,
        },
        targetTag: {
          importance: 1,
          tagClass: 1,
          tagSuperClass: 1,
          tagValue: 1,
        },
        channel: 1,
        __typename: 1,
      },
    }) as Promise<AdProfile>;
  }

  private checkAllObjectiveCampaign(
    filter: CreativeAnalyticFilter,
  ): CreativeAnalyticFilter {
    if (filter.campaignObjective === ("all" as any)) {
      filter.campaignObjective = null;
    }
    return filter;
  }

  private checkAllSuperClass(
    filter: CreativeAnalyticFilter,
  ): CreativeAnalyticFilter {
    if (filter.superClass === ("all" as any)) {
      filter.superClass = null;
    }
    return filter;
  }
}
