import { CTvSvg } from "@ui/icons/svgs";
import { FC } from "react";
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { HiRectangleGroup } from "react-icons/hi2";
import { SiTiktok } from "react-icons/si";
import { Channel, enumChannel } from "src/graphql/client";

type ChannelIconUIProps = {
  channel: Channel;
  size?: number;
};

export const ChannelIconUI: FC<ChannelIconUIProps> = ({ channel, size }) => {
  return getChannelIcon(channel, { size });
};

export const getChannelIcon = (channel: Channel, props = {}) => {
  if (channel === enumChannel.crossChannel)
    return <HiRectangleGroup {...props} />;
  if (channel === enumChannel.facebook) return <AiFillFacebook {...props} />;
  if (channel === enumChannel.youtube) return <AiFillYoutube {...props} />;
  if (channel === enumChannel.instagram) return <AiFillInstagram {...props} />;
  if (channel === enumChannel.tiktok) return <SiTiktok {...props} />;
  if (channel === enumChannel.ctv)
    return (
      <CTvSvg
        style={{
          color: "var(--black-white-black)",
          width: "16px",
          height: "16px",
        }}
        {...props}
      />
    );
  return <></>;
};
