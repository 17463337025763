import { FC } from "react";
import CardUI from "src/app/ui/cards/card.ui";
import { ScrollTopButtonWidget } from "src/app/widgets/scroll-top/scroll-top.button.widget";
import { HeaderWidget } from "../creative-intelligence-suite/widgets/header/header.widget";
import { ButtonUI } from "src/app/ui/buttons/button.ui";
import { useSessionFeature } from "src/app/features/session/session.feature";
import { LoadingPage } from "src/app/features/requests/features/loadings/pages/loading.page";
import { Navigate } from "react-router-dom";

const NoBrandPages: FC = () => {
  const { logout, user } = useSessionFeature();

  if (user === undefined) {
    return <LoadingPage />;
  }

  if (!user) {
    return <Navigate to={"/"} />;
  }

  return (
    <CardUI
      className="animFadeIn flex h-full w-full justify-center"
      style={{ background: "rgb(239 239 239)" }}
    >
      <div className="h-full w-full overflow-auto">
        <div className="sticky" style={{ top: "0" }}>
          <HeaderWidget />
        </div>
        <div
          style={{
            maxWidth: "550px",
            margin: "2rem auto",
            borderRadius: "3px",
            overflow: "hidden",
          }}
        >
          <div className="m-10">
            <h1 className="mb-6">You don&apos;t have access to any brand.</h1>
            <p>Contact your account manager.</p>
            <ButtonUI onClick={logout} className="mt-8">
              Sign out
            </ButtonUI>
          </div>
          <div className="h-8"></div>
          <div className="flex justify-center py-10">
            <ScrollTopButtonWidget />
          </div>
        </div>
      </div>
    </CardUI>
  );
};
export default NoBrandPages;
