import { CampaignsRepository } from "src/domain/campaigns/campaigns.domain";
import { AdRankingInput, CampaignRankingElement } from "src/graphql/client";
import { client } from "../clients/graphql.client";

export class CampaignsBackendRepository implements CampaignsRepository {
  async getCampaignRanking(args: AdRankingInput) {
    return client.chain.query.getCampaignRanking({ filter: args }).get({
      channels: 1,
      campaign: { id: 1, name: 1, objective: 1 },
      metrics: {
        conversionRate: {
          value: 1,
          variance: 1,
        },
        clickThroughRate: {
          value: 1,
          variance: 1,
        },
        costPerMille: {
          value: 1,
          variance: 1,
        },
        frequency: {
          value: 1,
          variance: 1,
        },
        engagementRate: {
          value: 1,
          variance: 1,
        },
        impressionsTotal: {
          value: 1,
          total: 1,
        },
        costPerClick: {
          metric: 1,
          variance: 1,
          value: 1,
          average: 1,
        },
        spendTotal: {
          value: 1,
        },
        brandLift: {
          value: 1,
          variance: 1,
        },
        costPerEngagement: {
          value: 1,
          variance: 1,
        },
        costPerCompletedView: {
          value: 1,
          variance: 1,
        },
        videoThroughRate: {
          value: 1,
          variance: 1,
        },
        costPerAcquisition: {
          value: 1,
          variance: 1,
        },
        costPerLead: {
          value: 1,
          variance: 1,
        },
      },
    }) as Promise<CampaignRankingElement[]>;
  }
}
