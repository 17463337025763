import Icon from "@ant-design/icons";
import tiktokIcon from "./tiktok-ads-icon.svg";
const TiktokAdsIcon = ({ props }: { props?: Record<string, any> }) => {
  return (
    <Icon
      component={() => (
        <img src={tiktokIcon} alt="Tiktok" width={20} height={20} {...props} />
      )}
    />
  );
};

export default TiktokAdsIcon;
