import { PictureOutlined } from "@ant-design/icons";
import { Modal, Upload, UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import { UploadListType } from "antd/es/upload/interface";
import DOMPurify from "dompurify";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { SpinnerUI } from "src/app/features/requests/features/loadings/spinner.ui";
import { useRequestFeature } from "src/app/features/requests/request.feature";
import { useUploaderFeature } from "src/app/features/uploader/uploader.feature";
import { titleCaseFormatterTool } from "src/app/tools/formatters/title-case.formatter.tool";
import { UploadDataResponse } from "src/graphql/client";

export const DropZoneInputUI: FC<{
  onFileUploaded: (file: { url: string }) => void;
  listType?: UploadListType;
  isSuccessToastShowed?: boolean;
}> = ({
  onFileUploaded,
  listType = "picture-card",
  isSuccessToastShowed = true,
}) => {
  const { sendRequest } = useRequestFeature();
  const { generatePresignedUrl } = useUploaderFeature();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage] = useState("");
  const [previewTitle] = useState("");
  const [isFileLoading, setIsFileLoading] = useState(false);

  const [filesDirectory, setFilesDirectory] = useState<
    Map<string, UploadDataResponse>
  >(new Map());

  const beforeUpload = async (file: RcFile) => {
    numberOfAttempts++;
    const { type, name, uid } = file;
    await sendRequest({
      id: "generating-presigned-url",
      request: generatePresignedUrl({
        extension: name.split(".").at(-1)?.toLocaleLowerCase() ?? "",
        mimeType: type,
      }),
      onSuccess(response) {
        const data = response.presignedData;
        setFilesDirectory(filesDirectory);
        data.fields = JSON.parse(data.fields);
        filesDirectory.set(uid, response);
      },
      async onError(error) {
        if (numberOfAttempts >= maxNumberOfAttempts) {
          const serverError =
            Array.isArray(error) && error[0] && error[0]?.message
              ? error[0]?.message
              : undefined;
          if (!serverError) return toast.error("Error uploading file");
          const msg = titleCaseFormatterTool(serverError);
          return toast.error(msg);
        }
        await beforeUpload(file);
      },
    });
  };

  async function uploadFile(file: RcFile) {
    const fileUploadData = filesDirectory.get(file.uid);

    const formData = new FormData();
    const fields = fileUploadData?.presignedData.fields;
    if (!fields) return;
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key as any]);
    });
    formData.append("file", file);
    if (!fileUploadData?.presignedData?.url) return;
    setIsFileLoading(true);
    const response = await fetch(fileUploadData?.presignedData?.url, {
      method: "POST",
      body: formData,
    });
    setIsFileLoading(false);
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return response;
  }

  const onFileUpload = (info: any) => {
    const { name, uid } = info.file;
    const data = filesDirectory.get(uid);
    const creativeId = data?.id;
    if (!creativeId) return;
    if (isSuccessToastShowed)
      toast.success(`${name} file uploaded!`, { id: "image-upload" });
    onFileUploaded({ url: data.presignedData.url });
  };

  const handleCancel = () => setPreviewOpen(false);

  const onChange = async (info: any) => {
    const { name, status } = info.file;
    if (status === "done") {
      onFileUpload(info);
    } else if (status === "error") {
      toast.error(`${name} file upload failed.`);
    }
  };

  const customRequest = async ({ onSuccess, onError, file }: any) => {
    try {
      setIsFileLoading(true);
      await uploadFile(file);
      setIsFileLoading(false);
      onSuccess(null, file);
    } catch (error: any) {
      onError(error);
    }
  };

  const maxNumberOfAttempts = 3;
  let numberOfAttempts = 0;

  const props: UploadProps = {
    name: "avatar",
    showUploadList: false,
    multiple: false,
    method: "post",
    beforeUpload,
    listType,
    customRequest,
    onChange,
  };

  if (isFileLoading) {
    return (
      <div
        className="flex items-center justify-center"
        style={{
          width: "100px",
          height: "100px",
        }}
      >
        <SpinnerUI />
      </div>
    );
  }

  return (
    <Upload {...props}>
      <div className="p-2">
        <p
          style={{
            color: "#64748B",
            fontSize: "34px",
            margin: "4px",
          }}
        >
          <PictureOutlined />
        </p>
        <small className="ant-upload-hint">Select or drag and drop</small>
      </div>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{ width: "100%" }}
          src={DOMPurify.sanitize(previewImage)}
        />
      </Modal>
    </Upload>
  );
};
