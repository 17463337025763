import Table, {
  ColumnsType,
  TablePaginationConfig,
  TableProps,
} from "antd/es/table";
import classNames from "classnames";
import { FC, useEffect, useState } from "react";

interface TableUIProps<T> {
  columns: ColumnsType<T>;
  showHeader?: boolean;
  isLoading?: boolean;
  maxItemPerPage?: number | false;
  selectedRowKeys?: React.Key[];
  className?: string;
  scrollDimensions?: { x?: number | string; y?: number | string };
  onRowClick?: (record: T) => void;
  size?: "large" | "middle" | "small";
  onSelectChange?: (newSelectedRowKeys: React.Key[]) => void;
  onSelectInDisabledRow?: (record: T) => void;
  data: T[];
  hideHeaderCheckbox?: boolean;
  rowSelectionType?: "checkbox" | "radio";
}

export const TableUI: FC<TableProps<any> & TableUIProps<any>> = ({
  columns,
  data,
  isLoading,
  className = "",
  onRowClick,
  maxItemPerPage = 14,
  size,
  selectedRowKeys: _selectedRowKeys,
  scrollDimensions,
  onSelectInDisabledRow,
  showHeader,
  onSelectChange,
  hideHeaderCheckbox,
  rowSelectionType,
  ...tableProps
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    _selectedRowKeys ?? [],
  );

  useEffect(() => {
    if (_selectedRowKeys) setSelectedRowKeys(_selectedRowKeys);
  }, [_selectedRowKeys]);

  const onTableSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (!onSelectChange) return;
    setSelectedRowKeys(newSelectedRowKeys);
    onSelectChange(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onTableSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record.isSelectionDisabled,
      name: record.name,
    }),
    type: rowSelectionType ?? "checkbox",
    ...(hideHeaderCheckbox ? { columnTitle: <></> } : undefined),
  };

  const getPagination = (): false | TablePaginationConfig => {
    if (maxItemPerPage === false) return false;
    if (data.length < maxItemPerPage) return false;
    return {
      position: ["bottomRight"],
      defaultPageSize: maxItemPerPage,
    };
  };

  const getRowClassName = (record: any) => {
    if (record.isSelectionDisabled) {
      return "row-selection-disabled";
    }
    return "";
  };

  const tableClassNames = classNames(className, {
    "clickable-row": onRowClick && !onSelectChange,
    "selectable-row": onSelectChange,
  });

  return (
    <Table
      className={tableClassNames}
      onRow={(record) => {
        return {
          onClick: () => {
            if (!onSelectChange) return onRowClick && onRowClick(record);
            if (record.isSelectionDisabled) {
              if (onSelectInDisabledRow) onSelectInDisabledRow(record);
              return;
            }
            const pressingAnimationDuration = 60;
            setTimeout(() => {
              const key = record.key;
              const newSelectedRowKeys = selectedRowKeys.includes(key)
                ? selectedRowKeys.filter((k) => k !== key)
                : [...selectedRowKeys, key];
              onTableSelectChange(newSelectedRowKeys);
            }, pressingAnimationDuration);
          },
        };
      }}
      showHeader={showHeader}
      rowSelection={onSelectChange ? rowSelection : (null as any)}
      columns={columns}
      showSorterTooltip={false}
      size={size}
      scroll={scrollDimensions}
      loading={isLoading}
      rowClassName={getRowClassName}
      pagination={getPagination()}
      dataSource={data}
      {...tableProps}
    />
  );
};
