import { FC } from "react";
import { useForm } from "react-hook-form";
import { ButtonUI } from "src/app/ui/buttons/button.ui";
import { SelectInputUI } from "src/app/ui/inputs/select/select.ui";
import { FormComponent } from "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/account-and-brands/pages/account/pages/edit-business/edit-business.form.page";
import { FormErrorsWidget } from "src/app/pages/signup/form-errors.widget";
import { useBusinessDomain } from "src/domain/business/business.domain";
import { useRequestFeature } from "src/app/features/requests/request.feature";
import { useUsersDomain } from "src/domain/users/users.domain";
import useSWR from "swr";
const BusinessInfoForm: FC<FormComponent> = ({
  onSubmit,
  onCancel,
  defaultValues = {},
}) => {
  const { register, handleSubmit, formState, getValues } = useForm({
    defaultValues,
  });
  const { getUser } = useUsersDomain();
  const { mutate } = useSWR("getUser", getUser);
  const { createBusiness } = useBusinessDomain();
  const { sendRequest, isLoading } = useRequestFeature();

  const submitHandler = () => {
    sendRequest({
      id: "creating-business",
      request: createBusiness(getValues()),
      onSuccess(business) {
        mutate();
        onSubmit?.(business);
      },
    }).then();
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="flex w-full flex-col gap-4">
        <label
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          htmlFor="businessName"
        >
          <div className="pb-2">Company name*</div>
          <input
            id="businessName"
            {...register("businessName", {
              required: "Company name is required",
            })}
            type="text"
          />
        </label>

        <label
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          htmlFor="sector"
        >
          <div className="pb-2">
            Which sector best describe your business activity?
          </div>
          <SelectInputUI
            id="sector"
            className="w-full"
            options={[
              "Consumer Goods",
              "Energy and Utilities",
              "Financial Services",
              "Healthcare",
              "Industrial and Manufacturing",
              "Technology and Software",
              "Telecommunications",
            ].map((r) => ({
              key: r,
              label: r,
            }))}
          />
        </label>

        <label htmlFor="primary-goal">
          <div className="pb-2">
            What is your primary goal for using our platform?
          </div>
          <SelectInputUI
            id="primary-goal"
            className="w-full"
            options={[
              "Improve my creative assets' performance",
              "Optimize my future marketing campaigns",
              "Gain insights on my previous campaigns",
              "Make data-driven decisions",
              "Streamline my creative testing and analytics workflows",
              "Other",
            ].map((r) => ({
              key: r,
              label: r,
            }))}
          />
        </label>
      </div>
      <div className="h-3"></div>
      <FormErrorsWidget formState={formState} />
      <div className="h-3"></div>
      <div className="flex items-center justify-end gap-2">
        <ButtonUI type="text" onClick={onCancel}>
          Cancel
        </ButtonUI>
        <ButtonUI
          isLoading={isLoading("creating-business")}
          onClick={handleSubmit(submitHandler)}
        >
          Continue
        </ButtonUI>
      </div>
    </form>
  );
};
export default BusinessInfoForm;
