import { Typography } from "antd";
import { FC, useState } from "react";
import { useNavigationFeature } from "src/app/features/navigation/navigation.feature";
import PlatformsIntegrationsPage from "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/integrations-and-assets/pages/brand-connections-and-business-assets/pages/platforms-integrations/platforms-integrations.page";
import { ButtonUI } from "src/app/ui/buttons/button.ui";
import {
  PlatformsIntegrations,
  useIntegrationDomain,
} from "src/domain/integrations/integration.domain";
import { useUsersDomain } from "src/domain/users/users.domain";
import useSWR from "swr";
import { useOnboardingFeature } from "../onboarding.feature";

const ConnectYourPlatforms: FC = () => {
  const { gotoNextStep, cancelOnBoarding } = useOnboardingFeature();
  const { goto } = useNavigationFeature();
  const { getIntegrations } = useIntegrationDomain();

  const { getUser } = useUsersDomain();
  const { data: user } = useSWR("getUser", getUser);

  const { data: integrations } = useSWR(user ? `get-integrations` : null, () =>
    getIntegrations({ path: "connect-your-platforms" }),
  );

  const isAnyIntegrationConnectedTool = (
    integrations: PlatformsIntegrations[],
  ) => {
    return (
      integrations?.some((integration) => integration.status === "OK") ?? false
    );
  };

  const [isAnyIntegrationConnected, setIsAnyIntegrationConnected] =
    useState<boolean>(isAnyIntegrationConnectedTool(integrations ?? []));

  return (
    <div
      className="animFadeIn  w-full "
      style={{
        maxWidth: "660px",
        margin: "0 auto",
      }}
    >
      <Typography.Title level={4}>Connect your platforms</Typography.Title>
      <div className="mb-6">
        <p>
          This allows Memorable to train your own Brand AI. We train models to
          detect what helps your brand and what hurts it.
        </p>
      </div>
      <PlatformsIntegrationsPage
        onIntegrationsStatusChanges={(integrations) => {
          setIsAnyIntegrationConnected(
            isAnyIntegrationConnectedTool(integrations),
          );
        }}
      />
      <div className="h-8"></div>

      <div className="flex items-center justify-end gap-2">
        <ButtonUI type="text" onClick={cancelOnBoarding}>
          Cancel
        </ButtonUI>
        <ButtonUI
          isDisabled={!isAnyIntegrationConnected}
          onClick={gotoNextStep}
        >
          Next
        </ButtonUI>
      </div>
      {!isAnyIntegrationConnected && (
        <div className="mt-8 flex w-full items-center justify-center">
          <ButtonUI
            onClick={() => goto("/welcome/skip-integration")}
            type="text"
          >
            Skip if you don&apos;t place ads on any of these platforms.
          </ButtonUI>
        </div>
      )}
    </div>
  );
};
export default ConnectYourPlatforms;
