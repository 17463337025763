import { TabsConfig } from "@creative-intelligence-suite-pages/creative-analytics/pages/ads-library/widgets/ads-tabs.widget";
import {
  AdRankingOrderingColumn,
  CampaignObjective,
  Channel,
  Country,
  TimeRange,
} from "src/graphql/client";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type TabsType = "ads" | "ad-groups" | "campaigns";

interface IAdsProps {
  currentDateRange: TimeRange;
  currentChannels: Channel[];
  currentObjective: CampaignObjective;
  orderingColumn: {
    ads?: AdRankingOrderingColumn;
    campaigns?: AdRankingOrderingColumn;
    "ad-groups"?: AdRankingOrderingColumn;
  };
  campaignsSelected: string[] | undefined;
  adsGroupsSelected: string[] | undefined;
  searchText: Record<TabsType, string>;
  searchTextForQuery: Record<TabsType, string>;
  countries: Country[];
}

export interface IAdsState extends IAdsProps {
  setCurrentDateRange: (timeRange: TimeRange) => void;
  setCurrentChannels: (channels: Channel[]) => void;
  setCurrentObjective: (campaign: CampaignObjective) => void;
  setOrderingColumn: (props: Record<string, AdRankingOrderingColumn>) => void;
  setDefaultOrderingColumns: (
    props: Record<keyof IAdsProps["orderingColumn"], AdRankingOrderingColumn>,
  ) => void;
  setCampaignsSelected: (campaignIds: string[]) => void;
  setAdsGroupsSelected: (adGroupIds: string[]) => void;
  setSearchText: (props: Record<TabsType, string>) => void;
  setSearchTextForQuery: (props: Record<TabsType, string>) => void;
  setCountries: (props: Country[]) => void;
  reset: () => void;
}

const DEFAULT_PROPS: IAdsProps = {
  currentDateRange: {},
  currentChannels: [],
  currentObjective: "all" as CampaignObjective,
  orderingColumn: {
    ads: undefined,
    campaigns: undefined,
    "ad-groups": undefined,
  },
  campaignsSelected: undefined,
  adsGroupsSelected: undefined,
  countries: [],
  searchText: {
    [TabsConfig.campaigns.key as "campaigns"]: "",
    [TabsConfig.ads.key as "ads"]: "",
    [TabsConfig["ad-groups"].key as "ad-groups"]: "",
  },
  searchTextForQuery: {
    [TabsConfig.campaigns.key as "campaigns"]: "",
    [TabsConfig.ads.key as "ads"]: "",
    [TabsConfig["ad-groups"].key as "ad-groups"]: "",
  },
};

export const useAdsLibraryState = create<IAdsState>(
  persist(
    (set) => ({
      ...DEFAULT_PROPS,
      reset: () => set(DEFAULT_PROPS),
      setCurrentDateRange: (timeRange: TimeRange) =>
        set({
          currentDateRange: timeRange,
          // need to clear selections
          campaignsSelected: undefined,
          adsGroupsSelected: undefined,
        }),
      setCurrentChannels: (channels: Channel[]) =>
        set({
          currentChannels: channels,
          // need to clear selections
          campaignsSelected: undefined,
          adsGroupsSelected: undefined,
        }),
      setCurrentObjective: (campaign: CampaignObjective) =>
        set({
          currentObjective: campaign,
          // need to clear selections
          campaignsSelected: undefined,
          adsGroupsSelected: undefined,
        }),
      setOrderingColumn: (prop: { [tab: string]: AdRankingOrderingColumn }) =>
        set((state: IAdsProps) => ({
          orderingColumn: { ...state.orderingColumn, ...prop },
          // need to clear selections
          campaignsSelected: undefined,
          adsGroupsSelected: undefined,
        })),
      setDefaultOrderingColumns: (
        props: Record<
          keyof IAdsProps["orderingColumn"],
          AdRankingOrderingColumn
        >,
      ) =>
        set({
          orderingColumn: props,
        }),
      setCampaignsSelected: (campaignIds: string) =>
        set({
          campaignsSelected: campaignIds,
          // need to clear selections
          adsGroupsSelected: undefined,
        }),
      setAdsGroupsSelected: (adsGroupIds: string) =>
        set({ adsGroupsSelected: adsGroupIds }),
      setSearchText: (params: Record<string, string>) =>
        set(({ searchText }: { searchText: Record<TabsType, string> }) => ({
          searchText: {
            ...searchText,
            ...params,
          },
        })),
      setSearchTextForQuery: (params: Record<TabsType, string>) => {
        return set(
          ({
            searchTextForQuery,
          }: {
            searchTextForQuery: Record<TabsType, string>;
          }) => ({
            searchTextForQuery: {
              ...searchTextForQuery,
              ...params,
            },
          }),
        );
      },
      setCountries: (countries: Country[]) => set({ countries: countries }),
    }),
    {
      name: "ads-library-storage",
      getStorage: () => localStorage,
    },
  ) as any,
);
