import { FC, useEffect } from "react";
import { useRequestFeature } from "src/app/features/requests/request.feature";
import { useSessionFeature } from "src/app/features/session/session.feature";
import CardPageUI from "src/app/ui/cards/card-page.ui";
import { LoaderStateUI } from "src/app/ui/states/loader-state.ui";
import { useBusinessDomain } from "src/domain/business/business.domain";
import { enumBusinessStatus } from "src/graphql/client";

const SkipIntegrationFeedbackPage: FC = () => {
  const { user } = useSessionFeature();
  const { sendRequest, isLoading } = useRequestFeature();
  const { updateBusinessAccountStatus } = useBusinessDomain();

  const setBusinessAsOnboardingCompleted = () => {
    sendRequest({
      id: "SkipIntegrationFeedbackPage-onboardingCompleted",
      request: updateBusinessAccountStatus({
        status: enumBusinessStatus.onboardingCompleted,
      }).then(),
    });
  };

  useEffect(() => {
    if (
      user?.businessAccount?.status === enumBusinessStatus.onboardingCompleted
    )
      return;
    setBusinessAsOnboardingCompleted();
  }, [user]);

  if (isLoading("SkipIntegrationFeedbackPage-onboardingCompleted")) {
    return (
      <CardPageUI className="mx-auto max-w-4xl">
        <LoaderStateUI isLoading={true} />
      </CardPageUI>
    );
  }

  return (
    <CardPageUI className="mx-auto max-w-4xl">
      <div className="m-10">
        <h1 className="mb-8">Look out for an email</h1>
        <p>
          A member of Memorable will send you an email within the next business
          day to schedule your access.
        </p>
      </div>
    </CardPageUI>
  );
};
export default SkipIntegrationFeedbackPage;
