import { lazy } from "react";
import { Route } from "./features/navigation/models/route.model";
import { CreativeIntelligenceSuiteRoutes } from "./pages/creative-intelligence-suite/creative-intelligence-suite.routes";
import NoBrandPages from "./pages/states/no-brand.page";
import { WelcomeRoutes } from "./pages/welcome/welcome.routes";

const CreativeIntelligenceSuitePage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/creative-intelligence-suite.page"
    ),
);

const SignUpPage = lazy(() => import("src/app/pages/signup/signup.page"));

const WelcomePage = lazy(() => import("src/app/pages/welcome/welcome.page"));

const MetaSuccessOauthPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/integrations-and-assets/pages/brand-connections-and-business-assets/pages/platforms-integrations/pages/meta.oauth.page"
    ),
);

const GoogleSuccessOauthPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/integrations-and-assets/pages/brand-connections-and-business-assets/pages/platforms-integrations/pages/google.oauth.page"
    ),
);
const TiktokSuccessOauthPage = lazy(
  () =>
    import(
      "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/integrations-and-assets/pages/brand-connections-and-business-assets/pages/platforms-integrations/pages/tiktok.oauth.page"
    ),
);

const ChangeLogPage = lazy(
  () => import("src/app/pages/changelog/changelog.page"),
);

export const AppRoutesConfig: Route[] = [
  {
    path: "/",
    element: CreativeIntelligenceSuitePage,
    children: CreativeIntelligenceSuiteRoutes,
    title: "Creative Intelligence Suite",
  },
  {
    path: "signup",
    title: "Signup",
    element: SignUpPage,
  },
  {
    path: "signup/:token",
    title: "Signup",
    element: SignUpPage,
  },
  {
    path: "welcome",
    element: WelcomePage,
    children: WelcomeRoutes,
    title: "Welcome",
  },
  {
    path: "changelog",
    element: ChangeLogPage,
    title: "Changelog",
  },
  {
    path: "oauth/meta",
    element: MetaSuccessOauthPage,
    title: "Oauth Meta",
  },
  {
    path: "oauth/google",
    element: GoogleSuccessOauthPage,
    title: "Oauth Google",
  },
  {
    path: "oauth/tiktok",
    element: TiktokSuccessOauthPage,
    title: "Oauth TikTok",
  },
  {
    path: "no-brands",
    element: NoBrandPages,
    title: "You don't have a brand",
  },
];
