import { useRepositoryFeature } from "src/app/features/repositories/repositories.feature";
import {
  Brand,
  Invitation,
  InvitationInput,
  PermissionGroup,
  UserPermissionsInput,
  UserWithPermissions,
} from "src/graphql/client";

export interface PermissionsRepository {
  getBusinessAccountPermissionGroup(): Promise<PermissionGroup[]>;
  updateUserPermissions(input: UserPermissionsInput): Promise<boolean>;
  createInternalInvitations(input: InvitationInput): Promise<Invitation[]>;
  removeInternalInvitations(code: string): Promise<boolean>;
  getActiveInternalInvitations(): Promise<Invitation[]>;
  resendInvitation(invitationCode: string): Promise<boolean>;
}

export const usePermissionsDomain = (repoId = "PermissionsRepository") => {
  const { repository } = useRepositoryFeature<PermissionsRepository>(repoId);

  const canISeeBrandSettings = (
    isAdmin: boolean,
    user: UserWithPermissions,
    currentBrand: Brand | null,
  ) => {
    if (isAdmin) return false;
    return hasUserPermission(2, user, currentBrand);
  };

  const hasUserPermission = (
    permissionKey: number,
    user: UserWithPermissions,
    currentBrand: Brand | null,
  ) => {
    const a = user?.brandPermissions ?? [];
    if (!a) return false;
    const b = a.find((b) => b.brandId === currentBrand?.id);
    if (!b) return false;
    return b.permissions.indexOf(permissionKey) > -1;
  };

  const canUserImpersonate = (user: UserWithPermissions) => {
    const IMPERSONATION_PERMISSION_KEY = 0;
    if (user.userPermissions?.includes(IMPERSONATION_PERMISSION_KEY))
      return true;
    if (
      user.brandPermissions?.some((bp) =>
        bp.permissions.includes(IMPERSONATION_PERMISSION_KEY),
      )
    )
      return true;

    return false;
  };

  const getBusinessAccountPermissionGroup = () => {
    return repository.getBusinessAccountPermissionGroup();
  };

  const updateUserPermissions = (input: UserPermissionsInput) => {
    return repository.updateUserPermissions(input);
  };

  return {
    getBusinessAccountPermissionGroup,
    updateUserPermissions,
    canISeeBrandSettings,
    canUserImpersonate,
    hasUserPermission,
  };
};
