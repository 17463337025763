import { Brand, UserWithPermissions } from "src/graphql/client";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type SessionState = {
  user: UserWithPermissions | null | undefined;
  currentBrand: Brand | null;
  sessionId: string | null | undefined;
  setUser: (user: UserWithPermissions | undefined) => void;
  selectBrand: (brand: Brand) => void;
  setSessionId: (sessionId: string) => void;
  removeUser: () => void;
  reset: () => void;
  isUserImpersonated: boolean | undefined;
  setUserImpersonated: () => void;
};

const initialState = {
  user: undefined,
  sessionId: undefined,
  currentBrand: null,
  isUserImpersonated: undefined,
};

export const useSessionState = create<SessionState>(
  persist(
    (set) => ({
      ...initialState,
      selectBrand: (brand: Brand) => set({ currentBrand: brand }),
      setSessionId: (sessionId: string) => set({ sessionId }),
      setUser: (user: UserWithPermissions | undefined) => set({ user }),
      removeUser: () => set({ user: null }),
      reset: () => set(initialState),
      setUserImpersonated: () => set({ isUserImpersonated: true }),
    }),
    {
      name: "session-storage",
      getStorage: () => localStorage,
      partialize: (state: SessionState) => ({
        isUserImpersonated: state.isUserImpersonated,
      }),
    },
  ) as any,
);
