import GoogleAdsIcon from "@ui/icons/google-ads-icon/google-ads-icon";
import MetaIcon from "@ui/icons/meta-ads-icon/meta-ads-icon";
import TiktokAdsIcon from "@ui/icons/tiktok-ads-icon/tiktok-ads-icon";
import { FC } from "react";
import { Platform, enumPlatform } from "src/graphql/client";
// if we are implementing this way of connecting add this
import { MdCastConnected } from "react-icons/md";

type PlatformIconWidgetProps = {
  platform: Platform;
  size?: number;
};

export const getPlatformIcon = (platform: Platform, props = {}) => {
  if (platform === enumPlatform.TIKTOK) return <TiktokAdsIcon {...props} />;
  if (platform === enumPlatform.META) return <MetaIcon {...props} />;
  if (platform === enumPlatform.GOOGLE) return <GoogleAdsIcon {...props} />;
  if (platform === enumPlatform.CTV) return <MdCastConnected {...props} />;
  return null;
};
export const PlatformIconWidget: FC<PlatformIconWidgetProps> = ({
  platform,
  size,
}) => {
  const Icon = getPlatformIcon(platform, { size });

  if (!Icon) return null;

  return Icon;
};
