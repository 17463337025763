import { CreativeRepository } from "src/domain/creatives/creative.domain";
import {
  ChannelOptions,
  CopyInput,
  Creative,
  CreativeLibraryFolder,
  CreativeType,
  DeleteCreativesFromFolderInput,
  PerformanceMetricFiltersInput,
  PerformanceMetricsV2,
  PerformanceMetricValue,
} from "src/graphql/client";
import { client } from "../clients/graphql.client";
import { ReducedRecommendationResponseFields } from "./recommendations.repository";

export class CreativesBackendRepository implements CreativeRepository {
  deleteCreatives(input: DeleteCreativesFromFolderInput): Promise<boolean> {
    return client.chain.mutation.deleteCreativesFromFolder({ input }).get();
  }

  getChannelAvailableOptions = (creativeIds: string[]) => {
    return client.chain.query
      .getChannelAvailableOptions({
        creativeIds,
      })
      .get({
        channel: 1,
        placements: 1,
        campaignObjectives: 1,
      }) as Promise<ChannelOptions[]>;
  };

  async getCreatives(
    brandId: string,
    creativeType: CreativeType,
    folderId?: number,
  ): Promise<CreativeLibraryFolder> {
    return client.chain.query
      .folder({
        input: {
          brandId,
          folderId,
          creativeType,
        },
      })
      .get({
        path: 1,
        id: 1,
        parentId: 1,
        folders: {
          path: 1,
          id: 1,
          parentId: 1,
        },
        creatives: [
          { input: { creativeType: [creativeType] } },
          {
            id: 1,
            name: 1,
            updatedAt: 1,
            fileType: 1,
            url: 1,
            clipEmbeddingUrl: 1,
            status: 1,
            thumbnailUrl: 1,
          },
        ],
      }) as Promise<CreativeLibraryFolder>;
  }

  async getPerformance(
    brandId: string,
    filter: PerformanceMetricFiltersInput,
    requestedFields: (keyof Omit<PerformanceMetricsV2, "__typename">)[],
    requestMarkers: boolean,
  ) {
    const commonFields = {
      value: 1,
      quantiles: 1,
      rawValue: 1,
      ...(requestMarkers ? { affectingCognitives: 1 } : undefined),
    };

    const fields = requestedFields.reduce(
      (acc, field) => {
        acc[field] = commonFields;
        return acc;
      },
      {} as Record<
        keyof Omit<PerformanceMetricsV2, "__typename">,
        Record<
          keyof Omit<
            PerformanceMetricValue,
            "recommendations" | "__typename" | "affectingCognitives"
          >,
          number
        >
      >,
    );

    const result = await client.chain.query
      .performanceMetrics({
        brandId,
        filter,
      })
      .get(fields);

    return result as Promise<PerformanceMetricsV2>;
  }

  async getLLMsCognitiveScores(
    brandId: string,
    filter: PerformanceMetricFiltersInput,
    loadRecommendations: boolean,
    requestedFields: (keyof Omit<PerformanceMetricsV2, "__typename">)[],
    enableCognitiveRecosFeature: boolean,
  ) {
    const commonFieldsLLMs = {
      affectingCognitives: 1,
      // commenting this until backend is ready
      // also loadRecommendations is used to load recommendations for single creative
      ...(enableCognitiveRecosFeature && loadRecommendations
        ? { recommendations: ReducedRecommendationResponseFields }
        : undefined),
    };

    const fields = requestedFields.reduce(
      (acc, field) => {
        acc[field] = commonFieldsLLMs;
        return acc;
      },
      {} as Record<
        keyof Omit<PerformanceMetricsV2, "__typename">,
        Record<
          keyof Omit<
            PerformanceMetricValue,
            "__typename" | "recommendations" | "affectingCognitives"
          > & {
            recommendations?:
              | {
                  type: number;
                  goal: number;
                  title: number;
                  customMessage: number;
                  impactedCognitive: number;
                }
              | undefined;
            affectingCognitives: number;
          },
          number
        >
      >,
    );

    return client.chain.query
      .performanceMetrics({
        brandId,
        filter,
      })
      .get(fields) as Promise<PerformanceMetricsV2>;
  }

  async getCreativeById(creativeId: string): Promise<Creative | undefined> {
    return client.chain.query
      .creative({
        creativeId,
      })
      .get({
        id: 1,
        name: 1,
        fileType: 1,
        type: 1,
        clipEmbeddingUrl: 1,
        thumbnailUrl: 1,
        cognitiveScores: {
          memorability: {
            value: 1,
          },
          brandPresence: {
            value: 1,
          },
          focus: {
            value: 1,
          },
          cognitiveEase: {
            value: 1,
          },
          aesthetics: {
            value: 1,
          },
          engagementPotential: {
            value: 1,
          },
          authenticity: {
            value: 1,
          },
          distinctiveness: {
            value: 1,
          },
        },
        url: 1,
        heatmapUrl: 1,
        tags: {
          tagSuperClass: 1,
          importance: 1,
          tagClass: 1,
          tagValue: 1,
        },
      }) as Promise<Creative | undefined>;
  }
  async getCreativeCognitiveScoresByFrames(
    creativeId: string,
  ): Promise<
    Pick<Creative, "id" | "name" | "cognitiveScoresByFrame"> | undefined
  > {
    return client.chain.query
      .creative({
        creativeId,
      })
      .get({
        id: 1,
        name: 1,
        cognitiveScoresByFrame: {
          type: 1,
          values: 1,
        },
      }) as Promise<
      Pick<Creative, "id" | "name" | "cognitiveScoresByFrame"> | undefined
    >;
  }

  async getCopyGeneration(creativeId: string, copyGenerationInput: CopyInput) {
    return client.chain.query.creative({ creativeId }).get({
      id: 1,
      name: 1,
      copies: [
        { filter: copyGenerationInput },
        {
          title: 1,
          description: 1,
        },
      ],
    }) as Promise<Pick<Creative, "id" | "name" | "copies"> | undefined>;
  }
}
