import { FC } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { ButtonUI } from "src/app/ui/buttons/button.ui";
import { FormComponent } from "src/app/pages/creative-intelligence-suite/pages/business-settings/pages/account-and-brands/pages/account/pages/edit-business/edit-business.form.page";
import { useUsersDomain } from "src/domain/users/users.domain";
import { useRequestFeature } from "src/app/features/requests/request.feature";
import { FormErrorsWidget } from "src/app/pages/signup/form-errors.widget";
import toast from "react-hot-toast";
import { useSWRConfig } from "swr";

export const EditPersonalInfoFormPage: FC<FormComponent> = ({
  defaultValues,
}) => {
  const { updateUser } = useUsersDomain();
  const { sendRequest, isLoading } = useRequestFeature();

  const { mutate } = useSWRConfig();

  const { register, handleSubmit, formState } = useForm({
    defaultValues,
  });

  const submitHandler: SubmitHandler<FieldValues> = async ({ name }) => {
    sendRequest({
      id: "updating-user",
      request: updateUser({
        name,
      }),
      onSuccess() {
        mutate("getUser");
        toast.success("User updated");
      },
    }).then();
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="flex w-full flex-col gap-4">
        <label
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          htmlFor="name"
        >
          <div className="pb-2">name*</div>
          <input
            id="name"
            {...register("name", { required: "name is required" })}
            type="text"
          />
        </label>
      </div>

      <div className="h-3"></div>
      <FormErrorsWidget formState={formState} />
      <div className="h-3"></div>

      <div className="flex items-center justify-end gap-2">
        <ButtonUI
          isLoading={isLoading("updating-user")}
          onClick={handleSubmit(submitHandler)}
        >
          Update info
        </ButtonUI>
      </div>
    </form>
  );
};
