import { Avatar } from "antd";
import mime from "mime";
import { FC } from "react";
import { VideoAssetUI } from "src/app/widgets/asset/video/video.asset.ui";
import { Ad } from "src/graphql/client";

export const AdsTableThumbnailWidget: FC<{
  ad: Ad;
}> = ({ ad }) => {
  const mimeType = (ad.url && mime.getType(ad.url)) ?? "image";

  const isImage = mimeType.startsWith("image");
  const isVideo = mimeType.startsWith("video");

  switch (true) {
    case isImage: {
      return (
        <Avatar shape="square" src={ad.thumbnailUrl} className="h-24 w-24" />
      );
    }
    case isVideo: {
      return (
        <div className="h-24 w-24">
          <VideoAssetUI
            creative={{ url: ad.url }}
            isDetailMode={false}
            isHeatMode={false}
          />
        </div>
      );
    }
  }
  return null;
};
