import { Tooltip, Typography } from "antd";
import classnames from "classnames";
import { FC } from "react";
import { BiCollapse } from "react-icons/bi";
import { GrTarget } from "react-icons/gr";
import { useNavigationFeature } from "src/app/features/navigation/navigation.feature";
import { titleCaseFormatterTool } from "src/app/tools/formatters/title-case.formatter.tool";
import CardClickableUI from "src/app/ui/cards/card-clickable.ui";
import { AssetUI } from "src/app/widgets/asset/asset.ui";
import {
  CampaignObjective,
  Channel,
  Highlight,
  enumCampaignObjective,
} from "src/graphql/client";
import { ImpactMetricUI } from "./impact-metric.ui";
import { ChannelIconUI } from "./tag-insight/channel.icon.ui";

type CreativeInsightWidgetProps = {
  highlight: Highlight;
  className?: string;
};

export const ChannelListUI: FC<{
  channels?: Channel[];
  isNamesShowed?: boolean;
}> = ({ channels, isNamesShowed }) => {
  if (!channels) return <></>;

  return (
    <div
      className="inline-flex items-center gap-2 rounded-md p-1"
      style={{ background: "#F5F5F5" }}
    >
      {channels.map((channel) => {
        return (
          <div key={channel} className="flex items-center gap-1">
            <ChannelIconUI size={20} channel={channel} />
            {isNamesShowed ? (
              <span>{titleCaseFormatterTool(channel)}</span>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const ObjectiveUI: FC<{ objective: CampaignObjective }> = ({
  objective,
}) => {
  return (
    <div
      className="inline-flex items-center gap-2 rounded-md p-1"
      style={{ background: "#F5F5F5" }}
    >
      <div className="flex items-center gap-1">
        {objective === enumCampaignObjective.CROSS_OBJECTIVE ? (
          <BiCollapse />
        ) : (
          <GrTarget />
        )}
        <span className="whitespace-nowrap">
          {titleCaseFormatterTool(objective)}
        </span>
      </div>
    </div>
  );
};

export const CreativeInsightWidget: FC<CreativeInsightWidgetProps> = ({
  highlight,
  className,
}) => {
  const { goto } = useNavigationFeature();

  const gotoDetail = () =>
    goto(`/creative-analytics/creative/${highlight.advertisementId}`);

  const classNames = classnames("", {
    [className ?? ""]: !!className,
  });
  return (
    <CardClickableUI className={classNames} onClick={gotoDetail}>
      <div className="p-4">
        <div className="flex flex-col">
          <div className="flex">
            <div className="flex-1"></div>
          </div>
          <Tooltip title={highlight.name}>
            <Typography.Text style={{ width: "160px" }} ellipsis={true}>
              {highlight.name}
            </Typography.Text>
          </Tooltip>
          <div className="h-2"></div>
          <AssetUI
            style={{
              borderRadius: "8px",
              overflow: "hidden",
              aspectRatio: "1",
              width: "100%",
            }}
            creative={highlight}
          />
        </div>
        <div className="h-4"></div>

        <div className="py-2">
          <ImpactMetricUI variance={highlight.result} />
        </div>

        <div className="flex items-center gap-2">
          <ObjectiveUI objective={highlight.campaignObjective} />
          <div className="flex-1"></div>
          <ChannelListUI channels={highlight.channel} />
        </div>
      </div>
    </CardClickableUI>
  );
};
