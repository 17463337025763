import { CopyOutlined } from "@ant-design/icons";
import { ButtonUI } from "@ui/buttons-2.0/button.ui";
import { useSessionFeature } from "src/app/features/session/session.feature";
import useDebuggingDomain from "src/domain/debugging/debuging.domain";
import { useCopyToClipboard } from "usehooks-ts";
export default function BrandIdDebugger() {
  const [_, copy] = useCopyToClipboard();
  const { currentBrand, user } = useSessionFeature();

  const { debugMode } = useDebuggingDomain();

  if (!debugMode) {
    return null;
  }
  return (
    <div className="caption-regular animateFadeIn relative">
      <div className="absolute right-0 bottom-[-25px]">
        <ButtonUI
          size="small"
          type="text"
          className="caption-regular flex items-center gap-1"
          onClick={() => {
            currentBrand && copy(currentBrand?.id);
          }}
        >
          BrandId: {currentBrand?.id} {<CopyOutlined />}
        </ButtonUI>
        <ButtonUI
          size="small"
          type="text"
          className="caption-regular flex items-center gap-1"
          onClick={() => {
            user?.businessAccount && copy(user?.businessAccount?.id);
          }}
        >
          BusinessId: {user && user?.businessAccount?.id} {<CopyOutlined />}
        </ButtonUI>
      </div>
    </div>
  );
}
