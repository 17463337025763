import { create } from "zustand";

export interface OnBoardingState {
  currentIndex: number;
  setIndex: (index: number) => void;
}

export const useOnBoardingState = create<OnBoardingState>((set) => ({
  currentIndex: 0,
  setIndex: (index: number) => set({ currentIndex: index }),
}));
