import { FC } from "react";
import { ImageUI } from "src/app/ui/images/image.ui";

type PhotoAssetProps = {
  creative: any;
  isHeatMode?: boolean;
  isDetailMode?: boolean;
};

export const PhotoAssetUI: FC<PhotoAssetProps> = ({
  creative,
  isDetailMode,
  isHeatMode,
}) => {
  const { heatmapUrl, url } = creative;

  const heatmapUrlWithFallback = heatmapUrl;

  const imageSrc = (isHeatMode ? heatmapUrlWithFallback : url) as string;

  if (!heatmapUrl && !url) return null;
  return (
    <ImageUI
      style={{
        objectFit: isDetailMode ? "contain" : "cover",
        objectPosition: "center",
      }}
      src={imageSrc}
      isPreview={isDetailMode ? true : false}
    />
  );
};
