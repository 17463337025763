export interface LockStatus {
  isLocked: boolean;
  pageId?: enumLockPages;
}

export enum enumLockPages {
  UpgradeBannerCreativeLabs = "UpgradeBannerCreativeLabs",
  CreativeLabElementLocked = "CreativeLabElementLocked",
  CustomKPIsFeature = "CustomKPIsFeature",
  Recommendation = "Recommendation",
  UpgradeAnyForChannel = "UpgradeAnyForChannel",
  IntegrateToGetInsightsInBrand = "IntegrateToGetInsightsInBrand",
  YourPlanDoesNotIncludeThisChannel = "YourPlanDoesNotIncludeThisChannel",
}
