import { ChannelListUI } from "@creative-intelligence-suite-pages/creative-analytics/pages/overview/components/creative-highlight.widget";
import { FCC } from "@types";
import { TableUI } from "@ui/tables/table.ui";
import { ColumnsType } from "antd/es/table";
import { useCallback, useDeferredValue, useMemo } from "react";
import { useSessionFeature } from "src/app/features/session/session.feature";
import { useAdsLibraryState } from "src/domain/ads/ads.state";
import { Campaign, CampaignRankingElement, Channel } from "src/graphql/client";
import { MetricValueMode } from "./ads-reporting.widget";
import { commonTableFields, TitleWrapper } from "./common-table-fields.widget";

export const CampaignsTableWidget: FCC<{
  data: CampaignRankingElement[] | undefined;
  isLoading: boolean;
  currentMode: MetricValueMode;
}> = ({ isLoading, data, currentMode }) => {
  const dataDeferred = useDeferredValue(data);
  const { setCampaignsSelected, campaignsSelected } = useAdsLibraryState(
    (state) => ({
      setCampaignsSelected: state.setCampaignsSelected,
      campaignsSelected: state.campaignsSelected,
      setAdsGroupSelected: state.setAdsGroupsSelected,
    }),
  );

  const { canIAccess } = useSessionFeature();

  const columns: ColumnsType<CampaignRankingElement> = [
    {
      title: <TitleWrapper>Campaign</TitleWrapper>,
      width: 500,
      dataIndex: "campaign",
      className: "px-6 py-4 max-w-175 flex-1",
      // fixed: "left",
      render: (campaign: Campaign) => {
        const showId = false;
        return (
          <div className="flex justify-start gap-2">
            <div className="body-12-regular flex text-black-white-black break-all">
              {showId ? campaign.id + " " : ""}
              {campaign?.name}
            </div>
          </div>
        );
      },
    },
    {
      title: <TitleWrapper>Channel</TitleWrapper>,
      dataIndex: "channels",
      width: 105,
      className: "px-6 py-4 max-w-20 w-20",
      render: (channels: Channel[]) => {
        return (
          <div className="flex items-center justify-center">
            <ChannelListUI channels={channels} />
          </div>
        );
      },
    },
    {
      title: <TitleWrapper>Objective</TitleWrapper>,
      width: 135,
      dataIndex: "campaign",
      className: "px-6 py-4",

      render: (campaign: Campaign) => {
        return <div className="text-xs break-word">{campaign.objective}</div>;
      },
    },
    ...commonTableFields({ canIAccess, currentMode }),
  ];

  const onSelected = useCallback((selectedRowKeys: React.Key[]) => {
    setCampaignsSelected((selectedRowKeys as string[]) ?? undefined);
  }, []);

  const withCalculation = useMemo(
    () =>
      columns.reduce((acc, current) => {
        return acc + Number(current.width);
      }, 0),
    [columns],
  );

  return (
    <div className="w-full">
      <TableUI
        className="andt-table-header"
        isLoading={isLoading}
        columns={columns}
        scrollDimensions={{ y: 500, x: withCalculation }}
        data={
          dataDeferred?.map((e) => ({
            ...e,
            isSelectionDisabled:
              campaignsSelected && campaignsSelected?.length >= 5
                ? !campaignsSelected?.includes(e.campaign.id)
                : false,
          })) ?? []
        }
        maxItemPerPage={false}
        onSelectChange={onSelected}
        hideHeaderCheckbox={true}
        selectedRowKeys={campaignsSelected}
      />
    </div>
  );
};
